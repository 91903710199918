import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';
import SuiBox from './components/SuiBox';
import Sidenav from './examples/Sidenav';
import Configurator from './examples/Configurator';
import theme from './assets/theme';
import routes from './routes';
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from './context';
import SignIn from './layouts/authentication/sign-in';
import brand from './assets/images/logo-ct.png';
import { useSelector } from 'react-redux';
import { ConfirmProvider } from 'material-ui-confirm';
import GlobalLoading from "./components/GlobalLoading";


export default function App() {
  // const ddispatch = useDispatch();
  // const token = localStorage.getItem("token");
  // const user = JSON.parse(localStorage.getItem("user"));
  // if (token && user) {
  //   ddispatch(login(user, token));
  // }
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const role = localStorage.getItem('role')

  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  // }, []);

  // Open sidenav when mouse enter on mini sidenav

  const { isAuth } = useSelector((state) => state.user);
  useEffect(() => {
    console.log({ isAuth });
  }, [isAuth]);
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const storedUser = localStorage.getItem('user');

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SuiBox>
  );

  return (
    <ThemeProvider theme={theme}>

      <ConfirmProvider>
        {/* <GlobalLoading /> */}

        <CssBaseline />
        {storedUser ? (
          <>
            {layout === 'dashboard' && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={brand}
                  brandName="Soft UI Dashboard"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {/* {configsButton} */}
              </>
            )}
            {layout === 'vr' && <Configurator />}
            {role === "Manager" ? (<Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>) : role === "Editor" ? (
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/produits" />} />
              </Routes>
            ) : (<Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/map" />} />
            </Routes>)}
          </>
        ) : (
          <>
            <SignIn />
          </>
        )}
      </ConfirmProvider>
    </ThemeProvider>
  );
}
