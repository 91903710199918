import React from 'react';
import Icon from '@mui/material/Icon';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import SuiButton from '../../components/SuiButton';
import moment from 'moment';
import Axios from '../../axios/authAxios';
import download from 'downloadjs';

function Invoices({ invoice }) {


  return (
    <SuiBox bgColor="grey-100" borderRadius="lg" p={3} mt={2}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
          {`Facture n° ${invoice && invoice.attributes && invoice.attributes.number}`}
        </SuiTypography>

        <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
          {invoice && invoice.attributes && invoice.attributes.customer_name}
        </SuiTypography>

        <SuiBox mb={1} lineHeight={0} display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="text" mb={1}>
            Date de facturation:
          </SuiTypography>
          <SuiTypography
            variant="caption"
            fontWeight="medium"
            textTransform="capitalize"
            textAlign="center"
          >
            {moment(invoice && invoice.attributes && invoice.attributes.date).format('DD - MM - YYYY')}
          </SuiTypography>
        </SuiBox>

        <SuiBox mb={1} lineHeight={0} display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="text" mb={1}>
            Remise global:
          </SuiTypography>
          <SuiTypography variant="caption" fontWeight="medium" textAlign="center">
            {`${invoice && invoice.attributes && invoice.attributes.discount} $`}
          </SuiTypography>
        </SuiBox>

        <SuiBox mb={1} lineHeight={0} display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="text" mb={1}>
            Prix total:
          </SuiTypography>
          <SuiTypography variant="caption" fontWeight="medium" textAlign="center">
            {`${Number(invoice && invoice.attributes && invoice.attributes.total_price).toFixed(3)} $`}
          </SuiTypography>
        </SuiBox>

        <SuiBox mb={1} lineHeight={0} display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="text" mb={1}>
            Etat de la facture:
          </SuiTypography>
          <SuiTypography variant="caption" fontWeight="medium" textAlign="center">
            {invoice && invoice.attributes && invoice.attributes.status}
          </SuiTypography>
        </SuiBox>

        <SuiBox display="flex" justify="center" alignItems="center">
          <SuiButton
            variant="text"
            color="dark"
            onClick={() => {
              Axios.post(
                `/api/invoicePdf`,
                {
                  number: invoice.attributes.number,
                  discount_total: invoice.attributes.discount,
                  total: invoice.attributes.total_price,
                  total_tax: invoice.attributes.total_taxes,
                  customer_name: invoice.attributes.customer_name,
                  customer_adress: invoice.attributes.customer_adress,
                  email: invoice.attributes.customer_mail,
                  phone: invoice.attributes.customer_phoneNumber,
                  line_items: invoice.attributes.products,
                },
                {
                  headers: {},

                  responseType: 'blob',
                }
              ).then((res) => {
                const content = res.headers['content-type'];
                download(
                  res.data,
                  `Facture N° ${invoice.attributes.number}: ${invoice.attributes.customer_name}`,
                  content
                );
              });
            }}
          >
            <Icon>print</Icon>&nbsp;Imprimer
          </SuiButton>

          {/* <SuiButton
            variant="text"
            color="error"
            onClick={() =>
              handleDelete(form.id, form.attributes.customer_name, form.attributes.status, form.attributes.billed)
            }
          >
            <Icon>delete</Icon>&nbsp;Supprimer
          </SuiButton> */}
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default Invoices;
