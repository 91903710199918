import {
    SET_DEALERS,
    ADD_DEALER,
    REMOVE_DEALER,
    UPDATE_DEALER,
    SET_PRODUCT_BY_ID,
  } from '../types/dealersTypes.js';
  
  const initialState = {
    list: [],
    loading: false,
    selected: null,
    productById: {}
  };
  
  const dealersReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_DEALERS:
        return { ...state, list: action.payload };
      // case SET_PRODUCT_BY_ID:
      //   return { ...state, productById: action.payload };
      // //case ADD_DEALER:
      //   //return { ...state, list: [...state.list, action.payload] };
      // case REMOVE_DEALER:
      //   return { ...state, list: [...state.list] };
      // case UPDATE_DEALER:
      //   return {... state , list :  [...state.list]};
      default:
        return state;
    }
  };
  
  export default dealersReducer;
  