import React from 'react';
import PropTypes from 'prop-types';
import typography from '../../../../assets/theme/base/typography';
import SuiBox from '../../../../components/SuiBox';
import SuiTypography from '../../../../components/SuiTypography';


function ReportsBarChartItem({ color, icon, label, progress }) {
  const { size } = typography;


  return (
    <SuiBox width="100%">
      {/* <SuiBox display="flex" alignItems="center" mb={2}>
        <SuiBox
          bgColor={icon.color}
          width="1.25rem"
          height="1.25rem"
          borderRadius="sm"
          color="white"
          fontSize={size.xs}
          display="flex"
          justifyContent="center"
          alignItems="center"
          shadow="md"
          mr={1}
          variant="gradient"
        >
          <Icon>{icon.component}</Icon>
        </SuiBox>
        <SuiTypography
          variant="caption"
          textTransform="capitalize"
          fontWeight="medium"
          color="text"
        >
          {label}
        </SuiTypography>
      </SuiBox> */}
      <SuiBox mt={1} sx={{ backgroundColor: "#F7F9FB", borderRadius: "1rem" }} py={1} px={2}>
        <SuiTypography fontWeight="medium" color={color} sx={{ fontSize: "15px" }}>
          {label}
        </SuiTypography>
        <SuiTypography fontWeight="medium" color="secondary" sx={{ fontSize: "13px" }}>
          Quantité: {progress}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of ReportsBarChartItem
ReportsBarChartItem.defaultProps = {
  color: 'dark',
};

// Typechecking props for the ReportsBarChartItem
ReportsBarChartItem.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark'])
      .isRequired,
    component: PropTypes.node.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    content: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
  }).isRequired,
};

export default ReportsBarChartItem;
