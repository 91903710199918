import { SET_INVOICES, ADD_INVOICE } from '../types/invoicesTypes';

const initialState = {
  list: [],
  loading: false,
  selected: null,
};

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICES:
      return { ...state, list: action.payload };
    case ADD_INVOICE:
      return { ...state, list: [...state.list, action.payload] };
    default:
      return state;
  }
};

export default invoicesReducer;
