import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from '@react-google-maps/api';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Grid from '@mui/material/Grid';
import SuiBox from '../../components/SuiBox';
import Card from '@mui/material/Card';
import moment from 'moment';
import 'moment/locale/fr';
import { useDispatch, useSelector } from 'react-redux';
import DateFilter from '../../components/DateFilter';
import { fetchOrders, requestSetDealer } from '../../redux/actions/ordersActionCreators';
import GlobalLoading from '../../components/GlobalLoading';
import { fetchDealers } from '../../redux/actions/dealersActionCreators';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import DeliveyCard from './DeliveryCard';

const options = {
  fullscreenControl: false,
  disableDefaultUI: false,
  scaleControl: true,
  mapTypeId: 'roadmap',
  labels: true,
  styles: [
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [{
            "visibility": "on"
          }]
      }, {
      featureType: 'all',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
  ],
};

export default function Map() {
  moment.locale('fr');
  const dealers = useSelector((state) => state.dealers.list);

  const [center, setCenter] = useState({
    lat: 34.79804005882154,
    lng: 10.782014169316348,
  });
  const [zoom, setZoom] = useState(7);
  const [bounds, setBounds] = useState(null);
  const [map, setMap] = useState(null);
  const [filteredLocs, setFilterdLocs] = useState([]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  const [activeMarker, setActiveMarker] = useState(null);
  const [position, setPosition] = useState(0.0085);
  const [after, setAfter] = useState(
    moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss')
  );
  const [before, setBefore] = useState(
    moment().set({ hour: 0, minute: 0, second: 0 }).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
  );
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [final, setFinal] = useState([]);

  const [date, setDate] = useState([
    {
      startDate: moment()._d,
      endDate: moment()._d,
      key: 'selection',
    },
  ]);

  const userRole = localStorage.getItem('role');
  const storedUser = localStorage.getItem('user');

  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.list);

  const starter = () => {
    dispatch(fetchDealers({ role: 'Delivery' }));
    dispatch(
      fetchOrders({
        search: '',
        filter: 'any',
        page: 1,
        after,
        before,
        per_page: 100,
        from_map: true,
      })
    );
  }

  useEffect(() => {
    starter()
  }, [dispatch]);

  useEffect(() => {
    if (orders && orders.length) {
      if (bounds) {
        orders.filter((order) => bounds.contains(order.position));
        setFilterdLocs([...orders.filter((order) => bounds.contains(order.position))]);
      } else {
        setFilterdLocs([...orders]);
      }
    }
  }, [orders]);

  const handleCloseCard = () => {
    setOpenCard(false);
  };

  useEffect(() => {
    dispatch(
      fetchOrders({
        search: '',
        filter: 'any',
        page: 1,
        after,
        before,
        per_page: 100,
        from_map: true,
      })
    );
  }, [dispatch, after, before]);

  const loadHandler = (map) => {
    setMap(map);
  };
  const onBoundsChanged = () => {
    let boundries = map.getBounds();
    let pos = 0.0085;
    if (map.zoom > 12) {
      pos = 0.0085 / Math.pow(2, map.zoom - 12);
    } else {
      pos = 0.0085 * Math.pow(2, 12 - map.zoom);
    }
    let fitleredOrders = orders.filter((order) => boundries.contains(order.position));
    setFilterdLocs([...fitleredOrders]);
    setPosition(pos);
    setBounds(boundries);
  };
  // const handleActiveMarker = (marker) => {
  //   if (marker === activeMarker) {
  //     return;
  //   }
  //   setActiveMarker(marker);
  // };
  const handleAll = () => {
    setDate([{ ...date[0], startDate: '', endDate: '' }]);
    setAfter('');
    setBefore('');
  };

  const handleToday = () => {
    setDate([{ ...date[0], startDate: moment()._d, endDate: moment()._d }]);
    setAfter(moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss'));
    setBefore(
      moment().set({ hour: 0, minute: 0, second: 0 }).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
    );
  };
  const handleDate = (item) => {
    setDate([item]);
    setAfter(
      moment(item.startDate).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss')
    );
    setBefore(
      moment(item.endDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .add(1, 'days')
        .format('YYYY-MM-DDTHH:mm:ss')
    );
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const selectDelivery = (params) => {
    setSelectedDealer(params);
  };
  const selectOrder = (order) => {
    setSelectedOrder(order);
    if (userRole === 'Delivery') {
      setOpenCard(true);
    } else {
      if (order.dealer) {
        let dealer = dealers.find((item) => item.id === order.dealer);
        setSelectedDealer(JSON.parse(JSON.stringify(dealer)));
      } else {
        setSelectedDealer(null);
      }
      if (order.status !== 'completed') {
        setOpenModal(true);
      }
    }
  };
  const setDealer = async () => {
    if (selectedOrder && selectedDealer) {
      setFilterdLocs([]);
      dispatch(
        requestSetDealer({
          dealer: selectedDealer.id,
          order: selectedOrder.id,
          delivery: selectedOrder.delivery,
        })
      );
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (userRole === 'Delivery') {
      setFinal(filteredLocs.filter((el) => el.dealer == JSON.parse(storedUser).id));
    } else {
      setFinal(filteredLocs);
    }
  }, [filteredLocs]);

  // const setNewCenter = (newCenter, markerId) => {
  //   setCenter(newCenter);
  //   setZoom(18);
  //   if(markerId !== activeMarker) {
  //     handleActiveMarker(markerId);
  //   } else {
  //     setActiveMarker(null);
  //   }
  //   map.setZoom(18);
  // }
  // const handleActiveMarker = (marker) => {
  //   if (marker === activeMarker) {
  //     return;
  //   }
  //   setActiveMarker(marker);
  // };

  const renderMap = () => {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox mb={1.5} height={400}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <GoogleMap
                id="circle-example"
                mapContainerStyle={{
                  width: '100%',
                  height: '750px',
                }}
                zoom={zoom}
                center={center}
                options={options}
                onLoad={loadHandler}
                onBoundsChanged={onBoundsChanged}
                clickableIcons={false}
              >
                <MarkerClusterer>
                  {(clusterer) =>
                    final.map((loc, i) => (
                      <Marker
                        key={i}
                        position={loc.position}
                        clusterer={clusterer}
                        icon={{
                          url: loc.delivery
                            ? require('../../assets/images/google-map3.png')
                            : loc.isFrais
                              ? require('../../assets/images/google-map2.png')
                              : require('../../assets/images/google-map1.png'),
                        }}
                        // onClick={() => handleActiveMarker(loc.id)}
                        onClick={() => selectOrder(loc)}
                      >
                        {activeMarker === loc.id && (
                          <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}
                            position={{
                              lat: loc.position.lat + position,
                              lng: loc.position.lng,
                            }}
                          >
                            {loc.shipping && (
                              <div>
                                <p style={{ marginBottom: 0, lineHeight: 1.1 }}>
                                  {loc.shipping.first_name && (
                                    <strong>{loc.shipping.first_name}</strong>
                                  )}
                                  {loc.shipping.last_name && (
                                    <strong>{loc.shipping.last_name}</strong>
                                  )}
                                </p>
                                <p style={{ marginBottom: 0, lineHeight: 1.1 }}>
                                  <small>
                                    {loc.shipping.email || (loc.billing && loc.billing.email)}
                                  </small>
                                </p>
                                <p style={{ marginBottom: 0, lineHeight: 1.1 }}>
                                  <small>
                                    {loc.shipping.phone || (loc.billing && loc.billing.phone)}
                                  </small>
                                </p>
                                <p style={{ marginBottom: 0, lineHeight: 1.1 }}>
                                  {loc.shipping.address_1 && (
                                    <small>{loc.shipping.address_1}</small>
                                  )}
                                  {loc.shipping.address_2 && (
                                    <small>{loc.shipping.address_2}</small>
                                  )}
                                  {loc.shipping.postcode && <small>{loc.shipping.postcode}</small>}
                                  {loc.shipping.city && <small>{loc.shipping.city}</small>}
                                </p>
                              </div>
                            )}
                          </InfoWindow>
                        )}
                      </Marker>
                    ))
                  }
                </MarkerClusterer>
              </GoogleMap>
            </Grid>
            <Grid item xs={12} lg={4}>
              <GlobalLoading />
              <DateFilter
                date={date}
                handleAll={handleAll}
                handleToday={handleToday}
                handleDate={handleDate}
              />
              <div style={{ height: 700, overflowY: 'auto', marginTop: 20 }}>
                {final && final.length
                  ? final.map((loc) => (
                    <Card
                      key={loc.id}
                      style={{
                        marginBottom: 20,
                        marginRight: 20,
                        backgroundColor: loc.status === 'completed' ? '#dbd2d2' : null,
                        cursor:
                          loc.status !== 'completed'
                            ? 'pointer'
                            : userRole === 'Delivery' && loc.status === 'completed'
                              ? 'pointer'
                              : null,
                      }}
                      // onMouseEnter={() => handleActiveMarker(loc.id)}
                      // onMouseLeave={() => setActiveMarker(null)}
                      // onClick={() => setNewCenter(loc.position, loc.id)}
                      onClick={() => selectOrder(loc)}
                    >
                      <SuiBox mt={2} mx={2}>
                        {loc.shipping && (
                          <div>
                            <p style={{ marginBottom: 0, lineHeight: 1.1 }}>
                              {loc.shipping.first_name && (
                                <strong>{loc.shipping.first_name}</strong>
                              )}
                              {loc.shipping.last_name && (
                                <strong>{loc.shipping.last_name}</strong>
                              )}
                            </p>
                            <p style={{ marginBottom: 0, lineHeight: 1.1 }}>
                              <small>
                                {loc.shipping.email || (loc.billing && loc.billing.email)}
                              </small>
                            </p>
                            <p style={{ marginBottom: 0, lineHeight: 1.1 }}>
                              <small>
                                {loc.shipping.phone || (loc.billing && loc.billing.phone)}
                              </small>
                            </p>
                            <p style={{ marginBottom: 0, lineHeight: 1.1 }}>
                              {loc.shipping.address_1 && <small>{loc.shipping.address_1}</small>}
                              {loc.shipping.address_2 && <small>{loc.shipping.address_2}</small>}
                              {loc.shipping.postcode && <small>{loc.shipping.postcode}</small>}
                              {loc.shipping.city && <small>{loc.shipping.city}</small>}
                            </p>
                          </div>
                        )}
                        {loc.currency && loc.total && (
                          <div>
                            <small>
                              Prix totale:{' '}
                              <strong>
                                {loc.total} {loc.currency}
                              </strong>
                            </small>
                          </div>
                        )}
                        <div style={{ color: loc.status === 'completed' ? 'green' : null }}>
                          <small>
                            Etat:{' '}
                            <strong>
                              {loc.status === 'processing'
                                ? 'En cours'
                                : loc.status === 'delevering'
                                  ? 'En livraison'
                                  : loc.status === 'cancelled'
                                    ? 'Annulée'
                                    : 'Terminé'}
                            </strong>
                          </small>
                        </div>

                        {loc.dealer &&
                          loc.delivery &&
                          dealers &&
                          dealers.length &&
                          dealers.find((dealer) => dealer.id === loc.dealer) && (
                            <div style={{ color: 'blue' }}>
                              <small>
                                Livreur:{' '}
                                <strong>
                                  {dealers.find((dealer) => dealer.id === loc.dealer)['name']}
                                </strong>
                              </small>
                            </div>
                          )}
                        {loc.isFrais ? (
                          <div style={{ color: '#EA0606' }}>
                            <small>
                              <Tooltip title="Commande prioritaire">
                                <svg
                                  width="25"
                                  height="25"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ transform: 'scale(0.6)', transformOrigin: 'bottom' }}
                                >
                                  <path
                                    d="M9.75 5C9.33579 5 9 5.33579 9 5.75V10.716C9 10.8921 9.09268 11.0552 9.24397 11.1454L13.6109 13.7488C13.941 13.9456 14.3673 13.8491 14.5804 13.5294C14.8088 13.1867 14.7045 12.7227 14.3514 12.5108L10.7305 10.3383C10.5875 10.2525 10.5 10.098 10.5 9.9312V5.75C10.5 5.33579 10.1642 5 9.75 5ZM19 10C18.4477 10 18 10.4477 18 11V15C18 15.5523 18.4477 16 19 16C19.5523 16 20 15.5523 20 15V11C20 10.4477 19.5523 10 19 10ZM19 18C18.4477 18 18 18.4477 18 19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18ZM16 18C14.3 19.3 12.3 20 10 20C4.5 20 0 15.5 0 10C0 4.5 4.5 0 10 0C14.8 0 18.9 3.4 19.8 8H17.7C16.8 4.6 13.7 2 10 2C5.6 2 2 5.6 2 10C2 14.4 5.6 18 10 18C12.4 18 14.5 16.9 16 15.3V18Z"
                                    fill="#EA0606"
                                  />
                                </svg>
                              </Tooltip>
                              <strong>Commande Frais</strong>
                            </small>
                          </div>
                        ) : (
                          <div style={{ color: '#344767' }}>
                            <small>
                              <Tooltip title="Commande non prioritaire">
                                <svg
                                  width="25"
                                  height="25"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ transform: 'scale(0.6)', transformOrigin: 'bottom' }}
                                >
                                  <path
                                    d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C4.47 20 0 15.5 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM9.75 5C10.1642 5 10.5 5.33579 10.5 5.75V9.97129C10.5 10.1441 10.5911 10.3041 10.7397 10.3922L14.3746 12.5489C14.7188 12.7531 14.8298 13.1992 14.6214 13.5409C14.4159 13.878 13.9768 13.9861 13.6383 13.783L9.24275 11.1457C9.09215 11.0553 9 10.8925 9 10.7169V5.75C9 5.33579 9.33579 5 9.75 5Z"
                                    fill="#344767"
                                  />
                                </svg>
                              </Tooltip>
                              <strong>Commande non Frais</strong>
                            </small>
                          </div>
                        )}
                      </SuiBox>
                    </Card>
                  ))
                  : 'Aucune commande a afficher dans cette zone'}
              </div>
            </Grid>
          </Grid>
        </SuiBox>
        <DeliveyCard
          open={openCard}
          handleClose={handleCloseCard}
          order={selectedOrder}
          starter={starter}
        />
        <Dialog
          open={openModal}
          onClose={handleClose}
          maxWidth="xl"
          PaperProps={{ sx: { width: '80%' } }}
        >
          <DialogTitle>Séléctionner un livreur </DialogTitle>
          <DialogContent>
            <Autocomplete
              options={dealers}
              name="dealer"
              getOptionLabel={(option) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, value) => selectDelivery(value)}
              renderInput={(params) => <TextField {...params} placeholder="Selectionner Livreur" />}
              defaultValue={selectedDealer}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit" onClick={() => setDealer()}>
              Sauvegarder
            </Button>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    );
  };
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }
  return isLoaded ? renderMap() : <div>Loading...</div>;
}
