import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { requestAddingPhotos } from '../../redux/actions/assetsActionCreators';
import { useDispatch } from 'react-redux';
import { requestSucceeded } from '../../redux/actions/feedbackActionCreators';

export default function AddImages({ id, open, handleUpload }) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState();
  const uploadImage = async (e) => {
    e.preventDefault();

    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();

      formData.append('files', files[i]);
      formData.append('refId', id);
      formData.append('ref', 'api::asset.asset');
      formData.append('field', 'content');

      dispatch(requestAddingPhotos(formData));

    }
    dispatch(requestSucceeded('Images ajoutés avec succès!'));


  };

  return (
    <Dialog
      open={open}
      onClose={handleUpload}
      maxWidth="xl"
      maxHeight="xl"
      PaperProps={{ sx: { width: '100%' } }}
    >
      <DialogTitle>Ajouter des photos </DialogTitle>

      <form onSubmit={uploadImage}>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
            <input type="file" multiple onChange={(e) => setFiles(e.target.files)} accept=".jpg,.jpeg,.png" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleUpload()}>Annuler</Button>
          <Button type="submit" value="Submit" onClick={() => handleUpload()}>
            Submit{' '}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
