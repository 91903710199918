import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SuiTypography from '../../components/SuiTypography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import { requestAddingVariation } from '../../redux/actions/productsVariationsActionCreators';
import { fetchAttributes } from '../../redux/actions/attributesActionCreators';
import { fetchProductById } from '../../redux/actions/productsActionCreators';
import AddRemoveInputField from './AddRemoveAttributes';

export default function AddModal({ open, handleClose, initialValues, id, data, update }) {
  const dispatch = useDispatch();

  const attributes = useSelector((state) => state.attributes.list);
  const product = useSelector((state) => state.products.productById);

  const [customersData, setCustomersData] = useState([]);
  const [attr, setAttr] = useState([]);

  const handleChange = (data) => {
    setAttr(data);
  };

  console.log({ data });

  useEffect(() => {
    dispatch(fetchProductById(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);

  const initValSchema = {
    regular_price: Yup.string()
      .matches(/^-?[0-9,\.]+$/, 'Doit contenir uniquement des chiffres')
      .required('Ce champ est obligatoire!'),
    stock_quantity: Yup.string()
      .matches(/^-?[0-9,\.]+$/, 'Doit contenir uniquement des chiffres')
      .required('Ce champ est obligatoire!'),
    attributes: Yup.array().required('Ce champ est obligatoire!'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema:
      // !isUpdating &&
      Yup.object(initValSchema),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        requestAddingVariation({
          id: id,
          regular_price: values.regular_price,
          stock_quantity: values.stock_quantity,
          attributes: attr,
          manage_stock: true,
        })
      );
      resetForm({ values: '' });
      handleClose();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{ sx: { width: '80%' } }}>
        {console.log('From', formik.values)}
        <DialogTitle>Ajouter une variation</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <AddRemoveInputField
              attributes={attributes}
              handleChangeInput={handleChange}
              product={product}
              data={data}
              update={update}
            />

            <hr style={{ marginTop: '45px', marginBottom: '30px' }} />

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Quantité
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Quantité"
                    name="stock_quantity"
                    error={Boolean(formik.touched.stock_quantity && formik.errors.stock_quantity)}
                    helperText={formik.touched.stock_quantity && formik.errors.stock_quantity}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.stock_quantity}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Prix
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Prix"
                    name="regular_price"
                    error={Boolean(formik.touched.regular_price && formik.errors.regular_price)}
                    helperText={formik.touched.regular_price && formik.errors.regular_price}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.regular_price}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit">Ajouter</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
