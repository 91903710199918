import Api from '../../axios/api';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import {
  SET_CUSTOMERS,
  REMOVE_CUSTOMERS,
  ADD_CUSTOMERS,
  UPDATE_CUSTOMERS,
} from '../types/customersTypes.js';

export const setCustomers = (customers, totalPages, totalCustomers) => ({
  type: SET_CUSTOMERS,
  payload: { customers: customers, totalPages: totalPages, totalCustomers: totalCustomers },
});
export const addCustomer = (customer) => ({ type: ADD_CUSTOMERS, payload: customer });
export const removeCustomer = (id) => ({ type: REMOVE_CUSTOMERS, payload: id });
export const updateCustomer = (id, customer) => ({ type: UPDATE_CUSTOMERS, payload: id, customer });

export const fetchCustomers = (page) => (dispatch) => {
  dispatch(requestStarted());
  Api.get(`customers?page=${page}&per_page=10`)
    .then((res) => {
      dispatch(setCustomers(res.data, res.headers['x-wp-totalpages'], res.headers['x-wp-total']));
      dispatch(requestSucceeded());
    })
    .catch((err) => {
      console.log({ err });
      dispatch(requestFailed(err.response.data.message));
    });
};

export const requestAddingCustomer =
  (first_name, last_name, username, email, phone, page) => (dispatch) => {
    dispatch(requestStarted());
    Api.post('customers', {
      first_name: first_name,
      last_name: last_name,
      username: username,
      email: email,
      billing: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
      },
      shipping: {
        first_name: first_name,
        last_name: last_name,
      },
      password: '12345678',
    })
      .then((res) => {
        dispatch(requestSucceeded('Client ajouté avec succès!'));
        dispatch(addCustomer(res.data));
        dispatch(fetchCustomers(page));
      })
      .catch((error) => {
        console.log(error);
        dispatch(requestFailed(error.response.data.message));
      });
  };

export const requestRemovingCustomer = (id, page) => async (dispatch) => {
  dispatch(requestStarted());
  Api.delete(`customers/${id}`, {
    force: true,
  })
    .then((res) => {
      dispatch(requestSucceeded('Client supprimé avec succès!'));
      dispatch(removeCustomer(id));
      dispatch(fetchCustomers(page));
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestFailed(error.response.data.message));
    });
};

export const requestUpdatingCustomer = (id, first_name, last_name, email, phone) => (dispatch) => {
  dispatch(requestStarted());
  Api.put(`customers/${id}`, {
    first_name: first_name,
    last_name: last_name,
    email: email,
    billing: {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
    },
    shipping: {
      first_name: first_name,
      last_name: last_name,
    },
  })
    .then((res) => {
      dispatch(requestSucceeded('Client mis à jour avec succès!'));
      // dispatch(updateProduct(id, res.data));
      dispatch(fetchCustomers());
    })
    .catch((error) => {
      console.log(error.response.data);
      dispatch(requestFailed(error.response.data.message));
    });
};
