import Axios from '../../axios/authAxios';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import { SET_INVOICES, ADD_INVOICE } from './../types/invoicesTypes';
import { fetchForms } from './deliveryFormsActionCreators'
import moment from 'moment'

export const setInvoice = (invoices) => ({ type: SET_INVOICES, payload: invoices });
export const addInvoice = (invoice) => ({ type: ADD_INVOICE, payload: invoice });


export const fetchInvoices = () => (dispatch) => {
  dispatch(requestStarted());
  Axios.get('/api/invoices?populate=*')
    .then((res) => {
      dispatch(requestSucceeded());
      const { data } = res.data;
      console.log(data);
      dispatch(setInvoice(data));
    })
    .catch((err) => {
      console.log({ err });
      dispatch(requestFailed(err.message));
    });
};

export const requestAddingInvoice = (payload) => (dispatch) => {
  const {
    customer_name,
    customer_adress,
    customer_phoneNumber,
    customer_mail,
    total_taxes,
    total_price,
    discount,
    products,
    id
  } = payload;
  dispatch(requestStarted());
  let temp = 0;
  Axios.get(`/api/invoices`).then((res) => {
    res.data.data.forEach((element) => {
      if (temp < element.id) {
        temp = element.id;
      }
    });
    temp = temp + 1;
    Axios.post('/api/invoices', {
      data: {
        number: temp.toString(),
        date: moment().format('YYYY-MM-DD'),
        customer_name: customer_name,
        customer_adress: customer_adress,
        customer_phoneNumber: customer_phoneNumber,
        customer_mail: customer_mail,
        total_taxes: total_taxes,
        total_price: (Number(total_price) + 0.600).toString(),
        discount: discount,
        products: products,
        status: 'Pending',
        delivery_form: id
      },
    })
      .then((res) => {
        dispatch(addInvoice(res.data))
        
        Axios.put(`/api/delivery-forms/${id}`, {
          data: {
            billed: true
          }
        })
        .then((res) => {
          dispatch(requestSucceeded('Facture généré avec succée!'));
          dispatch(fetchForms());
        })
      })
      .catch((err) => console.log(err));
  });
};