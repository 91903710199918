import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import SuiTypography from '../../components/SuiTypography';
import { fetchProducts } from '../../redux/actions/productsActionCreators';

function AddRemoveInputField({ handleChangeInput, prod, isUpdating }) {
  const ref0 = useRef();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.list);
  const loading = useSelector((state) => state.feedback.loading);

  const [stock, setStock] = useState('');
  const [inputFields, setInputFields] = useState(
    isUpdating
      ? prod
      : [
        {
          product_id: '',
          quantity: '',
          stock: '',
        },
      ]
  );
  const [search, setSearch] = useState('');
  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        product_id: '',
        quantity: '',
        stock: '',
      },
    ]);
  };

  useEffect(() => {
    dispatch(fetchProducts({ search: search, filter: '', page: 1 }));
  }, [dispatch, search]);

  useEffect(() => {
    console.log({ stock });
  }, [stock]);

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChange = (index, value, name) => {
    console.log(value, name);
    // const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  useEffect(() => {
    handleChangeInput(inputFields);
  }, [inputFields]);
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          {inputFields.map((data, index) => {
            return (
              <Grid container spacing={2} display="flex" alignItems="center" mb={2}>
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Articles
                    </SuiTypography>
                    <Autocomplete
                      // disablePortal
                      onInputChange={(event, value) => setSearch(value)}
                      id="combo-box-demo"
                      ref={ref0}
                      options={products}
                      loading={loading}
                      loadinngText="Veuillez patientez...!"
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(event, value) => {
                        handleChange(index, value && value.id, ref0.current.getAttribute('name'));
                        handleChange(index, value && value.stock_quantity, 'stock');
                      }}
                      name="product_id"
                      // sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Articles" required />
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Quantité
                    </SuiTypography>
                    <TextField
                      type="text"
                      placeholder="Quantité"
                      required
                      onChange={(evnt) => handleChange(index, evnt.target.value, evnt.target.name)}
                      name="quantity"
                      error={data.quantity > data.stock}
                      helperText={
                        data.quantity > data.stock && 'Quantité supérieur au stock disponible!'
                      }
                    // defaultValue={quantity}
                    />
                  </Stack>
                </Grid>
                {!isUpdating && (
                  <>
                    {data.stock && (
                      <Grid item xs={2}>
                        <Stack spacing={2}>
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Stock disponible
                          </SuiTypography>
                          <SuiTypography component="label" variant="caption">
                            {data.stock}
                          </SuiTypography>
                        </Stack>
                      </Grid>
                    )}
                    <Grid item xs={2}>
                      <Stack spacing={2}>
                        {inputFields.length !== 1 ? (
                          <Button style={{ color: 'red' }} onClick={removeInputFields}>
                            Supprimer cet article
                          </Button>
                        ) : (
                          ''
                        )}
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            );
          })}

          {!isUpdating && (
            <div className="row">
              <Button
                className="btn btn-outline-success "
                onClick={() => {
                  addInputField();
                  setSearch('');
                }}
              >
                Ajouter un autre article
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default AddRemoveInputField;
