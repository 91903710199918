import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import SuiButton from '../../components/SuiButton';
import Icon from '@mui/material/Icon';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Table from '../../examples/Tables/Table';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCustomers,
  requestRemovingCustomer,
} from '../../redux/actions/cutomersActionCreators';
import { useConfirm } from 'material-ui-confirm';
import AddCustomer from './AddCustomer';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';

function Tables() {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const [open, setOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);

  const handleDelete = (id, firstName, lastName) => {
    confirm({
      description: `Voulez vous supprimer définitivement le client ${firstName} ${lastName}?`,
    })
      .then(() => {
        dispatch(requestRemovingCustomer(id));
      })
      .catch(() => console.log('Supression Annulé'));
  };
  useEffect(() => {
    dispatch(fetchCustomers(page));
  }, [dispatch, page]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const customers = useSelector((state) => state.customers.list);
  const totalCustomers = useSelector((state) => state.customers.totalCustomers);
  const totalPages = useSelector((state) => state.customers.totalPages);

  const columns = [
    { name: "date d'inscription", align: 'center' },
    { name: 'nom & prénom', align: 'center' },
    { name: "nom d'utilisateur", align: 'center' },
    { name: 'adresse mail', align: 'center' },
    { name: 'telephone', align: 'center' },
    { name: 'rôle', align: 'center' },
    { name: 'action', align: 'center' },
  ];

  const rows = customers.map((el) => ({
    id: el.id,
    "date d'inscription": moment(el && el.date_created).format('DD-MM-YYYY'),
    'nom & prénom': `${el.first_name} ${el.last_name}`,
    "nom d'utilisateur": el.username,
    'adresse mail': el.email,
    telephone: el.billing.phone,
    rôle: el.role ? 'Client' : 'Abonné',
    action: (
      <>
        <Icon
          sx={{ fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => {
            handleClickOpen();
            setIsUpdating(true);
            setData(el);
          }}
        >
          edit
        </Icon>
        &nbsp;&nbsp;
        <Icon
          sx={{ fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => handleDelete(el.id, el.first_name, el.last_name)}
        >
          delete
        </Icon>
      </>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Les Clients</SuiTypography>
              <SuiButton variant="gradient" color="dark" onClick={handleClickOpen}>
                <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                &nbsp;Ajouter un client
              </SuiButton>
            </SuiBox>
            <SuiBox
              sx={{
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </SuiBox>
            <Grid container mb={2} mt={2} ml={2}>
              <Grid item xs={10}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  onChange={(e, value) => setPage(value)}
                />
              </Grid>
              <Grid item xs={2}>
                <SuiTypography
                  variant="button"
                  fontWeight="medium"
                  textTransform="capitalize"
                >{`Nombre de résultats: ${totalCustomers}`}</SuiTypography>
              </Grid>
            </Grid>
          </Card>
        </SuiBox>
        {/* <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">Projects table</SuiTypography>
          </SuiBox>
          <SuiBox
            sx={{
              '& .MuiTableRow-root:not(:last-child)': {
                '& td': {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SuiBox>
        </Card> */}
      </SuiBox>
      {isUpdating ? (
        <AddCustomer
          open={open}
          handleClose={handleClose}
          page={page}
          isUpdating={true}
          initialValues={{
            first_name: data.first_name,
            last_name: data.last_name,
            username: data.username,
            email: data.email,
            phone: data.billing.phone,
          }}
          id={data.id}
        />
      ) : (
        <AddCustomer
          open={open}
          handleClose={handleClose}
          page={page}
          isUpdating={false}
          initialValues={{
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            phone: '',
          }}
        />
      )}
    </DashboardLayout>
  );
}

export default Tables;
