import Api from '../../axios/api';
import {
    PRODUCTS_REPORT,
    ORDERS_REPORT,
    CUSTOMERS_REPORT,
    SALES_REPORT,
    TOP_SELLERS,
} from '../types/reportsTypes';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import moment from 'moment';

export const setProductsReport = (productReport) => ({
    type: PRODUCTS_REPORT,
    payload: productReport,
});
export const setOrdersReport = (orderReport) => ({ type: ORDERS_REPORT, payload: orderReport });
export const setCustomersReport = (customerReport) => ({
    type: CUSTOMERS_REPORT,
    payload: customerReport,
});

export const setTopSellers = (topSellers) => ({ type: TOP_SELLERS, payload: topSellers });
export const setSalesReport = (salesReport, actualSales, previousSales) => ({
    type: SALES_REPORT,
    payload: { salesReport: salesReport, actualSales: actualSales, previousSales: previousSales },
});

export const fetchProductsReport = () => (dispatch) => {
    dispatch(requestStarted());
    Api.get('reports/products/totals')
        .then((res) => {
            dispatch(requestSucceeded());
            console.log(res.data);
            dispatch(setProductsReport(res.data));
        })
        .catch((err) => {
            console.log({ err });
            dispatch(requestFailed(err.message));
        });
};

export const fetchOrdersReport = () => (dispatch) => {
    dispatch(requestStarted());
    Api.get('reports/orders/totals')
        .then((res) => {
            dispatch(requestSucceeded());
            console.log(res.data);
            dispatch(setOrdersReport(res.data));
        })
        .catch((err) => {
            console.log({ err });
            dispatch(requestFailed(err.message));
        });
};

export const fetchCustomersReport = () => (dispatch) => {
    dispatch(requestStarted());
    Api.get('reports/customers/totals')
        .then((res) => {
            dispatch(requestSucceeded());
            console.log(res.data);
            dispatch(setCustomersReport(res.data));
        })
        .catch((err) => {
            console.log({ err });
            dispatch(requestFailed(err.message));
        });
};

export const fetchSalesReport = (payload) => (dispatch) => {
    dispatch(requestStarted());
    Api.get('reports/sales', {
        date_min: payload.perviousStartDate,
        date_max: payload.endDate,
    })
        .then((res) => {
            const totals = res.data.map((el) => el.totals);
            const actualSales = [];
            const previousSales = [];
            for (
                let i = payload.startDate;
                i <= payload.endDate;
                i = moment(i).add(1, 'd').format('YYYY-MM-DD')
            ) {
                actualSales.push(totals[0][i].sales);
            }
            for (
                let i = payload.perviousStartDate;
                i <= payload.perviousEndDate;
                i = moment(i).add(1, 'd').format('YYYY-MM-DD')
            ) {
                previousSales.push(totals[0][i].sales);
            }
            dispatch(requestSucceeded());
            dispatch(setSalesReport(res.data, actualSales, previousSales));
        })
        .catch((err) => {
            console.log({ err });
            dispatch(requestFailed(err.message));
        });
};

export const fetchTopSellersReport = () => (dispatch) => {
    dispatch(requestStarted());
    Api.get('reports/top_sellers')
        .then((res) => {
            dispatch(requestSucceeded());
            console.log(res.data);
            dispatch(setTopSellers(res.data));
        })
        .catch((err) => {
            console.log({ err });
            dispatch(requestFailed(err.message));
        });
};
