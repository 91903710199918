import { SET_API_KEYS } from '../types/keysApiTypes';

const initialState = {
  keys: {},
  loading: false,
  selected: null,
};

const apiKeysReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_API_KEYS:
      return { ...state, keys: action.payload };
    default:
      return state;
  }
};

export default apiKeysReducer;
