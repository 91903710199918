import Api from '../../axios/api';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import {
    SET_CATEGORIES,
} from '../types/categoriesTypes.js';

export const setCategories = (categories) => ({ type: SET_CATEGORIES, payload: { categories: categories } });


export const fetchAllCategories = () => (dispatch) => {
    dispatch(requestStarted());

    const allCategories = []
    Api.get(`products/categories?per_page=100`)
        .then((res) => {
            dispatch(requestSucceeded());
            const totalPages = res.headers["x-wp-totalpages"]
            for (let i = 1; i <= totalPages; i++) {
                Api.get(`products/categories?per_page=100&page=${i}`)
                    .then((res) => {
                        allCategories.push(...res.data)
                    })
                    .catch((err) => {
                        console.log({ err });
                        dispatch(requestFailed(err.message));
                    });
            }
            dispatch(setCategories(allCategories))
        })
        .catch((err) => {
            console.log({ err });
            dispatch(requestFailed(err.message));
        });
};

