import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SuiTypography from '../../components/SuiTypography';
import SuiButton from '../../components/SuiButton';
import { requestTerminateOrder } from '../../redux/actions/ordersActionCreators';
import { useDispatch } from 'react-redux';

const DeliveryCard = ({ open, handleClose, order, starter }) => {
    const dispatch = useDispatch();
    const date_livraison =
        order &&
        order.meta_data &&
        order.meta_data.find((el) => el.key === 'delivery_date') &&
        order.meta_data &&
        order.meta_data.find((el) => el.key === 'delivery_date').value;

    const info =
        order &&
        order.meta_data &&
        order.meta_data.find((el) => el.key === '_billing_informations_manquantes') &&
        order.meta_data &&
        order.meta_data.find((el) => el.key === '_billing_informations_manquantes').value;
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>Commande N° {order && order.id} </DialogTitle>
            <DialogContent>
                <Grid container spacing={6} align="center">
                    <Grid item xs={12} align="center" display="flex" flexDirection="column" sx={{ gap: 2 }}>
                        <SuiTypography variant="overline" fontWeight="medium">
                            {`Nom et Prénom: ${order && order.billing.first_name} ${order && order.billing.last_name
                                }`}
                        </SuiTypography>
                        <SuiTypography variant="overline" fontWeight="medium">
                            {`Télephone: ${order && order.billing.phone}`}
                        </SuiTypography>
                        <SuiTypography variant="overline" fontWeight="medium">
                            {`Adresse mail: ${order && order.billing.email}`}
                        </SuiTypography>
                        <SuiTypography variant="overline" fontWeight="medium">
                            {`Date de livraison ${date_livraison}`}
                        </SuiTypography>
                        <SuiTypography variant="overline" fontWeight="medium">
                            {`Horaire: ${order &&
                                order.meta_data &&
                                order &&
                                order.meta_data.find((el) => el.key === 'delivery_time') &&
                                order &&
                                order.meta_data &&
                                order &&
                                order.meta_data.find((el) => el.key === 'delivery_time').value
                                }`}
                        </SuiTypography>
                        <SuiTypography variant="overline" fontWeight="medium">
                            {`Adresse: ${order && order.billing.address_1}`}
                        </SuiTypography>
                        <div style={{ color: order && order.status === 'completed' ? 'green' : null }}>
                            <small>
                                Etat:{' '}
                                <strong>
                                    {order && order.status === 'delevering' ? 'En livraison' : 'Terminé'}
                                </strong>
                            </small>
                        </div>
                        {info && (
                            <SuiTypography variant="overline" fontWeight="medium">
                                {`Information supplémentaire: ${info}`}
                            </SuiTypography>
                        )}
                        <SuiButton
                            variant="gradient"
                            color="dark"
                            sx={{ width: '210px', margin: 'auto' }}
                            disabled={order && order.status === "completed"}
                            onClick={() => {
                                dispatch(
                                    requestTerminateOrder({
                                        id: order.id,
                                    })
                                );
                                starter();
                                handleClose()
                            }
                            }
                        >
                            {order && order.status === "completed" ? "Commande livrée" : "Confirmer la livraison"}
                        </SuiButton>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeliveryCard;
