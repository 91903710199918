import {
  SET_CUSTOMERS,
  REMOVE_CUSTOMERS,
  ADD_CUSTOMERS,
  UPDATE_CUSTOMERS,
} from '../types/customersTypes.js';

const initialState = {
  list: [],
  loading: false,
  selected: null,
  totalPages: null,
  totalCustomers: null,
};

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS:
      return {
        ...state,
        list: action.payload.customers,
        totalPages: action.payload.totalPages,
        totalCustomers: action.payload.totalCustomers,
      };
    case ADD_CUSTOMERS:
      return { ...state, list: [...state.list, action.payload] };
    case REMOVE_CUSTOMERS:
      return { ...state, list: [...state.list] };
    case UPDATE_CUSTOMERS:
      return state.list.map((cust) =>
        cust.id === action.payload.id ? { ...state, cust: action.payload } : cust
      );
    default:
      return state;
  }
};

export default customersReducer;
