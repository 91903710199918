import axios from 'axios';

const authAxios = axios.create({baseURL: `${process.env.REACT_APP_API_URL}`}
);
authAxios.interceptors.request.use(request => {
    // add auth header with jwt if account is logged in
    const token = localStorage.getItem("token");
    if (token ) {
        request.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
});

const responseSuccessHandler = response =>  response;
  ;
  
  const responseErrorHandler = error => {
    if (error.response.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location='/login'
    }
  
    return Promise.reject(error);
  };
  
  authAxios.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
  );
  

export default authAxios
