import Api from '../../axios/api';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import { SET_ATTRIBUTES, ADD_ATTRIBUT, REMOVE_ATTRIBUT } from '../types/attributesTypes';

export const setAttributes = (attributes) => ({ type: SET_ATTRIBUTES, payload: attributes });
export const addAttribut = (attribut) => ({ type: ADD_ATTRIBUT, payload: attribut });
export const removeAttribut = (id) => ({ type: REMOVE_ATTRIBUT, payload: id });

export const fetchAttributes = () => (dispatch) => {
  dispatch(requestStarted());
  let dataResult = [];
  Api.get('products/attributes')
    .then((response) => {
      console.log(response.data);
      for (let i = 0; i < response.data.length; i++) {
        const attribute = response.data[i];
        Api.get(`products/attributes/${attribute.id}/terms`).then((res) => {
          attribute.terms = res.data;
          console.log(attribute);
          dataResult.push(attribute);
          console.log('result', dataResult);
          dispatch(setAttributes([...dataResult]));
          dispatch(requestSucceeded());
        });
      }
    })
    .catch((err) => {
      console.log({ err });
      dispatch(requestFailed(err));
    });
};

export const requestAddingAttribut =
  ({ name, terms }) =>
  (dispatch) => {
    dispatch(requestStarted());
    Api.post('products/attributes', {
      name: name,
      type: 'select',
      order_by: 'menu_order',
      has_archives: true,
    })
      .then((response) => {
        for (let i = 0; i < terms.length; i++) {
          Api.post(`products/attributes/${response.data.id}/terms`, {
            name: terms[i],
          })
            .then((res) => {
              console.log(res.data);
            })
            .catch((error) => {
              console.log(error);
              dispatch(requestFailed(error.response.data.message));
            });
        }
        dispatch(requestSucceeded('Attribut ajouté avec succès!'));
        dispatch(fetchAttributes());
      })
      .catch((error) => {
        console.log(error);
        dispatch(requestFailed(error.response.data.message));
      });
  };

export const requestRemovingAttribut = (id) => (dispatch) => {
  dispatch(requestStarted());
  Api.delete(`products/attributes/${id}`, {
    force: true,
  })
    .then((res) => {
      dispatch(requestSucceeded('Attribut supprimé avec succès!'));
      dispatch(removeAttribut(id));
      dispatch(fetchAttributes());
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestFailed(error.response.data.message));
    });
};

