import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import MiniStatisticsCard from '../../examples/Cards/StatisticsCards/MiniStatisticsCard';
import ReportsBarChart from '../../examples/Charts/BarCharts/ReportsBarChart';
import GradientLineChart from '../../examples/Charts/LineCharts/GradientLineChart';
import typography from '../../assets/theme/base/typography';
import BuildByDevelopers from './components/BuildByDevelopers';
import WorkWithTheRockets from './components/WorkWithTheRockets';
import Projects from './components/Projects';
import OrderOverview from './components/OrderOverview';
import reportsBarChartData from './data/reportsBarChartData';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductsReport,
  fetchOrdersReport,
  fetchCustomersReport,
  fetchSalesReport,
  fetchTopSellersReport,
} from '../../redux/actions/reportsActionsCreators';
import moment from 'moment';

function Dashboard() {
  const dispatch = useDispatch();
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  const [percentage, setPercentage] = useState('0%');
  const [color, setColor] = useState('');
  const [todaySales, setTodaySales] = useState('');
  const [yesterdaySales, setYesterdaySales] = useState('');

  const dateMax = moment().format('YYYY-MM-DD');
  const dateMin = moment().subtract(1, 'd').format('YYYY-MM-DD');
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  const endDate = moment().endOf('month').format('YYYY-MM-DD');
  const perviousStartDate = moment(startDate).subtract(1, 'M').format('YYYY-MM-DD');
  const perviousEndDate = moment(perviousStartDate).endOf('month').format('YYYY-MM-DD');

  useEffect(() => {
    dispatch(fetchProductsReport());
    dispatch(fetchOrdersReport());
    dispatch(fetchCustomersReport());
    dispatch(fetchSalesReport({ perviousStartDate, endDate, startDate, perviousEndDate }));
    dispatch(fetchTopSellersReport());
  }, [dispatch]);

  const {
    productsReport,
    ordersReport,
    customersReport,
    salesReport,
    topSellers,
    actualSales,
    previousSales,
  } = useSelector((state) => state.reports);

  const productsCount = productsReport.reduce((acc, curr) => acc + curr.total, 0);
  const ordersCount = ordersReport.reduce((acc, curr) => acc + curr.total, 0);
  const customersCount = customersReport.reduce((acc, curr) => acc + curr.total, 0);
  const totals = salesReport[0] && salesReport[0].totals;


  useEffect(() => {
    setTodaySales(totals && totals[dateMax].sales);
    setYesterdaySales(totals && totals[dateMin].sales);
  }, [salesReport]);

  useEffect(() => {
    if (todaySales > 0 && yesterdaySales) {
      const per = (100 - (yesterdaySales / todaySales) * 100).toFixed(2);
      if (per > 0) {
        setPercentage(` +${per}%`);
        setColor('success');
      } else {
        setPercentage(` ${per}%`);
        setColor('error');
      }
    } else {
      setPercentage(' 0%');
      setColor('success');
      setTodaySales(0);
    }
  }, [todaySales, yesterdaySales]);

  const gradientLineChartData = {
    labels: [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ],
    datasets: [
      {
        label: 'Ce mois',
        color: 'info',
        data: actualSales,
      },
      {
        label: 'Le mois dernier',
        color: 'dark',
        data: previousSales,
      },
    ],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'Nombre total des produits' }}
                count={productsCount}
                icon={{
                  color: 'info',
                  component: 'shopping_cart',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'Nombre total des commandes' }}
                count={ordersCount}
                icon={{ color: 'info', component: 'emoji_events' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'Nombre total des clients' }}
                count={customersCount}
                icon={{ color: 'info', component: 'public' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Aujourd'hui" }}
                count={`$ ${todaySales}`}
                percentage={{ color: color, text: percentage }}
                icon={{ color: 'info', component: 'paid' }}
              />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3} mt={8} >
          <Grid container spacing={3} sx={{ height: "560px" }}>
            <Grid item xs={12} lg={5} >
              <ReportsBarChart
                title="Produits exclusive"
                description=""
                chart={chart}
                items={topSellers}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Sales Overview"
                description={
                  <SuiBox display="flex" alignItems="center">
                    <SuiBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SuiBox>
                    <SuiTypography variant="button" color="text" fontWeight="medium">
                      4% more{' '}
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </SuiTypography>
                    </SuiTypography>
                  </SuiBox>
                }
                height="100%"
                chart={gradientLineChartData}
              />
            </Grid>
          </Grid>
        </SuiBox>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}
      </SuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
