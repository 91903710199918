import React from "react"
import Dashboard from "./layouts/dashboard";
import Produits from "./layouts/products";
import MapOfTunisia from "./layouts/MapOfTunisia";
import Customers from "./layouts/customers"
import Orders from "./layouts/orders"
import Delivery from "./layouts/delivery-form"
import Invoices from './layouts/invoices'
import Attributs from "./layouts/attributes";
import Profile from "./layouts/profile";
import Shop from "./examples/Icons/Shop";
import Office from "./examples/Icons/Office";
import CustomerSupport from "./examples/Icons/CustomerSupport";
import CreditCard from "./examples/Icons/CreditCard";
import DescriptionIcon from '@mui/icons-material/Description';
import Dealers from "./layouts/dealers";
import Assets from "./layouts/assets";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Produits",
    key: "produits",
    route: "/produits",
    icon: <Office size="12px" />,
    component: <Produits />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Attributs",
  //   key: "attributs",
  //   route: "/attributs",
  //   icon: <CreditCard size="12px" />,
  //   component: <Attributs />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: "/clients",
    icon: <CustomerSupport size="12px" />,
    component: <Customers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Commandes",
    key: "commandes",
    route: "/commandes",
    icon: <CreditCard size="12px" />,
    component: <Orders />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Map",
    key: "map",
    route: "/map",
    icon: <CreditCard size="12px" />,
    component: <MapOfTunisia />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Bon de livraison",
  //   key: "bon-de-livraison",
  //   route: "/bon-de-livraison",
  //   icon: <DescriptionIcon size="12px" />,
  //   component: <Delivery />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Factures",
  //   key: "factures",
  //   route: "/factures",
  //   icon: <CreditCard size="12px" />,
  //   component: <Invoices />,
  //   noCollapse: true,
  // },

  // {
  //   type: "collapse",
  //   name: "Virtual Reality",
  //   key: "virtual-reality",
  //   route: "/virtual-reality",
  //   icon: <Cube size="12px" />,
  //   component: <VirtualReality />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   route: "/rtl",
  //   icon: <Settings size="12px" />,
  //   component: <RTL />,
  //   noCollapse: true,
  // },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   icon: <CustomerSupport size="12px" />,
  //   component: <Profile />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Utilisateurs",
    key: "utilisateurs",
    route: "/utilisateurs",
    icon: <CustomerSupport size="12px" />,
    component: <Dealers />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   route: "/authentication/sign-in",
  //   icon: <Document size="12px" />,
  //   component: <SignIn />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <SpaceShip size="12px" />,
  //   component: <SignUp />,
  //   noCollapse: true,
  // },
  ,
  {
    type: "collapse",
    name: "Assets",
    key: "assets",
    route: "/assets",
    icon: <Office size="12px" />,
    component: <Assets />,
    noCollapse: true,
  },

];

export default routes;
