import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SuiTypography from '../../components/SuiTypography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { requestAddingAsset } from '../../redux/actions/assetsActionCreators';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import SuiBox from '../../components/SuiBox';

import SuiButton from '../../components/SuiButton';
import Icon from '@mui/material/Icon';

export default function AddAsset({  initialValues }) {
  const dispatch = useDispatch();
  const [open,setOpen]=useState(false)
  

  const handleClickOpen = () => {
    setOpen(true);
    
  };
  const handleClose=()=>{setOpen(false)}
  const initValSchema = {
    name: Yup.string().required('Ce champ est obligatoire!'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(initValSchema),
    onSubmit: (values, { resetForm }) => {
      dispatch(requestAddingAsset(values.name));

      resetForm({ values: '' });
      handleClose();
    },
  });

  return (
    <div>


<SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SuiTypography variant="h6">Dossier des assets </SuiTypography>
          <SuiButton variant="gradient" color="dark" onClick={()=>handleClickOpen()}>
            <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            &nbsp;Ajouter un dossier
          </SuiButton>
        </SuiBox>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{ sx: { width: '80%' } }}>
        <DialogTitle>Ajout d'un dossier</DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nom du dossier
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Nom du dossier"
                    name="name"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>handleClose()}>Annuler</Button>
            <Button type="submit">Ajouter</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
