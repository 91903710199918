import { LOGIN, LOGOUT, SET_USERS } from '../types/userTypes';

const initialState = {
  isAuth: false,
  info: null,
  token: null,
  list: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      const { token } = action.payload;
      const { user } = action.payload;
      const { role } = action.payload
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('role', role)
      return { ...state, isAuth: true, info: user, token };
    }
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('role');
      return { ...state, isAuth: false, info: null, token: null };
    case SET_USERS:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};

export default userReducer;
