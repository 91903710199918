import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import SuiButton from '../../components/SuiButton';
import Icon from '@mui/material/Icon';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Table from '../../examples/Tables/Table';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAttributes,
  requestRemovingAttribut,
} from '../../redux/actions/attributesActionCreators';
import { useConfirm } from 'material-ui-confirm';
import AddAttribute from './AddAttribute';

function Tables() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const attributes = useSelector((state) => state.attributes.list);

  const [open, setOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [data, setData] = useState([]);
  const [elements, setElements] = useState([]);
  const [elem, setElem] = useState([
    {
      id: '',
      term: '',
    },
  ]);

  const handleDelete = (id, name) => {
    confirm({
      description: `Voulez vous supprimer définitivement l'attribut ${name}?`,
    })
      .then(() => {
        dispatch(requestRemovingAttribut(id));
      })
      .catch(() => console.log('Supression Annulé'));
  };

  useEffect(() => {
    dispatch(fetchAttributes());
  }, []);

  useEffect(() => {
    const el = elements.map((term) => {
      return {
        term: term.name,
      };
    });
    setElem(el);
  }, [elements]);

  useEffect(() => {
    console.log('tableau final', elem);
  }, [elem]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const columns = [
    { name: "nom de l'attribut", align: 'center' },
    { name: "les elements de l'attributs", align: 'center' },
    { name: 'action', align: 'center' },
  ];

  console.log('attributes', attributes);

  const rows = attributes.map((el) => {
    return {
      id: el.id,
      "nom de l'attribut": el.name,
      "les elements de l'attributs":
        el.terms && el.terms.map((term) => term && term.name).join(', '),
      action: (
        <>
          {/* <Icon
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              handleClickOpen();
              setIsUpdating(true);
              setData(el);
              setElements(el.terms);
            }}
          >
            edit
          </Icon>
          &nbsp;&nbsp; */}
          <Icon
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => handleDelete(el.id, el.name)}
          >
            delete
          </Icon>
        </>
      ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Les Attributs des produits</SuiTypography>
              <SuiButton variant="gradient" color="dark" onClick={handleClickOpen}>
                <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                &nbsp;Ajouter un Attribut
              </SuiButton>
            </SuiBox>
            <SuiBox
              sx={{
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </SuiBox>
          </Card>
        </SuiBox>
        {/* <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">Projects table</SuiTypography>
          </SuiBox>
          <SuiBox
            sx={{
              '& .MuiTableRow-root:not(:last-child)': {
                '& td': {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SuiBox>
        </Card> */}
      </SuiBox>
      {isUpdating ? (
        <AddAttribute
          open={open}
          handleClose={handleClose}
          isUpdating={true}
          elements={elem}
          initialValues={{
            name: data.name,
            element: [elem],
          }}
          id={data.id}
        />
      ) : (
        <AddAttribute
          open={open}
          handleClose={handleClose}
          isUpdating={false}
          elements={elem}
          initialValues={{
            name: '',
            element: [],
          }}
        />
      )}
    </DashboardLayout>
  );
}

export default Tables;
