import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SuiBox from "../../../../components/SuiBox";
import SuiTypography from "../../../../components/SuiTypography";
import BarReportsChartItem from "./ReportsBarChartItem";
import configs from "./configs";
import './style.css';


function ReportsBarChart({ color, title, description, chart, items }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const renderItems = items.map((el) => (
    <Grid item xs={12} sm={6} key={el.product_id} >
      {console.log("quantity", el)}
      <BarReportsChartItem
        color={color}
        label={el.name}
        progress={el.quantity}
      />
    </Grid>
  ));

  return (
    <Card sx={{ height: "560px", overflowY: "scroll" }} className="scrollhost" >
      <SuiBox padding="2rem" >
        {useMemo(
          () => (
            <SuiBox
              style={{ position: "sticky", top: "32px" }}
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              py={2}
              pr={0.5}
              mb={3}
              height="12.5rem"
            >
              <Bar data={data} options={options} />
            </SuiBox>
          ),
          [chart, color]

        )}
        <SuiBox px={1} >
          <SuiBox mb={2}>
            <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Les produits commandés aujourd'hui
            </SuiTypography>
            {/* <SuiTypography component="div" variant="button" color="text" fontWeight="regular">
              {description}
            </SuiTypography> */}
          </SuiBox>
          <SuiBox py={1} px={0.5} >
            <Grid container spacing={2} >
              {renderItems}
            </Grid>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
  items: [],
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default ReportsBarChart;
