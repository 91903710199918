import * as React from 'react';
import { useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { useState } from 'react';
import AddAsset from './AddAsset';
import { useSelector, useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { fetchAssets, requestRemovingAsset } from '../../redux/actions/assetsActionCreators';
import EditAsset from './EditAsset';
import RenameAsset from './RenameAsset';
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';
import GlobalLoading from '../../components/GlobalLoading';



export default function Assets() {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const assets = useSelector((state) => state.assets.list);
  const meta = useSelector((state) => state.assets.meta)
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(1)
  const [data, setData] = useState({});
  const [rename, setRename] = useState(false);

  useEffect(() => {
    dispatch(fetchAssets(page));
  }, [dispatch, page]);

  useEffect(() => {
    console.log({ assets })
  }, [assets])

  const handleEdit = (id, name, content) => {
    setEdit(true);
    setData({ id: id, name: name, content: content });
  };



  const handleRename = (id, name) => {
    setRename(true);
    setData({ id: id, name: name });
  };

  const handleDelete = (id, name) => {
    confirm({
      description: `Voulez vous supprimer définitivement le dossier ${name} ?`,
    })
      .then(() => {
        dispatch(requestRemovingAsset(id));
      })
      .catch(() => console.log('Supression Annulé'));
  };

  const handleClose = () => {
    setEdit(false);
    setRename(false);
    dispatch(fetchAssets(page));
  };

  function nonselected(e) {
    e.target.style.border = '';
  }

  function selected(e) {
    e.target.style.border = '2px solid #ececec';
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!edit ? (
        <>
          <AddAsset
            initialValues={{
              name: '',
            }}
          />
          <Box sx={{ overflow: 'auto', height: "700px", mb: 2 }}>
            <GlobalLoading />
            {assets && assets.map((el) => {
              return (
                <Card
                  sx={{ maxWidth: 250, mb: 2, mr: 5, display: 'inline-block', mt: 5, boxShadow: 0.2 }}
                  key={el.id}
                >
                  <CardMedia
                    onMouseEnter={selected}
                    onMouseOut={nonselected}
                    component="img"
                    alt=""
                    height="145"
                    width="45"
                    image="/redfolder3.png"
                    onClick={() => {
                      handleEdit(el.id, el.attributes.name, el.attributes.content);
                    }}
                  />
                  <CardContent style={{ height: "85px", width: "450px" }}>
                    <Typography gutterBottom variant="h6" component="div" style={{ width: "50%", height: "50px" }}>
                      {el.attributes.name}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      Contient {el.attributes.content && (el.attributes.content.data === null ? 0 : el.attributes.content.data.length)} photos
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleRename(el.id, el.attributes.name)}>
                      renommer
                    </Button>
                    <Button size="small" onClick={() => handleDelete(el.id, el.attributes.name)}>
                      supprimer
                    </Button>
                  </CardActions>
                </Card>
              );
            })}

          </Box>
          <Pagination
            count={meta && meta.pagination && meta.pagination.pageCount}
            variant="outlined"
            onChange={(e, value) => setPage(value)}
          />
        </>
      ) : (
        <>
          <EditAsset
            handleClose={handleClose}
            content={data.content}
            id={data.id}
            name={data.name}

          />
        </>
      )}

      <RenameAsset
        open={rename}
        initialValues={{
          name: '',
        }}
        id={data.id}
        handleClose={handleClose}
        name={data.name}
      />
    </DashboardLayout>
  );
}
