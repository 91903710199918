import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoices } from '../../redux/actions/invoicesActionCreators';
import Invoices from './Invoices';

function InvoiceInformations() {
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.invoices.list);

  useEffect(() => {
    dispatch(fetchInvoices());
  }, [dispatch]);

  return (
    <Card id="delete-account">
      <SuiBox pt={3} px={2}>
        <SuiTypography variant="h6" fontWeight="medium">
          Factures
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={1} pb={2} px={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {invoices.map((invoice) => (
            <Invoices invoice={invoice} />
          ))}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default InvoiceInformations;
