import { alertError, alertSuccess } from "../../utils/feedback";
import { REQUEST_STARTED, REQUEST_SUCCEEDED, REQUEST_FAILED } from "../types/feedbackTypes";

export const requestStarted = (boul) => ({
    type: REQUEST_STARTED,
    payload: boul
})

export const requestSucceeded = (message) => {
    if (message) {
        alertSuccess(message);
    }
    return {
        type: REQUEST_SUCCEEDED
    }
}

export const requestFailed = (errorMessage) => {
    alertError(errorMessage)
    return {
        type: REQUEST_FAILED,
        payload: errorMessage
    }
}