import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SuiTypography from '../../components/SuiTypography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { requestUpdatingAsset } from '../../redux/actions/assetsActionCreators';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import { Input } from '@mui/material';

export default function RenameAsset({ id,name, handleClose,open,initialValues }) {
  const dispatch = useDispatch();

  const initValSchema = {
    name: Yup.string().required('Ce champ est obligatoire!'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(initValSchema),
    onSubmit: (values, { resetForm }) => {
    
      dispatch(requestUpdatingAsset(id ,values.name ));
      
      resetForm({ values: '' });
      handleClose();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{ sx: { width: '80%' } }}>
        <DialogTitle>Renommer dossier : "{name}" </DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nom du dossier
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Enter votre nouveau nom du dossier"
                    name="name"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                 
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit">Renommer</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
