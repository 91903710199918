import {
    PRODUCTS_REPORT,
    ORDERS_REPORT,
    CUSTOMERS_REPORT,
    SALES_REPORT,
    TOP_SELLERS,
} from '../types/reportsTypes';

const initialState = {
    productsReport: [],
    ordersReport: [],
    customersReport: [],
    salesReport: [],
    topSellers: [],
    actualSales: [],
    previsousSales: [],
    loading: false,
    selected: null,
};

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCTS_REPORT:
            return { ...state, productsReport: action.payload };
        case ORDERS_REPORT:
            return { ...state, ordersReport: action.payload };
        case CUSTOMERS_REPORT:
            return { ...state, customersReport: action.payload };
        case SALES_REPORT:
            return {
                ...state,
                salesReport: action.payload.salesReport,
                actualSales: action.payload.actualSales,
                previousSales: action.payload.previousSales,
            };
        case TOP_SELLERS:
            return { ...state, topSellers: action.payload };
        default:
            return state;
    }
};

export default reportsReducer;
