import Axios from '../../axios/authAxios';
import Api from '../../axios/api';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import {
  SET_DELIVERY_FORM,
  ADD_DELIVERY_FORM,
  REMOVE_DELIVERY_FORM,
} from './../types/deliveryFormsTypes';
import { fetchOrders } from './ordersActionCreators';

export const setDeliveryForms = (forms) => ({ type: SET_DELIVERY_FORM, payload: forms });
export const addDeliveryForms = (form) => ({ type: ADD_DELIVERY_FORM, payload: form });
export const removeDeliveryForm = (id) => ({ type: REMOVE_DELIVERY_FORM, payload: id });

export const fetchForms = () => async (dispatch) => {
  dispatch(requestStarted());
  const result = [];
  await Axios.get('/api/delivery-froms?pagination[page]=1&pagination[pageSize]=100')
    .then((res) => {
      const pageCount = res.data.meta.pagination.pageCount;
      for (let i = 0; i <= pageCount; i++) {
        Axios.get('/api/delivery-froms?pagination[page]=1&pagination[pageSize]=100')
          .then((response) => {
            result.push(...(response && response.data && response.data.data));
          })
          .then(() => {
            dispatch(setDeliveryForms(result));
            dispatch(requestSucceeded());
          })
          .catch((err) => {
            console.log({ err });
            dispatch(requestFailed(err.message));
          });
      }
    })

    .catch((err) => {
      console.log({ err });
      dispatch(requestFailed(err.message));
    });
};

export const requestAddingForm = (payload) => (dispatch) => {
  const {
    customer_name,
    date,
    horaire,
    customer_adress,
    customer_phoneNumber,
    customer_mail,
    total_taxes,
    total_price,
    discount,
    products,
    order_number,
    type,
    fees,
    loyalty_card,
    fromOrder,
    download,
    search,
    filter,
    page,
    after,
    before,
  } = payload;
  dispatch(requestStarted());
  Axios.post('/api/delivery-froms', {
    data: {
      order_id: order_number,
    },
  })
    .then((res) => {
      dispatch(requestSucceeded('Bon de livraison généré avec succée!'));
      dispatch(addDeliveryForms(res.data));
      Api.put(`orders/${order_number}`, {
        status: 'delevering',
      });
      const id = res.data.data.id;
      Axios.post(
        `/api/deliveryPdf`,
        {
          number: res.data.data.id,
          discount_total: discount,
          date: date,
          horaire: horaire,
          total: total_price,
          total_tax: total_taxes,
          customer_name: customer_name,
          customer_adress: customer_adress,
          email: customer_mail,
          phone: customer_phoneNumber,
          line_items: products,
          type: type,
          fees: fees,
          loyalty_card: loyalty_card,
        },
        {
          headers: {},

          responseType: 'blob',
        }
      )
        .then((response) => {
          const content = res.headers['content-type'];
          download(response.data, `Bon de livraison N° ${id}: ${customer_name}`, content);
        })
        .then(() => {
          Api.put(`orders/${order_number}`, {
            status: 'delevering',
          });
        })
        .then((res) => {
          dispatch(fetchOrders({ search, filter, page, after, before }));
          dispatch(fetchForms());
        });
    })
    .catch((err) => console.log(err));
  // });
};

export const requestRemovingForm = (id) => (dispatch) => {
  dispatch(requestStarted());
  Axios.delete(`/api/delivery-forms/${id}`)
    .then((res) => {
      dispatch(requestSucceeded('Bon de livraison supprimé avec succès!'));
      dispatch(removeDeliveryForm(id));
      dispatch(fetchForms());
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestFailed(error));
    });
};
