import { combineReducers } from 'redux';

import userReducer from './userReducer';
import feedbackReducer from './feedbackReducer';
import productsReducer from './productsReducer';
import customersReducer from './customersReducer';
import apiKeysReducer from './apiKeysReducer';
import ordersReducer from './ordersReducer';
import deliveryFormsReducer from './deliveryFormsReducer';
import invoicesReducer from './invoicesReducer';
import profilsReducer from './profilsReducer';
import attributesReducer from './attributesReducer';
import productsVariationsReducer from './productsVariationsReducer';
import reportsReducer from "./reportsReducer"
import assetsReducer from './assetsReducer';
import productsPhotoReducer from './productsPhotoReducer';
import dealersReducer from './dealersReducers';
import categoriesReducer from "./categoriesReducer"

const rootReducer = combineReducers({
  user: userReducer,
  apiKeys: apiKeysReducer,
  feedback: feedbackReducer,
  products: productsReducer,
  uploadedPhotos: productsPhotoReducer,
  customers: customersReducer,
  orders: ordersReducer,
  deliveryForms: deliveryFormsReducer,
  invoices: invoicesReducer,
  profils: profilsReducer,
  attributes: attributesReducer,
  assets: assetsReducer,
  productsVariations: productsVariationsReducer,
  reports: reportsReducer,
  dealers: dealersReducer,
  categories: categoriesReducer
});

export default rootReducer;
