import React, { useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import SidenavCollapse from './SidenavCollapse';
import SidenavRoot from './SidenavRoot';
import sidenavLogoLabel from './styles/sidenav';
import { useSoftUIController, setMiniSidenav } from '../../context';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfil } from '../../redux/actions/profilsActionCreators';
import Logo from '../../assets/Logo.png'

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const ddispatch = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const profile = useSelector((state) => state.profils.list);
  const role = localStorage.getItem('role')

  // useEffect(() => {
  //   ddispatch(fetchProfil());
  // }, [ddispatch]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  const sideData = role === "Manager" ? routes : role === "Editor" ? routes.filter((el) => el.key === "produits" || el.key == "assets") : routes.filter((el) => el.key === "map")




  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = sideData.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
    let returnValue;

    if (type === 'collapse') {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}
        >
          <SidenavCollapse
            color={color}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavCollapse
            color={color}
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </NavLink>
      );
    } else if (type === 'title') {
      returnValue = (
        <SuiTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SuiTypography>
      );
    } else if (type === 'divider') {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SuiBox pt={3} pb={1} px={4} textAlign="center">
        <SuiBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <SuiTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </SuiTypography>
        </SuiBox>

        <SuiBox component={NavLink} to="/" display="flex" alignItems="center" justifyContent="center">

          <SuiBox
            component="img"
            src={Logo}
            alt="Soft UI Logo"
            width="55%"
          />

          {/* <SuiBox
                    width={!brandName && "100%"}
                    sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                  >
                    <SuiTypography component="h6" variant="button" fontWeight="medium">
                      {brandName}
                    </SuiTypography>
                  </SuiBox> */}
        </SuiBox>

      </SuiBox>
      <Divider />
      <List>{renderRoutes}</List>
      {/* <SuiBox pt={2} my={2} mx={2} mt="auto">
        <SidenavCard />
        <SuiBox mt={2}>
          <SuiButton
            component="a"
            href="https://creative-tim.com/product/soft-ui-dashboard-pro-react"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            color={color}
            fullWidth
          >
            upgrade to pro
          </SuiButton>
        </SuiBox>
      </SuiBox> */}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
