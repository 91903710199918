import React, { useEffect, useState } from 'react';
import SuiButton from './SuiButton';
import SuiTypography from './SuiTypography';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import 'moment/locale/fr';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateFilter = ({ date, handleAll, handleToday, handleDate }) => {
    const [button, setButton] = useState('');
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        date[0].startDate === ''
            ? setButton('Tout les commandes')
            : moment(date[0].startDate).format('DD-MM-YYYY') ===
                moment(date[0].endDate).format('DD-MM-YYYY')
                ? setButton(`Commandes du ${moment(date[0].startDate).format('DD-MM-YYYY')}`)
                : setButton(
                    `Commandes du ${moment(date[0].startDate).format('DD-MM-YYYY')} au ${moment(
                        date[0].endDate
                    ).format('DD-MM-YYYY')}`
                );
    }, [date]);
    return (
        <div>
            <SuiButton
                variant="gradient"
                color="white"
                onClick={handleOpenUserMenu}
                fullWidth
                sx={{
                    ':hover': {
                        bgcolor: '#E7EBF0',
                    },
                    '&:focus:not(:hover)': {
                        backgroundColor: '#E7EBF0',
                    },
                }}
            >
                {button}
            </SuiButton>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem>
                    <DateRange
                        editableDateInputs={true}
                        onChange={(item) => handleDate(item.selection)}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                    />
                </MenuItem>
                <Grid container align="center">
                    <Grid item xs={6}>
                        <SuiTypography
                            variant="button"
                            fontWeight="medium"
                            mb={2}
                            ml={2}
                            sx={{ cursor: 'pointer' }}
                            onClick={handleAll}
                        >
                            Afficher Tout
                        </SuiTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <SuiTypography
                            variant="button"
                            fontWeight="medium"
                            mb={2}
                            ml={2}
                            sx={{ cursor: 'pointer' }}
                            onClick={handleToday}
                        >
                            Aujourd'hui
                        </SuiTypography>
                    </Grid>
                </Grid>
            </Menu>
        </div>
    );
};

export default DateFilter;
