import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SuiTypography from '../../../components/SuiTypography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import { requestChangePassword } from "../../../redux/actions/userActionCreators";
import { useMediaQuery } from '@mui/material';

export default function AlertDialog({ open, handleClose }) {

    const dispatch = useDispatch();
    const storedUser = localStorage.getItem("user");

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            oldPassword: Yup.string().max(255).required('Ce champ est obligatoire'),
            // .matches(
            //     /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=*?!-]).*$/,
            //     t("password_policy")
            // )
            newPassword: Yup.string().max(255).required('Ce Champ est obligatoire'),
            // .matches(
            //     /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=*?!-]).*$/,
            //     t("password_policy")
            // )
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Les mots de passe doivent correspondre!')
                .required('Ce champ est obligatoire'),
        }),
        onSubmit: (values, { resetForm }) => {
            dispatch(requestChangePassword(values.oldPassword, values.newPassword));
            resetForm({ values: '' });
            handleClose();
        },
    });
    const isMobile = useMediaQuery('(max-width:1400px)');
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                PaperProps={{
                    sx: !isMobile
                        ? { width: '35%', padding: '20px ' }
                        : { width: '90%', height: '80%', margin: '5px 0', padding: '5px ' },
                }}
            >
                <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    style={{ display: 'flex', alignItems: 'center', mb: 1 }}
                >
                    <IconButton onClick={handleClose}>
                        <KeyboardBackspaceIcon sx={{ width: '70%', color: 'black' }} />
                    </IconButton>
                    Retour
                </SuiTypography>
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Changer mot de passe</DialogTitle>
                    <DialogContent>
                        <Grid container mt={2} spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <SuiTypography component="label" variant="caption" fontWeight="bold" mb={2}>
                                    Ancien mot de passe
                                </SuiTypography>

                                <TextField
                                    id="change-old-password"
                                    placeholder="Saisissez votre ancien mot de passe..."
                                    name="oldPassword"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            width: '100% !important',
                                        },
                                    }} fullWidth
                                    error={Boolean(formik.touched.oldPassword && formik.errors.oldPassword)}
                                    helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.oldPassword}
                                    type={showOldPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowOldPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="small"
                                                >
                                                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <SuiTypography component="label" variant="caption" fontWeight="bold" mb={2}>
                                    Nouveau mot de passe
                                </SuiTypography>
                                <TextField
                                    id="change-new-password"
                                    placeholder="Saisissez votre nouveau mot de passe..."
                                    name="newPassword"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            width: '100% !important',
                                        },
                                    }}
                                    fullWidth
                                    error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
                                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.newPassword}
                                    type={showNewPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="small"
                                                >
                                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <SuiTypography component="label" variant="caption" fontWeight="bold" mb={2}>
                                    Confirmer nouveau mot de passe
                                </SuiTypography>
                                <TextField
                                    id="change-confirm-new-password"
                                    placeholder="Confirmez votre nouveau mot de passe..."
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            width: '100% !important',
                                        },
                                    }}
                                    name="confirmPassword"
                                    type="password"
                                    fullWidth
                                    error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.confirmPassword}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" fullWidth sx={{ margin: '15px 15px' }}>
                            Confirmer
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
