import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import SuiBox from '../../components/SuiBox';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import ProfileInfoCard from '../../examples/Cards/InfoCards/ProfileInfoCard';
import Header from '../../layouts/profile/components/Header';
import PlatformSettings from '../../layouts/profile/components/PlatformSettings';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfil } from '../../redux/actions/profilsActionCreators';
import { Typography } from '@mui/material';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import SuiTypography from '../../components/SuiTypography';
import SuiButton from '../../components/SuiButton';
import Icon from '@mui/material/Icon';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DealerModal from './DealerModal';
import { Table } from '@mui/material';
import { fetchDealers, requestRemovingDealer } from '../../redux/actions/dealersActionCreators';
import { useConfirm } from 'material-ui-confirm';
import Api2 from '../../axios/authAxios';



function Tables({ dealer, openModal, handleClickOpen, handleClose, handleUpdate, handleData }) {
  const confirm = useConfirm();
  const dispatch = useDispatch();



  const handleDelete = (id, name) => {
    confirm({ description: `Voulez vous supprimer définitivement l'utilisateur ${name}?` })
      .then(() => {
        dispatch(requestRemovingDealer(id));
      })
      .catch(() => console.log('Supression Annulé'));
  };

  return (
    <React.Fragment>
      {console.log(dealer)}

      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="center">
          {dealer.username.match(/[A-z]+/)}
        </TableCell>
        <TableCell align="center" color="dark">
          {dealer.username.match(/[A-z]+$/)}
        </TableCell>
        <TableCell align="center">
          {dealer.phoneNumber}
        </TableCell>
        <TableCell align="center">
          {dealer.email}
        </TableCell>
        <TableCell align="center">
          {dealer.role.name === "Manager" ? "Directeur" : dealer.role.name === "Editor" ? "Éditeur" : dealer.role.name === "Delivery" ? "Livreur" : ""}
        </TableCell>
        <TableCell align="center">
          {' '}
          <Icon
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              handleClickOpen(); // open modal
              handleUpdate();
              handleData(dealer);
            }}
          >
            edit
          </Icon>
          &nbsp;&nbsp;
          <Icon
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => handleDelete(dealer.id, dealer.name)}
          >
            delete
          </Icon>
        </TableCell>
      </TableRow>


    </React.Fragment>
  );


}




function Dealers() {

  const dealers = useSelector((state) => state.dealers.list);
  const [isUpdating, setIsUpdating] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [roles, setRoles] = useState([]);







  const handleData = (del) => {
    setData(del);
  };


  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {

    Api2.get(`api/users-permissions/roles/`)
      .then((res) => {
        console.log(res.data.roles);
        setRoles(res.data.roles)

      })
      .catch((err) => {
        console.log({ err });
      });

    console.log(dealers)

    dispatch(fetchDealers({}));
  }, [dispatch]);
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleUpdate = () => {
    setIsUpdating(true);
  };




  return (
    <DashboardLayout>
      <DashboardNavbar />


      <SuiBox mb={3} px={3} display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SuiTypography variant="h6">Utilisateurs</SuiTypography>
        <SuiButton
          variant="gradient"
          color="dark"
          onClick={() => {
            handleClickOpen();
            setIsUpdating(false);
          }}
        >
          <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
          &nbsp;Ajouter utilisateur
        </SuiButton>
      </SuiBox>
      <Card>
        <SuiBox >
          <TableContainer style={{ height: '700px', overflow: 'auto' }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Prénom</TableCell>
                  <TableCell align="center">Nom</TableCell>
                  <TableCell align="center">Téléphone</TableCell>
                  <TableCell align="center">Adresse mail</TableCell>
                  <TableCell align="center">Role </TableCell>
                  <TableCell align="center"></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {dealers && dealers.map((deal) => (
                  <Tables
                    key={deal.id}
                    dealer={deal}
                    openModal={openModal}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    handleUpdate={handleUpdate}
                    handleData={handleData}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </SuiBox>
      </Card>


      {isUpdating ? (<>
        <DealerModal
          open={openModal}
          handleClose={handleClose}
          isUpdating={true}
          userRole={data.role.id}
          getroles={roles}


          initialValues={{
            name: data.username.match(/[A-z]+/),
            surname: data.username.match(/[A-z]+$/),
            phone_number: data.phoneNumber,
            password: data.password
          }}
          id={data.id}
          dealers={dealers}

        />
        {console.log(data.role)}</>

      ) : (
        <DealerModal

          open={openModal}
          handleClose={handleClose}
          isUpdating={false}
          dealers={dealers}
          userRole={1}
          getroles={roles}

          initialValues={{
            name: "",
            surname: '',
            phone_number: '',
            password: '',

          }}
        />
      )}


    </DashboardLayout>
  );
}

export default Dealers;
