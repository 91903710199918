import {
  SET_PRODUCTS,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  UPDATE_PRODUCT,
  SET_PRODUCT_BY_ID,
  SET_BATCH_PRODUCTS,
} from '../types/productsTypes.js';

const initialState = {
  list: [],
  loading: false,
  selected: null,
  productById: {},
  totalPages: null,
  totalProducts: null,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        list: action.payload.products,
        totalPages: action.payload.totalPages,
        totalProducts: action.payload.totalProducts,
      };
    case SET_PRODUCT_BY_ID:
      return { ...state, productById: action.payload };
    case ADD_PRODUCT:
      return { ...state, list: [...state.list, action.payload] };
    case SET_BATCH_PRODUCTS:
      return { ...state, list: state.list.concat(action.payload) };
    case REMOVE_PRODUCT:
      return { ...state, list: [...state.list] };
    case UPDATE_PRODUCT:
      return state.list.map((prod) =>
        prod.id === action.payload.id ? { ...state, prod: action.payload } : prod
      );

    default:
      return state;
  }
};

export default productsReducer;
