import {
  SET_DELIVERY_FORM,
  ADD_DELIVERY_FORM,
  REMOVE_DELIVERY_FORM,
} from '../types/deliveryFormsTypes';

const initialState = {
  list: [],
  loading: false,
  selected: null,
};

const deliveryFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DELIVERY_FORM:
      return { ...state, list: action.payload };
    case ADD_DELIVERY_FORM:
      return { ...state, list: [...state.list, action.payload] };
    case REMOVE_DELIVERY_FORM:
      return { ...state, list: [...state.list] };
    default:
      return state;
  }
};

export default deliveryFormsReducer;
