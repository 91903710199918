import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SuiTypography from '../../components/SuiTypography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { requestAddingDealer, requestUpdatingDealer } from '../../redux/actions/dealersActionCreators';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import Api2 from '../../axios/authAxios';
import SelectR from '../../components/Select';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';
import SuiInput from '../../components/SuiInput';

export default function DealerModal({
  open,
  handleClose,
  isUpdating,
  initialValues,
  id,
  userRole,
  dealers,
  getroles
}) {
  const dispatch = useDispatch();

  const [role, setRole] = useState("");
  //const [roles, setRoles] = useState([]);

  const [existNames, setexistNames] = useState([]);
  const [existSurnames, setexistSurnames] = useState([]);
  const [showPassword, setshowPassword] = useState(false);


  console.log(role);
  console.log(userRole);


  const handleRole = (ro) => {
    setRole(ro);
  };
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  console.log({ id });
  console.log(dealers);


  // useEffect(() => {

  //   getroles()
  // }, []);

  useEffect(() => {
    setexistNames(dealers.map((el) => el.username));
    setexistSurnames(dealers.map((el) => el.username));
    // getroles()
  }, [dealers]);
  console.log(existNames);

  // function getroles() {
  //   Api2.get(`api/users-permissions/roles/`)
  //     .then((res) => {
  //       console.log(res.data.roles);
  //       setRoles(res.data.roles)

  //     })
  //     .catch((err) => {
  //       console.log({ err });
  //     });
  // }
  const initValSchema = {
    name: Yup.mixed().required('Ce champ est obligatoire!'),
    surname: Yup.string()
      .required('Ce champ est obligatoire!')
      .when('name', {
        is: (name) => existNames.includes(name),
        then: Yup.string().notOneOf(existSurnames, 'Ce Utilisateur est déjà entré '),
      }),
    phone_number: Yup.string()
      .matches(/^-?[0-9,.]+$/, 'Doit contenir uniquement des chiffres')
      .required('Ce champ est obligatoire!'),
    password: Yup.string().required('Ce champ est obligatoire!'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: !isUpdating && Yup.object(initValSchema),
    onSubmit: (values, { resetForm }) => {
      console.log('submitted2');
      console.log(isUpdating);

      if (isUpdating) {
        dispatch(
          requestUpdatingDealer(
            id,
            values.name,
            values.surname,
            values.phone_number,
            values.password,
            role
          )
        );
        //setrole();
      } else {
        dispatch(
          requestAddingDealer(
            values.name,
            values.surname,
            values.phone_number,
            values.password,
            role
          )
        );
      }
      resetForm({ values: '' });
      handleClose();
    },
  });

  return (
    <div>
      {console.log(initialValues)}
      <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{ sx: { width: '80%', } }}>
        {console.log('From', formik.values)}
        {isUpdating ? (
          <DialogTitle>Modification d'un Utilisateur</DialogTitle>
        ) : (
          <DialogTitle>Ajout d'un Utilisateur </DialogTitle>
        )}
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2} sx={{ mb: "" }}>
              <Grid item xs={4} sx={{ mb: "14px" }}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Prénom
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Prénom ..."
                    name="name"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    disabled={isUpdating}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nom
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Nom"
                    name="surname"
                    error={Boolean(formik.touched.surname && formik.errors.surname)}
                    helperText={formik.touched.surname && formik.errors.surname}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.surname}
                    disabled={isUpdating}

                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Téléphone
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Téléphone.."
                    name="phone_number"
                    error={Boolean(formik.touched.phone_number && formik.errors.phone_number)}
                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.phone_number}
                  />
                </Stack>
              </Grid>
              {!isUpdating && (<Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Password
                  </SuiTypography>
                  <SuiInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Mot de passe..."
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    icon={{
                      component: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="top"
                          size="small"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                      direction: 'right',
                    }}
                  />
                </Stack>
              </Grid>)}
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Role
                  </SuiTypography>
                  <SelectR
                    handleType={handleRole}
                    typeProd={userRole}
                    values={getroles && getroles.map((role) => (

                      { label: role.name === "Manager" ? "Directeur" : role.name === "Editor" ? "Éditeur" : role.name === "Delivery" ? "Livreur" : "", value: role.id }
                    ))

                    }
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            {isUpdating ? (
              <Button type="submit">Modifier</Button>
            ) : (
              <Button type="submit" onClick={() => console.log('submitted')}>
                Ajouter
              </Button>
            )}
          </DialogActions>

        </form>
      </Dialog>
    </div>
  );
}
