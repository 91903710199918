import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import SuiTypography from '../../components/SuiTypography';

function AddRemoveAttributes({ handleChangeInput, product, data, update }) {
  const ref0 = useRef();
  const ref1 = useRef();
  console.log('product', product.attributes);
  const [id, setId] = useState('');
  const [terms, setTerms] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      id: '',
      option: '',
    },
  ]);

  const attributes = product.attributes;

  useEffect(() => {
    if (id) {
      const att = attributes.filter((el) => el.id == id);
      const ter = att.map((el) => el.options);
      setTerms(ter[0]);
    }
  }, [id, attributes]);

  useEffect(() => {
    console.log('ahom i terms', terms);
  }, [terms]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        id: '',
        option: '',
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChange = (index, value, name) => {
    console.log(value, name);
    // const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  useEffect(() => {
    handleChangeInput(inputFields);
  }, [inputFields]);
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          {inputFields.map((data, index) => {
            return (
              <Grid container spacing={2} display="flex" alignItems="center" mb={2}>
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Nom de l'attribut
                    </SuiTypography>
                    <Autocomplete
                      id="combo-box-demo"
                      ref={ref0}
                      options={attributes}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(event, value) => {
                        handleChange(index, value && value.id, ref0.current.getAttribute('name'));
                        setId(value.id);
                      }}
                      name="id"
                      renderInput={(params) => <TextField {...params} placeholder="Attributs" />}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Option
                    </SuiTypography>
                    <Autocomplete
                      id="combo-box-demo"
                      ref={ref1}
                      options={terms && terms}
                      getOptionLabel={(option) => option || ''}
                      onChange={(event, value) =>
                        handleChange(index, value, ref1.current.getAttribute('name'))
                      }
                      name="option"
                      renderInput={(params) => <TextField {...params} placeholder="Options" />}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Stack spacing={2}>
                    {inputFields.length !== 1 ? (
                      <Button
                        style={{ color: 'red', width: '200px', marginTop: '30px' }}
                        onClick={removeInputFields}
                      >
                        Supprimer cet attribut
                      </Button>
                    ) : (
                      ''
                    )}
                  </Stack>
                </Grid>
              </Grid>
            );
          })}

          <div className="row">
            <Button className="btn btn-outline-success " onClick={addInputField}>
              Ajouter un autre attribut
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddRemoveAttributes;
