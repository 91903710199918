import Api from '../../axios/api';
import axios from 'axios';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import {
  SET_PRODUCTS,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  UPDATE_PRODUCT,
  SET_PRODUCT_BY_ID,
  SET_BATCH_PRODUCTS,
} from '../types/productsTypes.js';
import Axios from '../../axios/authAxios';

export const setProducts = (products, totalPages, totalProducts) => ({
  type: SET_PRODUCTS,
  payload: { products: products, totalPages: totalPages, totalProducts: totalProducts },
});
export const addProduct = (product) => ({ type: ADD_PRODUCT, payload: product });
export const removeProduct = (id) => ({ type: REMOVE_PRODUCT, payload: id });
export const updateProduct = (id, product) => ({ type: UPDATE_PRODUCT, payload: id, product });
export const setProductById = (product) => ({ type: SET_PRODUCT_BY_ID, payload: product });
export const addBatchProduct = (products) => ({ type: SET_BATCH_PRODUCTS, payload: products });

export const fetchProducts =
  ({ search, filter, page }) =>
  (dispatch) => {
    dispatch(requestStarted());
    Api.get(`products?search=${search}&category=${filter}&page=${page}&per_page=50`)
      .then((res) => {
        dispatch(setProducts(res.data, res.headers['x-wp-totalpages'], res.headers['x-wp-total']));
        dispatch(requestSucceeded());
      })
      .catch((err) => {
        console.log({ err });
        dispatch(requestFailed(err.message));
      });
  };

export const fetchProductsSku = (sku) => (dispatch) => {
  dispatch(requestStarted());
  let value = Number(sku);
  Api.get(`products?sku=${Number(value)}`)
    .then((res) => {
      dispatch(requestSucceeded());
      dispatch(setProducts(res.data, res.headers['x-wp-totalpages'], res.headers['x-wp-total']));
    })
    .catch((err) => {
      console.log({ err });
      dispatch(requestFailed(err.message));
    });
};

export const fetchProductById = (id) => (dispatch) => {
  dispatch(requestStarted());
  Api.get(`products/${id}`)
    .then((res) => {
      dispatch(requestSucceeded());
      console.log(res.data);
      dispatch(setProductById(res.data));
    })
    .catch((err) => {
      console.log({ err });
      dispatch(requestFailed(err.message));
    });
};

export const requestAddingProduct =
  (
    {
      sku,
      name,
      meta_data,
      description,
      regular_price,
      type,
      manage_stock,
      stock_status,
      stock_quantity,
      categories,
      images,
    },
    files,
    search,
    filter,
    page
  ) =>
  (dispatch) => {
    dispatch(requestStarted());
    if (files.length !== 0) {
      Axios.post('/api/upload', files)
        .then((res) => {
          const img = res.data.map((el) => ({ src: `${process.env.REACT_APP_API_URL}${el.url}` }));

          Api.post('products', {
            sku: sku,
            name: name,
            meta_data: meta_data,
            description: description,
            regular_price: regular_price,
            type: type,
            manage_stock: manage_stock,
            stock_status: stock_status,
            stock_quantity: stock_quantity,
            categories: categories,
            images: images.concat(img),
          })
            .then((res) => {
              dispatch(requestSucceeded('Article ajouté avec succès!'));
              dispatch(addProduct(res.data));
              dispatch(fetchProducts({ search, filter, page }));
            })
            .catch((error) => {
              console.log(error);
              dispatch(requestFailed);
            });
        })
        .catch((error) => {
          console.log(error);
          dispatch(requestFailed);
        });
    } else {
      Api.post('products', {
        sku: sku,
        name: name,
        meta_data: meta_data,
        description: description,
        regular_price: regular_price,
        type: type,
        manage_stock: manage_stock,
        stock_status: stock_status,
        stock_quantity: stock_quantity,
        categories: categories,
        images: images,
      })
        .then((res) => {
          dispatch(requestSucceeded('Article ajouté avec succès!'));
          dispatch(addProduct(res.data));
          dispatch(fetchProducts({ search, filter, page }));
        })
        .catch((error) => {
          console.log(error);
          dispatch(requestFailed);
        });
    }
  };

export const requestRemovingProduct = (id, search, filter, page) => (dispatch) => {
  dispatch(requestStarted());
  Api.delete(`products/${id}`)
    .then((res) => {
      dispatch(requestSucceeded('Article supprimé avec succès!'));
      dispatch(removeProduct(id));
      dispatch(fetchProducts({ search, filter, page }));
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestFailed);
    });
};

export const requestUpdatingProduct =
  (
    id,
    name,
    description,
    regular_price,
    stock,
    categories,
    unit,
    promotion,
    checked,
    files,
    search,
    filter,
    page
  ) =>
  (dispatch) => {
    dispatch(requestStarted());
    if (files.length !== 0) {
      Axios.post('/api/upload', files)
        .then((res) => {
          const img = res.data.map((el) => ({ src: `${process.env.REACT_APP_API_URL}${el.url}` }));

          Api.put(`products/${id}`, {
            name: name,
            description: description,
            regular_price: regular_price.toString(),
            categories: categories,
            stock_quantity: stock,
            meta_data:
              Number(promotion) !== 0 || promotion == !''
                ? [
                    {
                      key: '_woo_uom_input',
                      value: unit,
                    },
                    {
                      key: '_sale_price',
                      value: promotion.toString(),
                    },
                  ]
                : [
                    {
                      key: '_woo_uom_input',
                      value: unit,
                    },
                    {
                      key: '_sale_price',
                      value: '',
                    },
                  ],
            images: checked.concat(img),
          })
            .then((res) => {
              dispatch(requestSucceeded('Article mis à jour avec succès!'));
              // dispatch(updateProduct(id, res.data));
              dispatch(fetchProducts({ search, filter, page }));
            })
            .catch((error) => {
              console.log(error);
              dispatch(requestFailed(error.message));
            });
        })
        .catch((error) => {
          console.log(error);
          dispatch(requestFailed(error.message));
        });
    } else {
      Api.put(`products/${id}`, {
        name: name,
        description: description,
        regular_price: regular_price.toString(),
        categories: categories,
        stock_quantity: stock,
        meta_data:
          Number(promotion) !== 0 || promotion == !''
            ? [
                {
                  key: '_woo_uom_input',
                  value: unit,
                },
                {
                  key: '_sale_price',
                  value: promotion.toString(),
                },
              ]
            : [
                {
                  key: '_woo_uom_input',
                  value: unit,
                },
                {
                  key: '_sale_price',
                  value: '',
                },
              ],
        images: checked,
      })
        .then((res) => {
          dispatch(requestSucceeded('Article mis à jour avec succès!'));
          // dispatch(updateProduct(id, res.data));
          dispatch(fetchProducts({ search, filter, page }));
        })
        .catch((error) => {
          console.log(error);
          dispatch(requestFailed(error.message));
        });
    }
  };

export const requestBatchingProducts = (search, filter, page) => async (dispatch) => {
  dispatch(requestStarted(true));
  let allProducts = [];
  const chunkSize = 99;
  const chunkUpdate = 99;

  let filtred = [];
  let forUpdate = [];
  let response = await axios.get('https://server.izy.tn/erp/products');
  let res = await Api.get(`products?per_page=100`);
  let data = response.data;
  const totalPages = res.headers['x-wp-totalpages'];
  for (let i = 1; i <= totalPages; i++) {
    let result = await Api.get(`products?per_page=100&page=${i}`);
    allProducts.push(...result.data);
  }

  for (let i = 0; i < data.length; i++) {
    if (!allProducts.some((el) => el.sku === data[i].ref.toString())) {
      filtred.push(data[i]);
    } else {
      for (let j = 0; j < allProducts.length; j++) {
        if (allProducts[j].sku === data[i].ref.toString()) {
          if (
            Number(allProducts[j].regular_price) !== Number(data[i].detail) ||
            Number(allProducts[j].sale_price) !== Number(data[i].promotion) ||
            Number(allProducts[j].stock_quantity) !== Number(data[i].stock)
          ) {
            forUpdate.push({ ...data[i], id: allProducts[j].id });
          }
        }
      }
    }
  }

  const finalResult = filtred.map((el) => ({
    sku: el.ref.toString(),
    name: el.des,
    meta_data:
      el && el.promotion !== 0
        ? [
            {
              key: '_woo_uom_input',
              value: el.unit === 'KG' ? 'Gramme' : el.unit,
            },
            {
              key: '_sale_price',
              value: el && el.promotion && el.promotion.toString(),
            },
          ]
        : [
            {
              key: '_woo_uom_input',
              value: el.unit === 'KG' ? 'Gramme' : el.unit,
            },
          ],
    regular_price: el && el.detail && el.detail.toString(),
    type: 'simple',
    manage_stock: true,
    stock_status: 'instock',
    stock_quantity:
      !!el && !!el.stock && !!el.stock.toString().match(/\./)
        ? Math.floor(el.stock * 1000)
        : el.stock,
    categories: [
      {
        id: Number(el.lane),
      },
      {
        id: Number(el.sublane),
      },
    ],
    // images: [
    //   {
    //     src: 'https://southwesttrainingsolutions.co.uk/wp-content/uploads/2020/07/no-image.png',
    //   },
    // ],
  }));

  const updateResult = forUpdate.map((el) => ({
    id: el.id,
    meta_data:
      el.promotion !== 0
        ? [
            {
              key: '_woo_uom_input',
              value: el.unit === 'KG' ? 'Gramme' : el.unit,
            },
            {
              key: '_sale_price',
              value: el && el.promotion && el.promotion.toString(),
            },
          ]
        : [
            {
              key: '_woo_uom_input',
              value: el.unit === 'KG' ? 'Gramme' : el.unit,
            },
            {
              key: '_sale_price',
              value: '',
            },
          ],
    regular_price: el && el.detail && el.detail.toString(),
    stock_quantity:
      !!el && !!el.stock && !!el.stock.toString().match(/\./)
        ? Math.floor(el.stock * 1000)
        : el.stock,
  }));

  console.log({ finalResult });
  console.log({ updateResult });

  for (let i = 0; i < finalResult.length; i++) {
    const chunk = finalResult[i];
    await Api.post('products', chunk).catch((error) => {
      console.log(error);
      // dispatch(requestFailed('Erreur Serveur'));
    });
  }

  for (let i = 0; i < updateResult.length; i++) {
    const chunk = updateResult[i];
    await Api.put(`products/${chunk.id}`, {
      meta_data: chunk.meta_data,
      regular_price: chunk.regular_price,
      stock_quantity: chunk.stock_quantity,
    }).catch((error) => {
      console.log(error);
      // dispatch(requestFailed('Erreur Serveur'));
    });
  }

  if (updateResult.length !== 0) {
    dispatch(requestSucceeded('Tous les articles sont mis à jour avec succès!'));
  } else {
    if (finalResult.length === 0) {
      dispatch(requestSucceeded('Tous les articles existent et à jour!'));
    } else {
      dispatch(requestSucceeded('Tous les articles sont ajoutés avec succès!'));
    }
  }

  dispatch(fetchProducts({ search, filter, page }));
};
