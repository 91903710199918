import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import SuiButton from '../../components/SuiButton';
import Icon from '@mui/material/Icon';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Table from '@mui/material/Table';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchProducts,
  requestRemovingProduct,
  fetchProductsSku,
} from '../../redux/actions/productsActionCreators';
import { requestRemovingVariation } from '../../redux/actions/productsVariationsActionCreators';
import AddModal from './AddModal';
import { useConfirm } from 'material-ui-confirm';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Collapse, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import AddVariation from './AddVariation';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import GlobalLoading from '../../components/GlobalLoading';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchAllCategories } from '../../redux/actions/categoriesActionsCreators';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import ProductDetails from './ProductDetails';
import AddFile from './AddFile';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function Tables({
  product,
  handleClickOpen,
  handleClose,
  handleUpdate,
  handleData,
  search,
  page,
  filter,
}) {
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const productsVariations = useSelector((state) => state.productsVariations.list);

  const [open, setOpen] = useState(false);
  const [openVar, setOpenVar] = useState(false);
  const [show, setShow] = useState(true);
  // const [openModal, setOpenModal] = useState(false);
  const [prodId, setProdId] = useState('');
  const [varData, setVarData] = useState({});
  const [updateVar, setUpdateVar] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  const handleDelete = (id, name) => {
    confirm({
      title: 'Etes vous sure?',
      description: `Voulez vous supprimer définitivement l'article ${name}?`,
      confirmationText: 'Supprimer',
      cancellationText: 'Annuler',
    })
      .then(() => {
        dispatch(requestRemovingProduct(id, search, filter, page));
      })
      .catch(() => console.log('Supression Annulé'));
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const handleDeleteVariation = (prodId, varId, name) => {
    confirm({
      description: `Voulez vous supprimer définitivement cette variation de l'article ${name}?`,
    })
      .then(() => {
        dispatch(requestRemovingVariation(prodId, varId));
      })
      .catch(() => console.log('Supression Annulé'));
  };

  const handleClickOpenVar = (id) => {
    setOpenVar(true);
    setProdId(id);
  };

  const handleClickCloseVar = () => {
    setOpenVar(false);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        onClick={() => setOpenDetails(true)}
        hover
      >
        <TableCell align="left" width="150px">
          <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              product &&
              product.images &&
              product.images.length > 1 && (
                <p
                  style={{
                    borderRadius: '50%',
                    border: '1px solid ',
                    borderColor: 'white',
                    backgroundColor: '#CED4DA',
                    color: 'white',
                    padding: '5px',
                    fontSize: '10px',
                    width: '120%',
                    height: '120%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  +{product && product.images && product.images.length - 1}
                </p>
              )
            }
          >
            <Avatar
              sx={{ height: '60px', width: '60px' }}
              alt="Remy Sharp"
              src={product && product.images[0] && product.images[0].src}
              variant="square"
            />
          </Badge>
        </TableCell>

        <TableCell align="left" color="dark" width="200px">
          {product.name}
        </TableCell>
        <TableCell align="center">
          {product &&
            product.description &&
            product.description.replaceAll('<p>', ' ').replaceAll('</p>', ' ').substring(0, 30)}
          {product && product.description && product.description.length > 30 && '...'}
        </TableCell>
        <TableCell align="center" width="200px">
          {product.stock_quantity > '10' ? (
            <p style={{ color: 'green' }}>
              {product.stock_quantity}{' '}
              {product.meta_data.filter((el) => el.key === '_woo_uom_input')[0].value}
            </p>
          ) : product.stock_quantity > '0' ? (
            <p style={{ color: 'orange' }}>
              {product.stock_quantity}{' '}
              {product.meta_data.filter((el) => el.key === '_woo_uom_input')[0].value}
            </p>
          ) : (
            <p style={{ color: 'red' }}>
              0 {product.meta_data.filter((el) => el.key === '_woo_uom_input')[0].value}
            </p>
          )}
        </TableCell>
        <TableCell align="center" width="200px">
          {product.price}
        </TableCell>
        <TableCell align="center" width="200px">
          {' '}
          <Icon
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={(e) => {
              handleClickOpen();
              handleUpdate();
              handleData(product);
              e.stopPropagation();
            }}
          >
            edit
          </Icon>
          &nbsp;&nbsp;
          <Icon
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={(e) => {
              handleDelete(product.id, product.name);
              e.stopPropagation();
            }}
          >
            delete
          </Icon>
        </TableCell>
      </TableRow>

      <TableRow style={{ backgroundColor: '#D3DAFE' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom component="div">
                  Les variations du produit
                </Typography>
                <SuiButton
                  variant="outlined"
                  color="dark"
                  onClick={() => {
                    handleClickOpenVar(product.id);
                    setUpdateVar(false);
                  }}
                >
                  <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                </SuiButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {productsVariations[0] &&
                      productsVariations[0].attributes.map((attr) => (
                        <TableCell align="center">{attr.name}</TableCell>
                      ))}

                    <TableCell align="center">Stock disponible</TableCell>
                    <TableCell align="center">Prix</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                {productsVariations.map((prodVar) => (
                  <TableBody>
                    <TableRow>
                      {prodVar.attributes.map((attr) => (
                        <TableCell align="center">{attr.option}</TableCell>
                      ))}
                      <TableCell align="center">
                        {' '}
                        {prodVar.stock_quantity > '10' ? (
                          <p style={{ color: 'green' }}>{prodVar.stock_quantity}</p>
                        ) : prodVar.stock_quantity > '0' ? (
                          <p style={{ color: 'orange' }}>{prodVar.stock_quantity}</p>
                        ) : (
                          <p style={{ color: 'red' }}>0</p>
                        )}
                      </TableCell>
                      <TableCell align="center">{prodVar.price}</TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Icon
                          sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                          onClick={(e) => {
                            handleClickOpenVar(product.id);
                            setVarData(prodVar);
                            setUpdateVar(true);
                            e.stopPropagation();
                          }}
                        >
                          edit
                        </Icon>
                        &nbsp;&nbsp;
                        <Icon
                          sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                          onClick={(e) => {
                            handleDeleteVariation(product.id, prodVar.id, product.name);
                            e.stopPropagation();
                          }}
                        >
                          delete
                        </Icon>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
              {updateVar ? (
                <AddVariation
                  open={openVar}
                  handleClose={handleClickCloseVar}
                  initialValues={{
                    regular_price: varData.price,
                    stock_quantity: varData.stock_quantity,
                    attributes: varData.attributes,
                  }}
                  id={prodId}
                  update={true}
                  data={varData}
                />
              ) : (
                <AddVariation
                  open={openVar}
                  handleClose={handleClickCloseVar}
                  initialValues={{
                    regular_price: '',
                    stock_quantity: '',
                    attributes: [],
                  }}
                  id={prodId}
                  update={false}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ProductDetails open={openDetails} handleClose={handleCloseDetails} product={product} />
    </React.Fragment>
  );
}

export default function CollapsibleTable() {
  const products = useSelector((state) => state.products.list);
  const totalPages = useSelector((state) => state.products.totalPages);
  const totalProducts = useSelector((state) => state.products.totalProducts);

  const categories = useSelector((state) => state.categories.list);

  const [openModal, setOpenModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');
  const [sku, setSku] = useState('');
  const [page, setPage] = useState(1);
  const [file, setFile] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleFile = (data) => {
    setFile(data);
  };

  const handleName = (fileName) => {
    setName(fileName);
  };

  const handleError = (item) => {
    setError(item);
  };

  const handleClose = () => {
    setOpenModal(false);
    dispatch(fetchProducts({ search, filter, page }));
  };

  const handleUpdate = () => {
    setIsUpdating(true);
  };

  const handleData = (prod) => {
    setData(prod);
  };

  useEffect(() => {
    if (sku !== '') {
      dispatch(fetchProductsSku(sku));
    } else {
      dispatch(fetchProducts({ search, filter, page }));
    }
  }, [dispatch, search, filter, page, sku]);

  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SuiBox>
        <SuiBox
          px={4}
          mb={3}
          mt={3}
          display="flex"
          justifyContent="space-between"
          alignItems="right"
        >
          <Grid container align="right">
            <Grid item xs={8} display="flex" justifyContent="space-between" alignItems="center">
              <SuiTypography variant="h6">Les Produits</SuiTypography>
              {name ? (
                <div>
                  <small style={{ fontSize: '14px' }}>{name} </small>
                  <Icon
                    sx={{
                      fontWeight: 'bold',
                      color: 'red',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setFile('');
                      setName('');
                    }}
                  >
                    cancel
                  </Icon>
                </div>
              ) : (
                error && <small style={{ color: 'red', fontSize: '12px' }}>{error}</small>
              )}
            </Grid>
            <Grid item xs={2} align="right">
              <AddFile
                file={file}
                handleFile={handleFile}
                name={name}
                handleName={handleName}
                error={error}
                handleError={handleError}
                search={search}
                filter={filter}
                page={page}
              />
            </Grid>
            <Grid item xs={2} align="right">
              <SuiButton
                variant="gradient"
                color="dark"
                onClick={() => {
                  handleClickOpen();
                  setIsUpdating(false);
                }}
              >
                <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                &nbsp;Ajouter un produit
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>

        <Grid container mb={3} spacing={3} alignItems="center">
          <Grid item xs={3}>
            <TextField
              type="text"
              placeholder="Code à barre"
              onChange={(e) => {
                setSku(e.target.value);
              }}
              name="sku"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              placeholder="Recherche"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              name="quantity"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={categories}
              getOptionLabel={(option) => (option ? option.name : '')}
              onChange={(e, value) => setFilter(value ? value.id : '')}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} placeholder="Liste des catégories" />}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <Card sx={{ marginTop: '40px' }}>
        <GlobalLoading />
        <SuiBox>
          <TableContainer style={{ height: '560px', overflow: 'auto' }}>
            <Table aria-label="collapsible table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{'      '}</TableCell>
                  <TableCell align="left">Article</TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell align="center">Quantité</TableCell>
                  <TableCell align="center">Prix</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products &&
                  products.map((prod) => (
                    <Tables
                      key={prod.id}
                      product={prod}
                      openModal={openModal}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      handleUpdate={handleUpdate}
                      handleData={handleData}
                      search={search}
                      page={page}
                      filter={filter}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container mt={2} mb={2} pl={3}>
            <Grid item xs={10}>
              <Pagination
                count={totalPages}
                variant="outlined"
                onChange={(e, value) => setPage(value)}
              />
            </Grid>
            <Grid item xs={2}>
              <SuiTypography
                variant="button"
                fontWeight="medium"
                textTransform="capitalize"
              >{`Nombre de résultats: ${totalProducts}`}</SuiTypography>
            </Grid>
          </Grid>
        </SuiBox>
      </Card>

      {isUpdating ? (
        <AddModal
          open={openModal}
          handleClose={handleClose}
          isUpdating={true}
          initialValues={{
            sku: data && data.sku,
            name: data && data.name,
            description:
              data &&
              data.description &&
              data.description.slice(3, data && data.description && data.description.length - 5),
            regular_price: Number(data && data.regular_price),
            stock: data && data.stock_quantity,
            categories: data && data.categories,
            promotion: data && data.sale_price,
            unit: data && data.meta_data.filter((el) => el.key === '_woo_uom_input')[0].value,
          }}
          id={data && data.id}
          typeProd={data.type}
          data={data.images}
          dataSku={data && data.sku}
          // relatedAttributes={data.attributes}
          products={products}
          categories={categories}
          search={search}
          filter={filter}
          page={page}
        />
      ) : (
        <AddModal
          open={openModal}
          handleClose={handleClose}
          isUpdating={false}
          typeProd="simple"
          products={products}
          categories={categories}
          search={search}
          filter={filter}
          page={page}
          initialValues={{
            sku: '',
            name: '',
            description: '',
            regular_price: '',
            stock: '',
          }}
        />
      )}
    </DashboardLayout>
  );
}
