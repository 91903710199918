import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import SuiBox from '../../components/SuiBox';
import SuiButton from '../../components/SuiButton';
import Icon from '@mui/material/Icon';
import SuiTypography from '../../components/SuiTypography';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from '../../redux/actions/ordersActionCreators';
import Order from './Orders';
import AddOrder from './AddOrder';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '../../components/Select';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import Sleeping from '../../assets/Sleeping.svg';
import 'moment/locale/fr';
import GlobalLoading from '../../components/GlobalLoading';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateFilter from '../../components/DateFilter';

function OrdersInformations() {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.list);
  const totalPages = useSelector((state) => state.orders.totalPages);
  const totalOrders = useSelector((state) => state.orders.totalOrders);
  moment.locale('fr');

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('any');
  const [search, setSearch] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [page, setPage] = useState(1);
  const [data, setData] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: moment()._d,
      endDate: moment()._d,
      key: 'selection',
    },
  ]);
  const [after, setAfter] = useState(
    moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss')
  );
  const [before, setBefore] = useState(
    moment().set({ hour: 0, minute: 0, second: 0 }).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
  );
  console.log({ data })
  useEffect(() => {
    dispatch(fetchOrders({ search, filter, page, after, before }));
  }, [dispatch, search, filter, page, after, before]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (order) => {
    setData(order);
    setOpen(true);
    setIsUpdating(true);
  };

  const handleSelectedId = (id) => {
    setSelectedId(id);
  };

  const handleAll = () => {
    setDate([{ ...date[0], startDate: '', endDate: '' }]);
    setAfter('');
    setBefore('');
  };

  const handleToday = () => {
    setDate([{ ...date[0], startDate: moment()._d, endDate: moment()._d }]);
    setAfter(moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss'));
    setBefore(
      moment().set({ hour: 0, minute: 0, second: 0 }).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
    );
  };

  const handleDate = (item) => {
    setDate([item]);
    setAfter(
      moment(item.startDate).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss')
    );
    setBefore(
      moment(item.endDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .add(1, 'days')
        .format('YYYY-MM-DDTHH:mm:ss')
    );
  };

  const handleType = (str) => {
    setFilter(str);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsUpdating(false);
  };

  return (
    <>
      <SuiBox px={6} mb={3} display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="h6" fontWeight="medium">
          Liste des commandes
        </SuiTypography>
        <SuiButton variant="gradient" color="dark" onClick={handleClickOpen}>
          <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
          &nbsp;Ajouter un bon de commande
        </SuiButton>
      </SuiBox>

      <Grid container mb={3} spacing={5} alignItems="center">
        <Grid item xs={7}>
          <TextField
            type="text"
            placeholder="Recherche"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            name="quantity"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            values={[
              { label: 'Tout', value: 'any' },
              { label: 'En cours', value: 'processing' },
              { label: 'En livraison', value: 'delevering' },
              { label: 'Terminée', value: 'completed' },
              { label: 'Annulée', value: 'cancelled' },
            ]}
            typeProd={filter}
            sx={{ width: '100%' }}
            handleType={handleType}
          />
        </Grid>
        <Grid item xs={3}>
          <DateFilter
            date={date}
            handleAll={handleAll}
            handleToday={handleToday}
            handleDate={handleDate}
          />
        </Grid>
      </Grid>
      <Card id="delete-account">
        <SuiBox pt={1} pb={2} px={2}>
          <SuiBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
            style={{ height: '600px', overflow: 'auto' }}
          >
            <GlobalLoading />
            {orders.length > 0 ? (
              orders.map((order) => (
                <Order
                  order={order}
                  handleSelectedId={handleSelectedId}
                  selectedId={selectedId}
                  search={search}
                  filter={filter}
                  page={page}
                  after={after}
                  before={before}
                  handleUpdate={handleUpdate}
                />
              ))
            ) : (
              <div style={{ marginTop: '65px', textAlign: 'center' }}>
                <h2>Pas de commande pour cette date</h2>
                <img
                  src={Sleeping}
                  alt="Pas de commande"
                  className="not-found-page-image"
                  style={{ width: '30%', alignItems: 'center' }}
                />
              </div>
            )}
          </SuiBox>
        </SuiBox>
        {isUpdating ? (
          <AddOrder
            open={open}
            handleClose={handleClose}
            initialValues={{
              first_name: data.billing.first_name,
              last_name: data.billing.last_name,
              address_1: data.billing.address_1,
              postcode: data.billing.postcode,
              phone: data.billing.phone,
              email: data.billing.email,
              line_items: data.line_items,
              date: data.meta_data.find((el) => el.key === 'delivery_date').value,
              info: data.meta_data.find((el) => el.key === '_billing_informations_manquantes')
                .value,
            }}
            position={{
              lat:
                data.meta_data.find((el) => el.key === 'lpac_latitude') &&
                data.meta_data.find((el) => el.key === 'lpac_latitude').value,
              lng:
                data.meta_data.find((el) => el.key === 'lpac_longitude') &&
                data.meta_data.find((el) => el.key === 'lpac_longitude').value,
            }}
            orderId={data.id}
            search={search}
            filter={filter}
            page={page}
            after={after}
            before={before}
            disabled={true}
            isUpdating={true}
          />
        ) : (
          <AddOrder
            open={open}
            handleClose={handleClose}
            initialValues={{
              first_name: '',
              last_name: '',
              address_1: '',
              postcode: '',
              phone: '',
              email: '',
              line_items: [],
              date: moment().format('YYYY-MM-DD'),
              info: '',
            }}
            position={{
              lat: null,
              lng: null,
            }}
            search={search}
            filter={filter}
            page={page}
            after={after}
            before={before}
            disabled={false}
            isUpdating={false}
          />
        )}
        <Grid container mb={2} ml={2}>
          <Grid item xs={10}>
            <Pagination
              count={totalPages}
              variant="outlined"
              onChange={(e, value) => setPage(value)}
            />
          </Grid>
          <Grid item xs={2}>
            <SuiTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
            >{`Nombre de résultats: ${totalOrders}`}</SuiTypography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default OrdersInformations;
