import {
    SET_CATEGORIES,
} from '../types/categoriesTypes.js';

const initialState = {
    list: [],
    loading: false,
    selected: null,
    totalPages: null,
    totalCategories: null
};

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES:
            return { ...state, list: action.payload.categories, totalPages: action.payload.totalPages, totalCategories: action.payload.totalCategories };
        //   case SET_PRODUCT_BY_ID:
        //     return { ...state, productById: action.payload };
        //   case ADD_PRODUCT:
        //     return { ...state, list: [...state.list, action.payload] };
        //   case REMOVE_PRODUCT:
        //     return { ...state, list: [...state.list] };
        //   case UPDATE_PRODUCT:
        //     return state.list.map((prod) =>
        //       prod.id === action.payload.id ? { ...state, prod: action.payload } : prod
        //     );
        default:
            return state;
    }
};

export default categoriesReducer;
