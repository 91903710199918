import { REQUEST_STARTED, REQUEST_FAILED, REQUEST_SUCCEEDED } from '../types/feedbackTypes';

const initialState = {
    loading: false,
    error: '',
    show: false
};

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_STARTED:
            return { ...state, loading: true, show: action.payload };
        case REQUEST_SUCCEEDED:
            return { ...state, loading: false };
        case REQUEST_FAILED:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}

export default feedbackReducer