import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import moment from 'moment'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ProductDetails({ open, handleClose, product }) {
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="xl"
                PaperProps={{ sx: { width: '60%', padding: '25px ' } }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    sx={{ fontSize: '24px' }}
                >
                    {product.name}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} mt={3}>
                        {product &&
                            product.images.map((el) => (
                                <Grid item xs={3}>
                                    <img src={el.src} alt="produit" width="100px" height="100px" />
                                </Grid>
                            ))}
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography gutterBottom mt={3} variant="h6" color="red">
                                Description:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" fontSize="small">
                                {product.description.replaceAll('<p>', ' ').replaceAll('</p>', ' ')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography gutterBottom variant="h6" mt={1}>
                        <span style={{ color: "red" }}>Stock: </span>{product.stock_quantity > '10' ? (
                            <span style={{ color: 'green' }}>{product.stock_quantity} {product.meta_data.filter((el) => el.key === '_woo_uom_input')[0].value}</span>
                        ) : product.stock_quantity > '0' ? (
                            <span style={{ color: 'orange' }}>{product.stock_quantity} {product.meta_data.filter((el) => el.key === '_woo_uom_input')[0].value}</span>
                        ) : (
                            <span style={{ color: 'red' }}>0 {product.meta_data.filter((el) => el.key === '_woo_uom_input')[0].value}</span>
                        )}
                    </Typography>
                    <Typography gutterBottom variant="h6" mt={1}>
                        <span style={{ color: "red" }}>Tarif Régulier: </span>{product.regular_price} Dt
                    </Typography>

                    <Typography gutterBottom variant="h6" mt={1}>
                        <span style={{ color: "red" }}>Tarif Promo: </span>{product.sale_price ? product.sale_price : 0} Dt
                    </Typography>

                    {
                        product.date_on_sale_from && (
                            <Typography gutterBottom variant="h6" mt={1}>
                                <span style={{ color: "red" }}>Date du Promo: </span>{`Du ${moment(product.date_on_sale_from).format("DD-MM-YYYY")} au ${moment(product.date_on_sale_to).format("DD-MM-YYYY")}`}
                            </Typography>
                        )
                    }

                    <Grid container mt={1}>
                        <Grid item xs={2}>
                            <Typography gutterBottom variant="h6" color="red">
                                Catégories:
                            </Typography>
                        </Grid>
                        {product.categories.map((el) => (
                            <Grid item xs={2}>
                                <Typography gutterBottom variant="h6" >
                                    {el.name}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>

                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>
        </div>
    );
}
