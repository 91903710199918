import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import SuiInput from '../../components/SuiInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import {
  requestAddingCustomer,
  requestUpdatingCustomer,
} from '../../redux/actions/cutomersActionCreators';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';

export default function AddModal({ open, handleClose, isUpdating, initialValues, id, page }) {
  const dispatch = useDispatch();

  const initValSchema = {
    first_name: Yup.string().required('Ce champ est obligatoire!'),
    last_name: Yup.string().required('Ce champ est obligatoire!'),
    username: Yup.string().required('Ce champ est obligatoire!'),
    email: Yup.string().email('Adresse mail invalide').required('Ce champ est obligatoire!'),
    phone: Yup.string()
      .matches(/^-?[0-9,\.]+$/, 'Doit contenir uniquement des chiffres')
      .required('Ce champ est obligatoire!'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: !isUpdating && Yup.object(initValSchema),
    onSubmit: (values, { resetForm }) => {
      if (isUpdating) {
        dispatch(
          requestUpdatingCustomer(
            id,
            values.first_name,
            values.last_name,
            // values.username,
            values.email,
            values.phone
          )
        );
      } else {
        dispatch(
          requestAddingCustomer(
            values.first_name,
            values.last_name,
            values.username,
            values.email,
            values.phone,
            page
          )
        );
      }
      resetForm({ values: '' });
      handleClose();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{ sx: { width: '80%' } }}>
        {console.log('From', formik.values)}
        {isUpdating ? (
          <DialogTitle>Modification d'un client</DialogTitle>
        ) : (
          <DialogTitle>Ajout d'un client</DialogTitle>
        )}
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Prénom
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Prénom"
                    name="first_name"
                    error={Boolean(formik.touched.first_name && formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nom
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Nom"
                    name="last_name"
                    error={Boolean(formik.touched.last_name && formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nom d'utilisateur
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Nom d'utilisateur"
                    name="username"
                    error={Boolean(formik.touched.username && formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    disabled={isUpdating}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Adresse mail
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Adresse mail"
                    name="email"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Numéro de téléphone
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Numéro de téléphone"
                    name="phone"
                    error={Boolean(formik.touched.phone && formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            {isUpdating ? (
              <Button type="submit">Modifier</Button>
            ) : (
              <Button type="submit">Ajouter</Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
