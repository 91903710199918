import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SuiTypography from '../../components/SuiTypography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import { requestAddingAttribut } from '../../redux/actions/attributesActionCreators';

export default function AddModal({ open, handleClose, initialValues, elements, isUpdating }) {
  const dispatch = useDispatch();

  const [terms, setTerms] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      term: '',
    },
  ]);
  const addInputField = () => {
    setInputFields([...inputFields, {}]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChange = (index, value, name) => {
    console.log(value);
    // const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };
  useEffect(() => {
    console.log('inputFields', inputFields);
    const data = inputFields.map((term) => term.term);
    setTerms(data);
  }, [inputFields]);

  useEffect(() => {
    if (isUpdating) {
      setInputFields(elements);
    }
  }, [elements]);

  useEffect(() => {
    console.log('gg', inputFields);
  }, [inputFields]);

  const initValSchema = {
    name: Yup.string().required('Ce champ est obligatoire!'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema:
      // !isUpdating &&
      Yup.object(initValSchema),
    onSubmit: (values, { resetForm }) => {
      console.log('ynzel');
      dispatch(
        requestAddingAttribut({
          name: values.name,
          terms: terms,
        })
      );
      resetForm({ values: '' });
      handleClose();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{ sx: { width: '70%' } }}>
        {console.log('From', formik.values)}
        <DialogTitle>Ajout d'un attribut</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nom de l'attribut
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Nom de l'attribut"
                    name="name"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </Stack>
              </Grid>

              {inputFields.map((data, index) => {
                return (
                  <Grid item xs={4}>
                    {' '}
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Element de l'attribut
                      </SuiTypography>
                      <TextField
                        type="text"
                        placeholder="Element de l'attribut"
                        name="term"
                        required
                        value={data.term || ''}
                        onChange={(evnt) =>
                          handleChange(index, evnt.target.value, evnt.target.name)
                        }
                      />
                    </Stack>
                    <Stack spacing={2}>
                      {inputFields.length !== 1 ? (
                        <Button style={{ color: 'red' }} onClick={removeInputFields}>
                          Supprimer cet element
                        </Button>
                      ) : (
                        ''
                      )}
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
            <div className="row">
              <Button onClick={addInputField}>Ajouter un autre element</Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit">Ajouter</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
