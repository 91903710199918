import Api from '../../axios/api';
import Axios from '../../axios/authAxios';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import { SET_ORDERS, REMOVE_ORDER, ADD_ORDER } from '../types/ordersTypes.js';
import { requestAddingForm } from './deliveryFormsActionCreators';
import moment from 'moment';

export const setOrders = (orders, totalPages, totalOrders) => ({
  type: SET_ORDERS,
  payload: { orders: orders, totalPages: totalPages, totalOrders: totalOrders },
});
export const addOrder = (order) => ({ type: ADD_ORDER, payload: order });
export const updateStatus = (id) => ({ type: REMOVE_ORDER, payload: id });

export const fetchOrders = ({ search, filter, page, after, before, per_page, from_map }) => {
  return async (dispatch) => {
    dispatch(requestStarted());
    let url =
      after && before
        ? `orders?search=${search}&status=${filter}&page=${page}&after=${after}&before=${before}`
        : `orders?search=${search}&status=${filter}&page=${page}`;
    if (per_page) {
      url += `&per_page=${per_page}`;
    }
    let res = await Api.get(url);
    try {
      if (res && res.data) {
        if (from_map) {
          let ids = res.data
            .map((el) => `&filters[delivery_from][order_id][$in]=${el.id}`)
            .join('');
          let deliveries = await Axios.get(`api/deliveries?populate=*${ids}`);
          let dealers = {};
          deliveries.data.data.forEach((el) => {
            dealers[
              el &&
              el.attributes &&
              el.attributes.delivery_from &&
              el.attributes.delivery_from.data &&
              el.attributes.delivery_from.data.attributes &&
              el.attributes.delivery_from.data.attributes.order_id
            ] = {
              user:
                el &&
                el.attributes &&
                el.attributes.user &&
                el.attributes.user.data &&
                el.attributes.user.data.id,
              delivery: el && el.id,
            };
          });
          let ordersList = res.data
            .filter(
              (el) =>
                el.meta_data &&
                el.meta_data.length &&
                el.meta_data.find(
                  (item) => item.key === 'delivery_type' && item.value === 'delivery'
                ) &&
                el.meta_data.find((item) => item.key === 'lpac_latitude') &&
                el.meta_data.find((item) => item.key === 'lpac_longitude')
            )
            .map((el) => ({
              ...el,
              dealer: dealers[el.id] ? dealers[el.id]['user'] : null,
              delivery: dealers[el.id] ? dealers[el.id]['delivery'] : null,
              isFrais: !!el.line_items.find(
                (product) =>
                  product.product_data &&
                  product.product_data.meta_data &&
                  product.product_data.meta_data.find(
                    (m) => m.key === '_et_gtin' && m.value === 'frais'
                  )
              ),
              position: {
                lat:
                  el.meta_data &&
                    el.meta_data.length &&
                    el.meta_data.find((item) => item.key === 'lpac_latitude')
                    ? Number(el.meta_data.find((item) => item.key === 'lpac_latitude')['value'])
                    : 0,
                lng:
                  el.meta_data &&
                    el.meta_data.length &&
                    el.meta_data.find((item) => item.key === 'lpac_longitude')
                    ? Number(el.meta_data.find((item) => item.key === 'lpac_longitude')['value'])
                    : 0,
              },
            }))
            .sort((a, b) => Number(b.isFrais) - Number(a.isFrais));

          dispatch(
            setOrders(ordersList, res.headers['x-wp-totalpages'], res.headers['x-wp-total'])
          );
          dispatch(requestSucceeded());
        } else {
          dispatch(setOrders(res.data, res.headers['x-wp-totalpages'], res.headers['x-wp-total']));
          dispatch(requestSucceeded());
        }
      }
    } catch (err) {
      console.log({ err });
      if (err && err.response && err.response.data && err.response.data.message) {
        dispatch(requestFailed(err.response.data.message));
      } else {
        dispatch('Problème de connexion avec le serveur');
      }
    }
  };
};

export const requestAddingOrder = (payload) => (dispatch) => {
  dispatch(requestStarted());
  Api.post('orders', payload.data)
    .then((res) => {
      dispatch(requestSucceeded('Bon de commande généré avec succée!'));
      dispatch(addOrder(res.data));
      dispatch(
        fetchOrders({
          search: payload.search,
          filter: payload.filter,
          page: payload.page,
          after: payload.after,
          before: payload.before,
        })
      );
    })
    .catch((error) => dispatch(requestFailed(error.response.data.message)));
};

export const requestUpdatingOrder =
  ({ orderId, currentPosition, search, filter, page, after, before }) =>
    (dispatch) => {
      dispatch(requestStarted());
      Api.put(`orders/${orderId}`, {
        meta_data: [
          { key: 'lpac_latitude', value: currentPosition.lat },
          { key: 'lpac_longitude', value: currentPosition.lng },
        ],
      })
        .then((res) => {
          dispatch(requestSucceeded('Position mis à jour avec succès!'));
          dispatch(updateStatus(orderId));
          dispatch(fetchOrders({ search, filter, page, after, before }));
        })
        .catch((error) => {
          console.log(error);
          dispatch(requestFailed('Echec de la mise à jour!'));
        });
    };

export const requestCancelingOrder =
  ({ id, search, filter, page, after, before }) =>
    (dispatch) => {
      dispatch(requestStarted());
      Api.put(`orders/${id}`, {
        status: 'cancelled',
      })
        .then((res) => {
          dispatch(requestSucceeded('Bon de commande Annulé avec succès!'));
          dispatch(updateStatus(id));
          dispatch(fetchOrders({ search, filter, page, after, before }));
        })
        .catch((error) => {
          console.log(error);
          dispatch(requestFailed("Echec de l'annulation!"));
        });
    };

export const requestTerminateOrder =
  ({ id }) =>
    (dispatch) => {
      dispatch(requestStarted());
      Api.put(`orders/${id}`, {
        status: 'completed',
      })
        .then((res) => {
          dispatch(updateStatus(id));
          dispatch(requestSucceeded('Commande livré avec succès!'));
        })
        .catch((error) => {
          console.log(error);
          dispatch(requestFailed("Echec de l'annulation!"));
        });
    };

export const requestSetDealer = ({ dealer, order, delivery }) => {
  return async (dispatch, getState) => {
    try {
      let res = null;
      const state = getState();
      const orders = state.orders.list;
      if (delivery) {
        res = await Axios.put(`api/deliveries/${delivery}`, {
          data: {
            user: dealer,
          },
        });
      } else {
        let checkExistantDeliveryForm = await Axios.get(
          `api/delivery-froms?filters[order_id][$eq]=${order}`
        );
        let existantDeliveryForm =
          checkExistantDeliveryForm &&
          checkExistantDeliveryForm.data &&
          checkExistantDeliveryForm.data.data &&
          checkExistantDeliveryForm.data.data.length;
        if (!existantDeliveryForm) {
          let newDeliveryForm = await Axios.post(`api/delivery-froms`, {
            data: {
              order_id: order.toString(),
            },
          });
          if (
            newDeliveryForm &&
            newDeliveryForm.data &&
            newDeliveryForm.data.data &&
            newDeliveryForm.data.data.id
          ) {
            delivery = newDeliveryForm.data.data.id;
          }
        } else {
          delivery = checkExistantDeliveryForm.data.data[0].id;
        }
        res = await Axios.post(`api/deliveries/`, {
          data: {
            user: dealer,
            delivery_from: delivery,
          },
        });
      }
      const newOrders = orders.map((el) => ({
        ...el,
        dealer: el.id === order ? dealer : el.dealer,
        delivery: el.id === order ? delivery : el.delivery,
      }));
      dispatch(setOrders([...newOrders], null, null));
    } catch (error) {
      console.log(error);
      dispatch(requestFailed("Echec d'affectation du livreur!"));
    }
  };
};
