import React from 'react';
import Card from '@mui/material/Card';
import SuiBox from '../../../../components/SuiBox';
import ProfileInfoCard from './../../../../examples/Cards/InfoCards/ProfileInfoCard/index';


function PlatformSettings({ profile }) {


  return (
    <Card>
      <SuiBox pt={2} px={2}>
        {/* <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          platform settings
        </SuiTypography> */}
      </SuiBox>
      {/* {profile.map(({ id, attributes }) => ( */}
      <ProfileInfoCard
        title="Informations de la société"
        // description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
        // image={`${process.env.REACT_APP_API_URL}${
        //   attributes &&
        //   attributes.company_logo &&
        //   attributes.company_logo.data &&
        //   attributes.company_logo.data.attributes &&
        //   attributes.company_logo.data.attributes.url
        // }`}
        // info={{
        //   raisonSocial: attributes.company_name,
        //   adresse: attributes.adress,
        //   matriculeFiscale: attributes.tax_identification_number,
        //   registreCommercial: attributes.commercial_register,
        //   rib: attributes.rib,
        // }}

        // social={[]}
        action={{ route: '', tooltip: 'Edit Profile' }}
      />
      {/* ))} */}

      {/* <SuiBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
        <SuiTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          account
        </SuiTypography>
        <SuiBox display="flex" py={1} mb={0.25}>
          <SuiBox mt={0.25}>
            <Switch checked={followsMe} onChange={() => setFollowsMe(!followsMe)} />
          </SuiBox>
          <SuiBox width="80%" ml={2}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              Email me when someone follows me
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox display="flex" py={1} mb={0.25}>
          <SuiBox mt={0.25}>
            <Switch checked={answersPost} onChange={() => setAnswersPost(!answersPost)} />
          </SuiBox>
          <SuiBox width="80%" ml={2}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              Email me when someone answers on my post
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox display="flex" py={1} mb={0.25}>
          <SuiBox mt={0.25}>
            <Switch checked={mentionsMe} onChange={() => setMentionsMe(!mentionsMe)} />
          </SuiBox>
          <SuiBox width="80%" ml={2}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              Email me when someone mentions me
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox mt={3}>
          <SuiTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
            application
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex" py={1} mb={0.25}>
          <SuiBox mt={0.25}>
            <Switch checked={newLaunches} onChange={() => setNewLaunches(!newLaunches)} />
          </SuiBox>
          <SuiBox width="80%" ml={2}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              New launches and projects
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox display="flex" py={1} mb={0.25}>
          <SuiBox mt={0.25}>
            <Switch checked={productUpdate} onChange={() => setProductUpdate(!productUpdate)} />
          </SuiBox>
          <SuiBox width="80%" ml={2}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              Monthly product updates
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox display="flex" py={1} mb={0.25}>
          <SuiBox mt={0.25}>
            <Switch checked={newsletter} onChange={() => setNewsletter(!newsletter)} />
          </SuiBox>
          <SuiBox width="80%" ml={2}>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              Subscribe to newsletter
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox> */}
    </Card>
  );
}

export default PlatformSettings;
