import React from 'react';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import { useState, useEffect } from 'react';
import axios from 'axios';

export const MapContainer = ({
  height,
  target,
  draggable,
  currentPosition,
  setCurrentPosition,
}) => {
  const mapStyles = {
    height: height,
    width: '100%',
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentPosition({ lat, lng });
    console.log(lat, lng);
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });

  useEffect(() => {
    if (target.lat && target.lng) {
      setCurrentPosition({
        lat: !isNaN(target.lat) ? Number(target.lat) : 34.79804005882154,
        lng: !isNaN(target.lng) ? Number(target.lng) : 10.782014169316348,
      });
    } else {
      if (target.address) {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?region=tn&address=${target.address}&key=${process.env.REACT_APP_MAP_API_KEY}&components=location&components=country:TN`
          )
          .then(({ data }) => {
            const cordinates = data.results[0].geometry.location;
            setCurrentPosition(cordinates);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setCurrentPosition({ lat: 34.79804005882154, lng: 10.782014169316348 });
      }
    }
  }, [target.lat, target.lng, target.address]);

  useEffect(() => {
    console.log({ currentPosition })
  }, [currentPosition])

  const renderMap = () => {
    return (
      <>
        {currentPosition.lat ? (
          <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={currentPosition}>
            {currentPosition.lat ? (
              <Marker
                position={currentPosition}
                onDragEnd={(e) => onMarkerDragEnd(e)}
                draggable={draggable}
              ></Marker>
            ) : null}
          </GoogleMap>
        ) : null}
      </>
    );
  };
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }
  return isLoaded ? renderMap() : <div>Loading...</div>;
};
