import React from 'react';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SuiTypography from './SuiTypography';

function GlobalLoading() {
  const loading = useSelector((state) => state.feedback.loading);
  const show = useSelector((state) => state.feedback.show);
  return (
    <>
      {loading && (
        <>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
            {show && <SuiTypography variant="h6" color="white">Veuillez patientez! Ceci peut prendre quelque minutes...</SuiTypography>}
          </Backdrop>
        </>
      )}
    </>
  );
}

export default GlobalLoading;
