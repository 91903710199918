import Axios from '../../axios/authAxios';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import { SET_PROFILS } from './../types/profilsTypes';


export const setProfils = (profils) => ({ type: SET_PROFILS, payload: profils });


export const fetchProfil = () => (dispatch) => {
  dispatch(requestStarted());
  Axios.get('/api/profils?populate=%2A&')
    .then((res) => {
      dispatch(requestSucceeded());
      const { data } = res.data;
      console.log(data);
      dispatch(setProfils(data));
    })
    .catch((err) => {
      console.log({ err });
      dispatch(requestFailed(err.message));
    });
};