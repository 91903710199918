import {
  SET_ATTRIBUTES,
  ADD_ATTRIBUT,
  REMOVE_ATTRIBUT,
} from '../types/attributesTypes';

const initialState = {
  list: [],
  loading: false,
  selected: null,
};

const attributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ATTRIBUTES:
      return { ...state, list: action.payload };
    case ADD_ATTRIBUT:
      return { ...state, list: [...state.list, action.payload] };
    case REMOVE_ATTRIBUT:
      return { ...state, list: [...state.list] };
    default:
      return state;
  }
};

export default attributesReducer;
