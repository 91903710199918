import React, { useEffect, useState } from 'react';
import Icon from '@mui/material/Icon';
import SuiTypography from '../../components/SuiTypography';
import SuiButton from '../../components/SuiButton';
import { useDispatch, useSelector } from 'react-redux';
import { requestCancelingOrder } from '../../redux/actions/ordersActionCreators';
import { requestAddingForm } from '../../redux/actions/deliveryFormsActionCreators';
import { useConfirm } from 'material-ui-confirm';
import { fetchForms } from '../../redux/actions/deliveryFormsActionCreators';
// import MapContainer from '../../components/MapContainer';
import { MapContainer } from '../../components/MapContainer';

import Axios from '../../axios/authAxios';
import download from 'downloadjs';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Store from '../../assets/store.png';

function Orders({
  order,
  handleSelectedId,
  selectedId,
  search,
  filter,
  page,
  before,
  after,
  handleUpdate,
  handleUpdatePosition,
}) {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const forms = useSelector((state) => state.deliveryForms.list);

  const [expand, setExpand] = useState(false);
  const [currentPosition, setCurrentPosition] = React.useState({
    lat: null,
    lng: null,
  });

  const handleDelete = (id, fullName) => {
    confirm({
      title: 'Etes vous sure?',
      description: `Voulez vous annuler la commande de ${fullName}?`,
      confirmationText: 'Confimer',
      cancellationText: 'Annuler',
    })
      .then(() => {
        dispatch(requestCancelingOrder({ id, search, filter, page, after, before }));
      })
      .catch(() => console.log('Supression Annulé'));
  };

  useEffect(() => {
    setExpand(order.id == selectedId);
  }, [selectedId]);

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch]);

  const handleFrom = (
    type,
    orderNumber,
    date,
    horaire,
    customer_name,
    customer_adress,
    customer_phoneNumber,
    customer_mail,
    total_taxes,
    total_price,
    discount,
    products,
    fees,
    loyalty_card
  ) => {
    const form = forms.filter((el) => el && el.attributes && el.attributes.order_id == orderNumber);
    if (form.length > 0) {
      Axios.post(
        `/api/deliveryPdf`,
        {
          number: form[0].id,
          discount_total: discount,
          total: total_price,
          total_tax: total_taxes,
          customer_name: customer_name,
          date: date,
          horaire: horaire,
          customer_adress: customer_adress,
          email: customer_mail,
          phone: customer_phoneNumber,
          line_items: products,
          type: type,
          fees: fees,
          loyalty_card: loyalty_card,
        },
        {
          headers: {},

          responseType: 'blob',
        }
      )
        .then((res) => {
          const content = res.headers['content-type'];
          download(res.data, `Bon de livraison N° ${form[0].id}: ${customer_name}`, content);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(
        requestAddingForm({
          customer_name: customer_name,
          date: date,
          horaire: horaire,
          customer_adress: customer_adress,
          customer_phoneNumber: customer_phoneNumber,
          customer_mail: customer_mail,
          total_taxes: total_taxes,
          total_price: total_price,
          discount: discount,
          products: products,
          order_number: orderNumber,
          type: type,
          fees: fees,
          loyalty_card: loyalty_card,
          fromOrder: true,
          download,
          search,
          filter,
          page,
          after,
          before,
        })
      );
    }
  };
  const date_livraison =
    order &&
    order.meta_data &&
    order.meta_data.find((el) => el.key === 'delivery_date') &&
    order.meta_data &&
    order.meta_data.find((el) => el.key === 'delivery_date').value;

  const date_retrait =
    order &&
    order.meta_data &&
    order.meta_data.find((el) => el.key === 'pickup_date') &&
    order.meta_data &&
    order.meta_data.find((el) => el.key === 'pickup_date').value;

  const info =
    order.meta_data &&
    order.meta_data.find((el) => el.key === '_billing_informations_manquantes') &&
    order.meta_data &&
    order.meta_data.find((el) => el.key === '_billing_informations_manquantes').value;
  return (
    // <SuiBox bgColor="grey-100" borderRadius="lg" p={3} mt={2}>

    <Accordion
      expanded={expand}
      sx={{
        '&:before': {
          display: 'none',
        },
        backgroundColor: '#F8F9FA',
        padding: '25px',
        marginTop: '20px',
        marginBottom: '5px',
        borderRadius: '12px',
      }}
      onClick={() => (expand ? setExpand(false) : setExpand(order.id == selectedId))}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ borderRadius: '10px' }}
        onClick={() => {
          handleSelectedId(order.id);
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={1} mt={1} lineHeight={0} display="flex" flexDirection="column">
            {order.line_items.find(
              (product) =>
                product.product_data &&
                product.product_data.meta_data &&
                product.product_data.meta_data.find(
                  (m) => m.key === '_et_gtin' && m.value === 'frais'
                )
            ) ? (
              <Tooltip title="Commande prioritaire">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.75 5C9.33579 5 9 5.33579 9 5.75V10.716C9 10.8921 9.09268 11.0552 9.24397 11.1454L13.6109 13.7488C13.941 13.9456 14.3673 13.8491 14.5804 13.5294C14.8088 13.1867 14.7045 12.7227 14.3514 12.5108L10.7305 10.3383C10.5875 10.2525 10.5 10.098 10.5 9.9312V5.75C10.5 5.33579 10.1642 5 9.75 5ZM19 10C18.4477 10 18 10.4477 18 11V15C18 15.5523 18.4477 16 19 16C19.5523 16 20 15.5523 20 15V11C20 10.4477 19.5523 10 19 10ZM19 18C18.4477 18 18 18.4477 18 19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18ZM16 18C14.3 19.3 12.3 20 10 20C4.5 20 0 15.5 0 10C0 4.5 4.5 0 10 0C14.8 0 18.9 3.4 19.8 8H17.7C16.8 4.6 13.7 2 10 2C5.6 2 2 5.6 2 10C2 14.4 5.6 18 10 18C12.4 18 14.5 16.9 16 15.3V18Z"
                    fill="#EA0606"
                  />
                </svg>
              </Tooltip>
            ) : (
              <Tooltip title="Commande normal">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C4.47 20 0 15.5 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM9.75 5C10.1642 5 10.5 5.33579 10.5 5.75V9.97129C10.5 10.1441 10.5911 10.3041 10.7397 10.3922L14.3746 12.5489C14.7188 12.7531 14.8298 13.1992 14.6214 13.5409C14.4159 13.878 13.9768 13.9861 13.6383 13.783L9.24275 11.1457C9.09215 11.0553 9 10.8925 9 10.7169V5.75C9 5.33579 9.33579 5 9.75 5Z"
                    fill="#344767"
                  />
                </svg>
              </Tooltip>
            )}

            {/*  */}
          </Grid>
          <Grid item xs={1}>
            <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {`Bon n° ${order.number}`}
            </SuiTypography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: 'center' }}>
            <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {`${order.billing.first_name} ${order.billing.last_name}`}
            </SuiTypography>
          </Grid>

          <Grid item xs={2} mb={1} lineHeight={0} display="flex" flexDirection="column">
            {date_livraison ? (
              <>
                <SuiTypography variant="caption" color="text" mb={1} textAlign="center">
                  Date de la livraison:
                </SuiTypography>
                <SuiTypography
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                  textAlign="center"
                >
                  {date_livraison}
                </SuiTypography>
              </>
            ) : (
              <>
                <SuiTypography variant="caption" color="text" mb={1} textAlign="center">
                  Date du retrait:
                </SuiTypography>
                <SuiTypography
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                  textAlign="center"
                >
                  {date_retrait}
                </SuiTypography>
              </>
            )}
          </Grid>

          <Grid item xs={1} mb={1} lineHeight={0} display="flex" flexDirection="column">
            <SuiTypography variant="caption" color="text" mb={1} textAlign="center">
              Prix total:
            </SuiTypography>
            <SuiTypography variant="caption" fontWeight="medium" textAlign="center">
              {`${order.total} $`}
            </SuiTypography>
          </Grid>

          <Grid item xs={2} mb={1} lineHeight={0} display="flex" flexDirection="column">
            <SuiTypography variant="caption" color="text" mb={1} textAlign="center">
              Etat:
            </SuiTypography>
            <SuiTypography
              variant="caption"
              fontWeight="medium"
              textAlign="center"
              color={order.status === 'cancelled' && 'error'}
            >
              {order.status === 'processing'
                ? 'En cours'
                : order.status === 'delevering'
                ? 'En livraison'
                : order.status === 'cancelled'
                ? 'Annulée'
                : 'Terminé'}
            </SuiTypography>
          </Grid>

          <Grid
            item
            xs={3}
            mb={1}
            display="flex"
            justify="center"
            alignItems="center"
            sx={{ justifyContent: 'center' }}
          >
            <SuiButton
              variant="text"
              color="dark"
              disabled={order.status === 'cancelled'}
              onClick={(e) => {
                e.stopPropagation();
                handleFrom(
                  date_livraison ? 'Livraison' : 'Retrait magasin',
                  order.number,
                  date_livraison ? date_livraison : date_retrait,
                  date_livraison
                    ? order.meta_data &&
                        order.meta_data.find((el) => el.key === 'delivery_time') &&
                        order.meta_data &&
                        order.meta_data.find((el) => el.key === 'delivery_time').value
                    : order.meta_data &&
                        order.meta_data.find((el) => el.key === 'pickup_time') &&
                        order.meta_data &&
                        order.meta_data.find((el) => el.key === 'pickup_time').value,
                  `${order.billing.first_name} ${order.billing.last_name}`,
                  `${order.billing.address_1} ${order.billing.city} ${order.billing.postcode}`,
                  order.billing.phone,
                  order.billing.email,
                  order.total_tax,
                  order.total,
                  order.discount_total,
                  order.line_items,
                  (order &&
                    order.fee_lines &&
                    order.fee_lines.find((el) => el.name === 'Frais de livraison') &&
                    order &&
                    order.fee_lines &&
                    order.fee_lines.find((el) => el.name === 'Frais de livraison').total) ||
                    (order &&
                      order.fee_lines &&
                      order.fee_lines.find((el) => el.name === 'Delivery Charges') &&
                      order &&
                      order.fee_lines &&
                      order.fee_lines.find((el) => el.name === 'Delivery Charges').total),
                  order.meta_data &&
                    order.meta_data.find((el) => el.key === '_billing_loyalty_card') &&
                    order.meta_data &&
                    order.meta_data.find((el) => el.key === '_billing_loyalty_card').value
                );
              }}
            >
              <Icon>description</Icon>&nbsp;Bon de livraison
            </SuiButton>

            <SuiButton
              variant="text"
              color="error"
              disabled={order.status === 'cancelled'}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(order.id, `${order.billing.first_name} ${order.billing.last_name}`);
              }}
            >
              <Icon>cancel</Icon>&nbsp;Annuler
            </SuiButton>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={6} mt={1} onClick={(e) => e.stopPropagation()}>
          <Grid item xs={3} align="left" display="flex" flexDirection="column" sx={{ gap: 2 }}>
            <SuiTypography variant="overline" fontWeight="medium">
              {`Nom et Prénom: ${order.billing.first_name} ${order.billing.last_name}`}
            </SuiTypography>
            <SuiTypography variant="overline" fontWeight="medium">
              {`Télephone: ${order.billing.phone}`}
            </SuiTypography>
            <SuiTypography variant="overline" fontWeight="medium">
              {`Adresse mail: ${order.billing.email}`}
            </SuiTypography>
            <SuiTypography variant="overline" fontWeight="medium">
              {date_livraison
                ? `Date de livraison ${date_livraison}`
                : `Date du retrait ${date_retrait}`}
            </SuiTypography>
            <SuiTypography variant="overline" fontWeight="medium">
              {date_livraison
                ? `Horaire: ${
                    order.meta_data &&
                    order.meta_data.find((el) => el.key === 'delivery_time') &&
                    order.meta_data &&
                    order.meta_data.find((el) => el.key === 'delivery_time').value
                  }`
                : `Horaire: ${
                    order.meta_data &&
                    order.meta_data.find((el) => el.key === 'pickup_time') &&
                    order.meta_data &&
                    order.meta_data.find((el) => el.key === 'pickup_time').value
                  }`}
            </SuiTypography>
            <SuiTypography variant="overline" fontWeight="medium">
              {`Adresse: ${order.billing.address_1}`}
            </SuiTypography>
            {info && (
              <SuiTypography variant="overline" fontWeight="medium">
                {`Information supplémentaire: ${info}`}
              </SuiTypography>
            )}
            <SuiTypography variant="overline" fontWeight="medium">
              {`Carte Fidélité: ${
                order.meta_data &&
                order.meta_data.find((el) => el.key === '_billing_loyalty_card') &&
                order.meta_data &&
                order.meta_data.find((el) => el.key === '_billing_loyalty_card').value
              }`}
            </SuiTypography>
          </Grid>
          <Grid item xs={5} align="center">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Désignation</TableCell>
                    <TableCell align="right">Quantité</TableCell>
                    <TableCell align="right">Prix unitaire</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.line_items.map((item) => (
                    <TableRow
                      key={item.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.name}
                      </TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">{item.subtotal}</TableCell>
                    </TableRow>
                  ))}
                  {date_livraison && (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Frais de livraison
                      </TableCell>
                      <TableCell align="right">1</TableCell>
                      <TableCell align="right">
                        {(order &&
                          order.fee_lines &&
                          order.fee_lines.find((el) => el.name === 'Frais de livraison') &&
                          order &&
                          order.fee_lines &&
                          order.fee_lines.find((el) => el.name === 'Frais de livraison').total) ||
                          (order &&
                            order.fee_lines &&
                            order.fee_lines.find((el) => el.name === 'Delivery Charges') &&
                            order &&
                            order.fee_lines &&
                            order.fee_lines.find((el) => el.name === 'Delivery Charges').total)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={4} align="center" display="flex" flexDirection="column" sx={{ gap: 2 }}>
            {order &&
            order.meta_data &&
            order.meta_data.find((el) => el.key === 'delivery_type') &&
            order.meta_data &&
            order.meta_data.find((el) => el.key === 'delivery_type').value !== 'delivery' ? (
              <>
                <img src={Store} alt="store" width="50%" />
              </>
            ) : (
              <>
                <SuiButton
                  variant="text"
                  color="dark"
                  disabled={order.status === 'cancelled'}
                  onClick={() => handleUpdate(order)}
                >
                  <Icon>edit</Icon>&nbsp;Modifier position
                </SuiButton>
                <MapContainer
                  height="100%"
                  setCurrentPosition={(pos) => setCurrentPosition(pos)}
                  target={{
                    lat:
                      order.meta_data.find((el) => el.key === 'lpac_latitude') &&
                      order.meta_data.find((el) => el.key === 'lpac_latitude').value,
                    lng:
                      order.meta_data.find((el) => el.key === 'lpac_longitude') &&
                      order.meta_data.find((el) => el.key === 'lpac_longitude').value,
                  }}
                  currentPosition={currentPosition}
                ></MapContainer>
              </>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default Orders;
