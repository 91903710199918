import Api from '../../axios/authAxios';
import axios from 'axios';

import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import {
  SET_DEALERS,
} from '../types/dealersTypes.js';

export const setDealers = (dealers) => ({ type: SET_DEALERS, payload: dealers });


export const fetchDealers = ({ role }) => (dispatch) => {
  dispatch(requestStarted());
  let url = 'api/users?populate[1]=role'
  if (role) {
    url += `&filters[role][name][$eq]=${role}`
  }
  Api.get(url)
    .then((res) => {

      console.log(res.data);

      dispatch(setDealers(res.data)); // array
      dispatch(requestSucceeded());
    })
    .catch((err) => {
      console.log({ err });
      dispatch(requestFailed(err.message));
    });
};


export const requestAddingDealer = (name, surname, phone, password, role) => (dispatch) => {
  dispatch(requestStarted());
  Api.post('/api/users', {
    username: `${name.replace(/ /g, '')}.${surname.replace(/ /g, '')}`,
    email: `${name.replace(/ /g, '')}.${surname.replace(/ /g, '')}@izy.tn`,
    password: password,
    phoneNumber: phone,
    name: `${name} ${surname}`,
    role: role,
    confirmed: true
  })
    .then((res) => {
      dispatch(requestSucceeded('Utilusateur ajouté avec succès!'));
      dispatch(fetchDealers({}));
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestFailed(error.message));
    });
};

export const requestRemovingDealer = (id) => (dispatch) => {
  dispatch(requestStarted());
  Api.delete(`api/users/${id}`)
    .then((res) => {
      dispatch(requestSucceeded('Utilisateur supprimé avec succès!'));
      dispatch(fetchDealers({}));
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestFailed);
    });
};

export const requestUpdatingDealer = (id, name, surname, phone, password, role) => (dispatch) => {
  dispatch(requestStarted());
  Api.put(`api/users/${id}`, {
    username: `${name}.${surname}`,
    email: `${name}.${surname}@izy.tn`,
    password: password,
    phoneNumber: phone,
    name: `${name} ${surname}`,
    role: role,
  })
    .then((res) => {
      dispatch(requestSucceeded('Utilisateur mis à jour avec succès!'));
      dispatch(fetchDealers({}));
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestFailed(error.message));
    });
};
