import {
  SET_ASSETS,
  ADD_ASSETS,
  REMOVE_ASSET,

} from '../types/assetsTypes';

const initialState = {
  list: [],
  loading: false,
  selected: null,
  meta: {
    pagination: { total: 0 },
  },
};

const assetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASSETS:
      return { ...state, list: action.payload.data, meta: action.payload.meta };
    case ADD_ASSETS:
      return { ...state, list: [...state.list, action.payload] };
    case REMOVE_ASSET:
      return { ...state, list: [...state.list] };

    default:
      return state;
  }
};

export default assetsReducer;
