import authAxios from '../../axios/authAxios';
import { LOGIN, SET_USERS, LOGOUT } from '../types/userTypes';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';

export const login = (user, token, role) => ({
  type: LOGIN,
  payload: { user, token, role },
});

export const users = (user) => ({ type: SET_USERS, payload: user });

export const logout = () => ({ type: LOGOUT });

export const requestLogin = (identifier, password) => (dispatch) => {
  dispatch(requestStarted());
  authAxios
    .post(`/api/auth/local`, {
      identifier,
      password,
    })
    .then(({ data }) => {
      dispatch(requestSucceeded());
      const { jwt, user } = data;
      dispatch(login({ ...user }, jwt));
      authAxios
        .get('/api/users/me?populate=*')
        .then((res) => {
          const { role } = res.data;
          dispatch(login({ ...user }, jwt, role.name));
          window.location.replace('/');
        })
        .catch((err) => {
          console.log(err.message);
        });
    })
    .catch((err) => {
      let errorMessage =
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.message;
      let errorName =
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.name;
      if (errorName === 'ValidationError') {
        errorMessage = 'Adresse mail ou mot de passe incorrect!';
      } else if (errorMessage === 'Your account has been blocked by an administrator') {
        errorMessage = 'Votre compte a été bloqué par un administrateur!';
      }
      errorMessage = errorMessage || 'LOGIN FAILED';
      dispatch(requestFailed(errorMessage));
    });
};

export const requestChangePassword = (oldPassword, newPassword) => {
  return async (dispatch) => {
    dispatch(requestStarted());
    try {
      const res = await authAxios.post(`/api/auth/change-password`, {
        currentPassword: oldPassword,
        password: newPassword,
        passwordConfirmation: newPassword,
      });
      dispatch(requestSucceeded('Mot de passe modifié avec succès!'));
    } catch (err) {
      dispatch(
        requestFailed(
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.message
        )
      );
    }
  };
};
