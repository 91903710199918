import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import {
  SET_ASSETS,
  ADD_ASSETS,
  REMOVE_ASSET,
  REMOVE_PHOTO,
  UPDATE_ASSETS,
  ADD_PHOTOS,
} from '../types/assetsTypes';
import { SET_PRODUCT_PHOTO } from '../types/productsTypes.js';
import Axios from '../../axios/authAxios';

export const setAssets = (assets) => ({ type: SET_ASSETS, payload: assets });
export const addAsset = (asset) => ({ type: ADD_ASSETS, payload: asset });
export const removeAsset = (id) => ({ type: REMOVE_ASSET, payload: id });
export const removePhoto = (photoId) => ({ type: REMOVE_PHOTO, payload: photoId });
export const updateAsset = (id, asset) => ({ type: UPDATE_ASSETS, payload: id, asset });
export const addPhotos = (id, photos) => ({ type: ADD_PHOTOS, payload: id, photos });
export const setProductPhoto = (photos) => ({ type: SET_PRODUCT_PHOTO, payload: photos });

export const fetchAssets = (page) => (dispatch) => {
  dispatch(requestStarted());
  Axios.get(`/api/assets?pagination[page]=${page}&populate=*`)
    .then((res) => {
      dispatch(requestSucceeded());
      dispatch(setAssets(res.data));
    })
    .catch((err) => {
      dispatch(requestFailed(err.response.data.message));
    });
};

export const requestAddingAsset = (name) => (dispatch) => {
  dispatch(requestStarted());
  Axios.post('/api/assets', {
    data: {
      name: name,
    },
  })
    .then((res) => {
      dispatch(requestSucceeded('Dossier ajouté avec succès!'));
      const { data } = res.data;
      dispatch(addAsset(data));
      dispatch(fetchAssets());
    })
    .catch((error) => {
      console.log(error, 'error');
      dispatch(requestFailed(error.response.data.message));
    });
};

export const requestRemovingAsset = (id) => (dispatch) => {
  dispatch(requestStarted());
  Axios.delete(`/api/assets/${id}`, {
    force: true,
  })
    .then((res) => {
      dispatch(requestSucceeded('Dossier supprimé avec succès!'));
      dispatch(fetchAssets());
      dispatch(removeAsset(id));
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestFailed(error.response.data.message));
    });
};

export const requestRemovingPhoto = (photoId) => (dispatch) => {
  dispatch(requestStarted());
  Axios.delete(`/api/upload/files/${photoId}`, {})
    .then((res) => {
      dispatch(requestSucceeded('Image supprimé avec succès!'));
      dispatch(removePhoto(photoId));
      dispatch(fetchAssets());
    })

    .catch((error) => {
      console.log(error);
      dispatch(requestFailed(error.response.data.message));
    });
};

export const requestUpdatingAsset = (id, name) => (dispatch) => {
  dispatch(requestStarted());
  Axios.put(`/api/assets/${id}`, {
    data: {
      name: name,
    },
  })
    .then((res) => {
      dispatch(requestSucceeded('Dossier renommé avec succès!'));

      dispatch(fetchAssets());
    })
    .catch((error) => {
      console.log(error.response.data);
      dispatch(requestFailed(error.response.data.message));
    });
};

export const requestAddingPhotos = (formData) => (dispatch) => {
  dispatch(requestStarted());
  Axios({
    method: 'post',
    url: '/api/upload',
    data: formData,
  })
    .then((response) => { })

    .catch((error) => {
      console.log(error.response.data);
      dispatch(requestFailed(error.response.data.message));
    });
};

export const requestUploadingPhotos = (formData) => (dispatch) => {
  dispatch(requestStarted());
  Axios({
    method: 'post',
    url: '/api/upload',
    data: formData,
  }).then((response) => {
    dispatch(getUploadedPhoto(response.data[0].id));
    dispatch(requestSucceeded('images ajoutés avec succès!'));
  });
};

export const getUploadedPhoto = (id) => async (dispatch) => {
  dispatch(requestStarted());
  await Axios({
    method: 'get',
    url: `/api/upload/files/${id}`,
  }).then((response) => {
    const { data } = response;

    dispatch(setProductPhoto(data));
  });
};
