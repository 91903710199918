
import {
    SET_PRODUCT_PHOTO 
  } from '../types/productsTypes.js';
  
  const initialState = {
    list: []
  };
  
  const productsPhotoReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PRODUCT_PHOTO :
        return { ...state, list :[...state.list, action.payload] };
     
      default:
        return state;
    }
  };
  
  export default productsPhotoReducer;
  