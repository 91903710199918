import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import SuiButton from '../../components/SuiButton';
import Icon from '@mui/material/Icon';
import { useDispatch } from 'react-redux';
import { requestBatchingProducts } from '../../redux/actions/productsActionCreators';
import axios from 'axios';
import { useConfirm } from 'material-ui-confirm';


const allowedExtensions = ['csv'];

const AddFile = ({ file, handleName, handleError, search, filter, page }) => {
    const dispatch = useDispatch();
    const confirm = useConfirm();


    const handleFile = () => {
        confirm({
            title: 'Etes vous sure?',
            description: `Cette opération peut prendre plusieurs minutes!`,
            confirmationText: 'Confirmer',
            cancellationText: 'Annuler',
        })
            .then(() => {
                dispatch(requestBatchingProducts(search, filter, page))
            })
            .catch(() => console.log('Supression Annulé'));
    };
    // const handleFileChange = (e) => {
    //     handleError('');
    //     if (e.target.files.length) {
    //         const inputFile = e.target.files[0];
    //         console.log('check here', e.target.files[0]);
    //         const fileExtension = inputFile && inputFile.type.split('/')[1];
    //         if (!allowedExtensions.includes(fileExtension)) {
    //             handleError('Veuillez selectionner un fichier de type CSV!');
    //             return;
    //         }
    //         handleName(e.target.files[0].name);
    //         handleFile(inputFile);
    //     }
    // };

    // const handleParse = () => {
    //     // if (!file) return setError('Enter a valid file');
    //     // const reader = new FileReader();
    //     // reader.onload = async ({ target }) => {
    //     //     const csv = Papa.parse(target.result, { header: true });
    //     //     const parsedData = csv && csv.data;
    //     //     const columns = Object.keys(parsedData[0]);
    //     //     setData(columns);
    //     // };
    //     // reader.readAsText(file);

    //     Papa.parse(file, {
    //         header: true,
    //         skipEmptyLines: true,
    //         complete: function (results) {
    //             setData(
    //                 results.data.map((el) => ({
    //                     name: el.designation,
    //                     sku: el.sku ? el.sku : el.sku1,
    //                     type: el.type,
    //                     regular_price: el.prixDetail,
    //                     images: [
    //                         {
    //                             src: 'https://southwesttrainingsolutions.co.uk/wp-content/uploads/2020/07/no-image.png',
    //                         },
    //                     ],
    //                     manage_stock: true,
    //                     stock_status: 'instock',
    //                     stock_quantity: el.stock,
    //                     reviews_allowed: false
    //                 }))
    //             );
    //             console.log(results.data);
    //             handleFile('');
    //             handleName('')
    //         },
    //     });
    //     dispatch(requestBatchingProducts(data, search, filter, page));
    // };


    return (
        <div>
            {/* {file ? (
                <>
                    <SuiButton
                        variant="gradient"
                        color="dark"
                        s
                        component="label"
                        type="file"
                        onClick={handleParse}
                    >
                        &nbsp;Confirmer
                    </SuiButton>
                </>
            ) : ( */}
            <>
                <SuiButton
                    variant="gradient"
                    color="dark"
                    component="label"
                    type="file"
                    onClick={handleFile}
                >
                    &nbsp;Synchroniser avec l'ERP
                    {/* <input hidden type="file" onChange={handleData} id="csvInput" name="file" /> */}
                </SuiButton>
            </>
            {/* )} */}
        </div>
    );
};

export default AddFile;
