import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssets, requestRemovingPhoto } from '../../redux/actions/assetsActionCreators';
import { useConfirm } from 'material-ui-confirm';
import SuiBox from '../../components/SuiBox';
import SuiButton from '../../components/SuiButton';
import Icon from '@mui/material/Icon';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddImages from './AddImages';

export default function EditAsset({id, handleClose, content }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [preview, setPreview] = useState(false);
  const [urlPreview, setUrlPreview] = useState('');
  const [upload, setUpload] = useState(false);
  
 
  const handleUpload = () => {
    setUpload(!upload);
  };

  const handlePreview = (url) => {
    setPreview(true);
    setUrlPreview(url);
  };

  const handleClosePreview = () => {
    setPreview(false);
  };

  const handleDelete = (photoId, name) => {
    confirm({
      description: `Voulez vous supprimer définitivement l'image ${name} ?`,
    })
      .then(() => {
        dispatch(requestRemovingPhoto(photoId));
        dispatch (fetchAssets())
      })
      .catch(() => console.log('Supression Annulé'));
  };
  function nonselected(e) {
    e.target.style.border = '';
  }

  function selected(e) {
    e.target.style.border = '2px solid #ececec';
  }


  
  if (content.data !== null) {
    return (
      <>
        {preview ? (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '6px' }}>
           
                <HighlightOffIcon  fontSize="large"  onClick={() => handleClosePreview()} sx={{ fontWeight: 'bold', ml: 0.5 }}>
                  add
                </HighlightOffIcon>
                &nbsp;
          
            </div>
            <SuiBox
              display="flex"
              justifyContent="center"
              boxShadow="0.8"
              onClick={handleClosePreview}
            >
              <img src={urlPreview} style={{ maxWidth: 900 }} />
            </SuiBox>
          </>
        ) : (
          <>
             <SuiBox>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            sx={{ fontWeight: 'bold' }}
          >
            <ArrowBackRoundedIcon sx={{ fontWeight: 'bold' }}  fontSize="large"  onClick={() => handleClose()} >add</ArrowBackRoundedIcon>
            &nbsp;
            <SuiButton variant="gradient" color="dark" onClick={() => handleUpload()}>
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              &nbsp;Ajouter des images
            </SuiButton>
          </SuiBox>
        </SuiBox>
            <AddImages open={upload} handleUpload={handleUpload} id={id}  />

            {content.data.map((el) => {
              return (
                <div style={{ display: 'inline-block' }}>
                  <Card sx={{ width: 345, mb: 2, mr: 2, display: 'flex', mt: 5 }} key={el.id}>
                    <CardMedia
                      onMouseEnter={selected}
                      onMouseOut={nonselected}
                      component="img"
                      height="200"
                      image={`${process.env.REACT_APP_API_URL}${el.attributes.url}`}
                      alt=""
                      onClick={() => {
                        handlePreview(`${process.env.REACT_APP_API_URL}${el.attributes.url}`);
                      }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {el.attributes.name.substring(0, 15)}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => {
                          handleDelete(el.id, el.attributes.name);
                        }}
                      >
                        Supprimer
                      </Button>
                    </CardActions>
                  </Card>
                </div>
              );
            })}
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <SuiBox>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            sx={{ fontWeight: 'bold' }}
          >
            <ArrowBackRoundedIcon sx={{ fontWeight: 'bold' }}  fontSize="large"  onClick={() => handleClose()} >add</ArrowBackRoundedIcon>
            &nbsp;
            <SuiButton variant="gradient" color="dark" onClick={() => handleUpload()}>
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              &nbsp;Ajouter des images
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <Typography
          gutterBottom
          variant="h2"
          component="div"
          sx={{ display: 'flex', justifyContent: 'center', mt: 15 }}
        >
       Pas de photos
        </Typography>
        <AddImages open={upload} handleUpload={handleUpload} id={id} />
      </>
    );
  }
}
