import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';

var Api = new WooCommerceRestApi({
  url: 'https://izy.tn',
  consumerKey: process.env.REACT_APP_CONSUMER_KEY,
  consumerSecret: process.env.REACT_APP_SECRET_KEY,
  version: 'wc/v3',
});


export default Api;
