import React, { useState, useEffect } from 'react';
import Icon from '@mui/material/Icon';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import SuiButton from '../../components/SuiButton';
import { useDispatch } from 'react-redux';
import { requestRemovingForm } from '../../redux/actions/deliveryFormsActionCreators';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { requestFailed } from '../../redux/actions/feedbackActionCreators';
import { requestAddingInvoice } from '../../redux/actions/invoicesActionCreators';

import Axios from '../../axios/authAxios';
import download from 'downloadjs';

function DeliveryForm({ form }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [invoiceData, setInvoiceData] = useState({});

  const handleDelete = (id, fullName, status, billed) => {
    console.log({ billed });
    if (status === 'Pending' && !billed) {
      confirm({
        description: `Voulez vous supprimer définitivement le bon de livraison de ${fullName}?`,
      })
        .then(() => {
          dispatch(requestRemovingForm(id));
        })
        .catch(() => console.log('Supression Annulé'));
    } else {
      dispatch(requestFailed('Seul les bon en attente ou non facturé peuvent être supprimé!'));
    }
  };

  useEffect(() => {
    console.log('invoiceData', invoiceData);
  }, [invoiceData]);

  return (
    <SuiBox bgColor="grey-100" borderRadius="lg" p={3} mt={2}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
          {`Bon n° ${form.attributes.number}`}
        </SuiTypography>

        <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
          {form.attributes.customer_name}
        </SuiTypography>

        <SuiBox mb={1} lineHeight={0} display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="text" mb={1}>
            Date de la commande:
          </SuiTypography>
          <SuiTypography
            variant="caption"
            fontWeight="medium"
            textTransform="capitalize"
            textAlign="center"
          >
            {moment(form.attributes.date).format('DD - MM - YYYY')}
          </SuiTypography>
        </SuiBox>

        <SuiBox mb={1} lineHeight={0} display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="text" mb={1}>
            Remise global:
          </SuiTypography>
          <SuiTypography variant="caption" fontWeight="medium" textAlign="center">
            {`${form.attributes.discount} $`}
          </SuiTypography>
        </SuiBox>

        <SuiBox mb={1} lineHeight={0} display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="text" mb={1}>
            Prix total:
          </SuiTypography>
          <SuiTypography variant="caption" fontWeight="medium" textAlign="center">
            {`${form.attributes.total_price} $`}
          </SuiTypography>
        </SuiBox>

        <SuiBox mb={1} lineHeight={0} display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="text" mb={1}>
            Bon de commande:
          </SuiTypography>
          <SuiTypography variant="caption" fontWeight="medium" textAlign="center">
            Bon N°: {form.attributes.order_number}
          </SuiTypography>
        </SuiBox>

        <SuiBox display="flex" justify="center" alignItems="center">
          {form.attributes.billed ? (
            <SuiTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
              mr={2}
              color="primary"
            >
              {' '}
              {`Facture N°${form &&
                form.attributes &&
                form.attributes.invoice &&
                form.attributes.invoice.data &&
                form.attributes.invoice.data.attributes &&
                form.attributes.invoice.data.attributes.number
                }`}
            </SuiTypography>
          ) : (
            <SuiButton
              variant="text"
              color="dark"
              onClick={() => {
                dispatch(
                  requestAddingInvoice({
                    customer_name: form.attributes.customer_name,
                    customer_adress: form.attributes.customer_adress,
                    customer_phoneNumber: form.attributes.customer_phoneNumber,
                    customer_mail: form.attributes.customer_mail,
                    total_taxes: form.attributes.total_taxes,
                    total_price: form.attributes.total_price,
                    discount: form.attributes.discount,
                    products: form.attributes.products,
                    id: form.id,
                  })
                );
              }}
            >
              <Icon>description</Icon>&nbsp;Facturer
            </SuiButton>
          )}

          <SuiButton
            variant="text"
            color="dark"
            onClick={() => {
              Axios.post(
                `/api/deliveryPdf`,
                {
                  number: form.attributes.number,
                  discount_total: form.attributes.discount,
                  total: form.attributes.total_price,
                  total_tax: form.attributes.total_taxes,
                  customer_name: form.attributes.customer_name,
                  customer_adress: form.attributes.customer_adress,
                  email: form.attributes.customer_mail,
                  phone: form.attributes.customer_phoneNumber,
                  line_items: form.attributes.products,
                },
                {
                  headers: {},

                  responseType: 'blob',
                }
              ).then((res) => {
                const content = res.headers['content-type'];
                download(
                  res.data,
                  `Bon de livraison N° ${form.attributes.number}: ${form.attributes.customer_name}`,
                  content
                );
              });
            }}
          >
            <Icon>print</Icon>&nbsp;Imprimer
          </SuiButton>

          <SuiButton
            variant="text"
            color="error"
            onClick={() =>
              handleDelete(
                form.id,
                form.attributes.customer_name,
                form.attributes.status,
                form.attributes.billed
              )
            }
          >
            <Icon>delete</Icon>&nbsp;Supprimer
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default DeliveryForm;
