import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SuiTypography from '../../components/SuiTypography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestAddingProduct,
  requestUpdatingProduct,
} from '../../redux/actions/productsActionCreators';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import Select from '../../components/Select';
import Checkbox from '@mui/material/Checkbox';
import { fetchAttributes } from '../../redux/actions/attributesActionCreators';
import AddProductImage from './AddProductImage';
import { requestUploadingPhotos } from '../../redux/actions/assetsActionCreators';
import { Autocomplete } from '@mui/material';
import { fetchProductsSku } from '../../redux/actions/productsActionCreators';

export default function AddModal({
  open,
  handleClose,
  isUpdating,
  initialValues,
  id,
  typeProd,
  categories,
  search,
  filter,
  page,
  data,
  dataSku
}) {
  const dispatch = useDispatch();
  const [type, setType] = useState('');
  const [skuValue, setSkuValue] = useState('');
  const [sku, setSku] = useState([]);
  const [files, setFiles] = useState([]);
  const [localProductPhotos, setLocalProductPhotos] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [checked, setChecked] = useState([]);

  console.log({ dataSku })

  const uploadedPhotos = useSelector((state) => state.uploadedPhotos.list);
  const products = useSelector((state) => state.products.list);

  const filtredCategories = categories.map((el) => ({
    id: el.id,
    name: el.name,
    slug: el.slug,
  }));

  const photos = (photos) => {
    setLocalProductPhotos(photos);
  };
  const handleUploadPhoto = (value) => {
    const formData = new FormData();
    for (let i = 0; i < value.length; i++) {
      formData.append('files', value[i]);
    }
    setFiles(formData);
  };

  const handleCheck = (src) => {
    if (!checked.some((item) => item.src === src)) {
      setChecked([...checked, { src: src }]);
    } else {
      setChecked(checked.filter((el) => el.src !== src));
    }
  };
  useEffect(() => {
    setType(typeProd);
  }, [typeProd]);

  const handleType = (typ) => {
    setType(typ);
  };

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);

  useEffect(() => {
    console.log({ checked });
  }, [checked]);

  const initValSchema = {
    name: Yup.string().required('Ce champ est obligatoire!'),
    // description: Yup.string().required('Ce champ est obligatoire!'),
    stock: Yup.string()
      .matches(/^-?[0-9,.]+$/, 'Doit contenir uniquement des chiffres')
      .required('Ce champ est obligatoire!'),
    regular_price: Yup.string()
      .matches(/^-?[0-9,.]+$/, 'Doit contenir uniquement des chiffres')
      .required('Ce champ est obligatoire!'),
    promotion: Yup.string().matches(/^-?[0-9,.]+$/, 'Doit contenir uniquement des chiffres'),
    unit: Yup.string().required('Ce champ est obligatoire!'),

    categories: Yup.array().required('Ce champ est obligatoire!'),
  };

  const handleSku = (value) => {
    console.log('value', Number(value));
    setSkuValue(value);
    dispatch(fetchProductsSku(Number(value)));
  };

  const reg = new RegExp('^[0-9]*$');

  useEffect(() => {
    console.log({ products });
    setSku(products.map((el) => el.sku));
  }, [products]);

  const handlePictures = (arr) => {
    setPictures(arr);
  };

  useEffect(() => {
    setChecked(data && data.map((el) => ({ src: el.src })).concat(pictures));
  }, [data, pictures]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: !isUpdating && Yup.object(initValSchema),
    onSubmit: (values, { resetForm }) => {
      if (isUpdating) {
        dispatch(
          requestUpdatingProduct(
            id,
            values.name,
            values.description,
            values.regular_price,
            values.stock,
            values.categories,
            values.unit,
            values.promotion,
            checked,
            files,
            search,
            filter,
            page
          )
        );
        // handleUploadPhoto();
      } else {
        dispatch(
          requestAddingProduct(
            {
              sku: skuValue,
              name: values.name,
              meta_data:
                Number(values.promotion) !== 0 || values.promotion == ''
                  ? [
                    {
                      key: '_woo_uom_input',
                      value: values.unit,
                    },
                    {
                      key: '_sale_price',
                      value: values.promotion,
                    },
                  ]
                  : [
                    {
                      key: '_woo_uom_input',
                      value: values.unit,
                    },
                    {
                      key: '_sale_price',
                      value: '',
                    },
                  ],
              description: values.description,
              regular_price: values.regular_price,
              type: 'simple',
              manage_stock: true,
              stock_status: 'instock',
              stock_quantity: values.stock,
              categories: values.categories,
              images: pictures,
            },
            files,
            search,
            filter,
            page
          )
        );
      }
      resetForm({ values: '' });
      setSkuValue('');
      handleClose();
    },
  });

  return (
    <div>
      {console.log({ sku })}
      <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{ sx: { width: '80%' } }}>
        {console.log('From', formik.values)}
        {isUpdating ? (
          <DialogTitle>Modification d'un produit</DialogTitle>
        ) : (
          <DialogTitle>Ajout d'un produit</DialogTitle>
        )}
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Code à barre
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Code à barre ..."
                    name="sku"
                    error={!isUpdating && sku.includes(skuValue)}
                    helperText={
                      !isUpdating && sku.includes(skuValue) && 'Ce code est déjà utilisé pour un autre produit!'
                    }
                    onChange={(e) => handleSku(e.target.value)}
                    value={isUpdating ? dataSku : skuValue}
                    disabled={isUpdating}
                    sx={{
                      '& .MuiInputBase-input': {
                        width: '100% !important',
                      },
                    }}
                    fullWidth
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nom de l'article
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Nom de l'article"
                    name="name"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    sx={{
                      '& .MuiInputBase-input': {
                        width: '100% !important',
                      },
                    }}
                    fullWidth
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Description
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Description.."
                    name="description"
                    error={Boolean(formik.touched.description && formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    sx={{
                      '& .MuiInputBase-input': {
                        width: '100% !important',
                      },
                    }}
                    fullWidth
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Prix
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Prix.."
                    name="regular_price"
                    error={Boolean(formik.touched.regular_price && formik.errors.regular_price)}
                    helperText={formik.touched.regular_price && formik.errors.regular_price}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.regular_price}
                    sx={{
                      '& .MuiInputBase-input': {
                        width: '100% !important',
                      },
                    }}
                    fullWidth
                  />
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Prix de promotion
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Prix de promotion ..."
                    name="promotion"
                    error={Boolean(formik.touched.promotion && formik.errors.promotion)}
                    helperText={formik.touched.promotion && formik.errors.promotion}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.promotion}
                    sx={{
                      '& .MuiInputBase-input': {
                        width: '100% !important',
                      },
                    }}
                    fullWidth
                  />
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Quantité
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Quantité ..."
                    name="stock"
                    error={Boolean(formik.touched.stock && formik.errors.stock)}
                    helperText={formik.touched.stock && formik.errors.stock}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.stock}
                    sx={{
                      '& .MuiInputBase-input': {
                        width: '100% !important',
                      },
                    }}
                    fullWidth
                  />
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Unité
                  </SuiTypography>
                  <TextField
                    type="text"
                    placeholder="Unité ..."
                    name="unit"
                    error={Boolean(formik.touched.unit && formik.errors.unit)}
                    helperText={formik.touched.unit && formik.errors.unit}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.unit}
                    sx={{
                      '& .MuiInputBase-input': {
                        width: '100% !important',
                      },
                    }}
                    fullWidth
                  />
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Catégories
                  </SuiTypography>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={formik.values.categories}
                    name="categories"
                    onChange={(e, value) => {
                      formik.setFieldValue(
                        'categories',
                        value.map((el) => ({ id: el.id }))
                      );
                      console.log('testest', value);
                    }}
                    multiple
                    options={filtredCategories}
                    getOptionLabel={(option) => option && option.name}
                    error={Boolean(formik.touched.description && formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                    renderInput={(params) => <TextField {...params} placeholder="Catégories" />}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Image du produit
                  </SuiTypography>
                  <Select
                    handleType={handleType}
                    typeProd={typeProd}
                    values={[
                      { label: 'Choisir image du dossier Images ', value: 'fromAssets' },
                      { label: 'Télécharger des nouvelles images', value: 'newImage' },
                    ]}
                  />
                </Stack>
              </Grid>
            </Grid>

            {type === 'fromAssets' ? (
              <>
                <hr style={{ marginTop: '45px', marginBottom: '30px' }} />
                <AddProductImage photos={photos} handlePictures={handlePictures} />
              </>
            ) : null}

            {type === 'newImage' ? (
              <>
                <hr style={{ marginTop: '45px', marginBottom: '30px' }} />
                <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                  <input type="file" multiple onChange={(e) => handleUploadPhoto(e.target.files)} />
                </div>
              </>
            ) : null}

            <Grid container spacing={3} mt={3}>
              {data &&
                data.map((el) => (
                  <Grid item xs={3} onClick={() => handleCheck(el.src)}>
                    <Checkbox checked={checked && checked.some((item) => item.src === el.src)} />
                    <img src={el.src} alt="produit" width="100px" height="100px" />
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            {isUpdating ? (
              <Button type="submit">Modifier</Button>
            ) : (
              <Button type="submit">Ajouter</Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
