import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import SuiTypography from '../../components/SuiTypography';
import Typography from '@mui/material/Typography';
import { fetchAssets } from '../../redux/actions/assetsActionCreators';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function AddProductImage({ photos, handlePictures }) {
  const dispatch = useDispatch();
  const [showFolders, setShowFolders] = useState(true);
  const [folder, setFolder] = useState({});
  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(fetchAssets(page));
  }, [dispatch, page]);

  const assets = useSelector((state) => state.assets.list);
  const meta = useSelector((state) => state.assets.meta);

  const [checked, setChecked] = useState([]);

  photos(checked);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChoosenPhoto = () => {
    setShowFolders(false);
  };

  useEffect(() => {
    console.log({ checked });
    let arr = checked.map((el) => ({
      src: `${process.env.REACT_APP_API_URL}${el && el.attributes && el.attributes.url}`,
    }));
    handlePictures(arr);
  }, [checked]);

  return (
    <div className="container">
      {showFolders ? (
        <div className="row">
          <div className="col-sm-8">
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Sélectionner le dossier des images
            </SuiTypography>
            <div
              style={{
                display: 'inline-grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                width: '1000px',
              }}
            >
              {assets.length === 0 ? (
                <SuiTypography component="label" variant="caption">
                  Pas de photos
                </SuiTypography>
              ) : (
                assets.map((el) => {
                  return (
                    el.attributes.content.data !== null && (
                      <Card
                        sx={{
                          maxWidth: '200px',
                          maxHeight: '300px',
                          mb: 2,
                          mr: 1,
                          display: 'grid',

                          mt: 2,
                          boxShadow: 0.5,
                        }}
                        key={el.id}
                        onClick={() => {
                          handleChoosenPhoto();
                          setFolder({ content: el.attributes.content });
                        }}
                      >
                        <CardMedia
                          component="img"
                          alt=""
                          height="150"
                          width="50"
                          image="/redfolder2.png"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {el.attributes.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            contient{' '}
                            {el.attributes.content &&
                              (el.attributes.content.data === null
                                ? 0
                                : el.attributes.content.data.length)}{' '}
                            photos
                          </Typography>
                        </CardContent>
                      </Card>
                    )
                  );
                })
              )}
            </div>
          </div>
          <Stack spacing={2}>
            <Pagination
              count={meta && meta.pagination && meta.pagination.pageCount}
              page={page}
              onChange={handleChange}
            />
          </Stack>
        </div>
      ) : (
        <>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Sélectionner les images
          </SuiTypography>
          <List
            dense
            sx={{
              width: '100%',
              maxWidth: 1000,
              bgcolor: 'background.paper',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr ',
            }}
          >
            {folder.content.data.map((value) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <>
                  <ListItem key={value} onClick={handleToggle(value)} disablePadding>
                    <ListItemButton>
                      <Checkbox checked={checked.indexOf(value) !== -1} />
                      <Avatar
                        alt={`Avatar n°${value + 1}`}
                        src={`${process.env.REACT_APP_API_URL}${value.attributes.url}`}
                      />
                      <ListItemText id={labelId} primary={`${value.attributes.name}`} />
                    </ListItemButton>
                  </ListItem>
                </>
              );
            })}
          </List>
        </>
      )}
    </div>
  );
}
export default AddProductImage;
