import {
  SET_PRODUCTS_VARIATIONS,
  REMOVE_PRODUCT_VARIATIONS,
  ADD_VARIATION,
} from '../types/productsVariationsTypes';

const initialState = {
  list: [],
  loading: false,
  selected: null,
};

const productsVariationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS_VARIATIONS:
      return { ...state, list: action.payload };
    case ADD_VARIATION:
      return { ...state, list: [...state.list, action.payload] };
    case REMOVE_PRODUCT_VARIATIONS:
      return { ...state, list: [...state.list] };
    default:
      return state;
  }
};

export default productsVariationsReducer;
