import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import { useDispatch, useSelector } from 'react-redux';
import { fetchForms } from '../../redux/actions/deliveryFormsActionCreators';
import DeliveryForm from './DeliveryForm';

function DeliveryFormInformations() {
  const dispatch = useDispatch();
  const forms = useSelector((state) => state.deliveryForms.list);

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch]);

  return (
    <Card id="delete-account">
      <SuiBox pt={3} px={2}>
        <SuiTypography variant="h6" fontWeight="medium">
        Bons des livraisons
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={1} pb={2} px={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {forms.map((form) => (
            <DeliveryForm form={form} />
          ))}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default DeliveryFormInformations;
