import React from 'react';
import Grid from '@mui/material/Grid';
import SuiBox from '../../components/SuiBox';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import OrdersInformation from './OrdersInformations';

function OrdersLists() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={4}>
        <SuiBox>
          <Grid>
            <Grid item>
              <OrdersInformation />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default OrdersLists;
