import {
  SET_ORDERS, REMOVE_ORDER, ADD_ORDER
} from '../types/ordersTypes.js';

const initialState = {
  list: [],
  loading: false,
  selected: null,
  totalPages: null,
  totalOrders: null
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return { ...state, list: action.payload.orders, totalPages: action.payload.totalPages, totalOrders: action.payload.totalOrders };
    case ADD_ORDER:
      return { ...state, list: [...state.list, action.payload] };
    case REMOVE_ORDER:
      return { ...state, list: [...state.list] };
    default:
      return state;
  }
};

export default ordersReducer;
