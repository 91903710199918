/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from 'react';

// react-router-dom components
// import { Link } from 'react-router-dom';

// @mui material components
import Switch from '@mui/material/Switch';

// Soft UI Dashboard React components
import SuiBox from '../../../components/SuiBox';
import SuiTypography from '../../../components/SuiTypography';
import SuiInput from '../../../components/SuiInput';
import SuiButton from '../../../components/SuiButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Authentication layout components
import CoverLayout from '../components/CoverLayout';

// Images
import curved9 from '../../../assets/images/curved-images/curved-6.jpg';

import { requestLogin } from '../../../redux/actions/userActionCreators';

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      identifier: '',
      password: '',
    },
    validationSchema: Yup.object({
      identifier: Yup.string()
        .email('Doit être une adresse mail valide!')
        .max(255)
        .required('Adresse mail obligatoire!'),
      password: Yup.string().max(255).required('Mot de passe obligatoire!'),
    }),
  });

  return (
    <CoverLayout
      title="Bienvenue"
      description="Saisissez votre adresse mail et mot de passe  pour se connecter"
      image={curved9}
    >
      <form onSubmit={formik.handleSubmit}>
        <SuiBox component="form" role="form">
          <SuiBox mb={2}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Adresse mail
              </SuiTypography>
            </SuiBox>
            <SuiInput
              type="email"
              placeholder="Adresse mail..."
              name="identifier"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </SuiBox>
          <SuiBox mb={2}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Mot de passe
              </SuiTypography>
            </SuiBox>
            <SuiInput
              type={showPassword ? 'text' : 'password'}
              placeholder="Mot de passe..."
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              icon={{
                component: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="top"
                    size="small"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
                direction: 'right',
              }}
            />
          </SuiBox>
          <SuiBox display="flex" alignItems="center">
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <SuiTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetRememberMe}
              sx={{ cursor: 'pointer', userSelect: 'none' }}
            >
              &nbsp;&nbsp;Se souvenir de moi
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={() => {
                dispatch(requestLogin(formik.values.identifier, formik.values.password));
                console.log(formik.values);
              }}
            >
              Se connecter
            </SuiButton>
          </SuiBox>
          {/* <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{' '}
            <Link to="/authentication/sign-up">
              <SuiTypography variant="button" color="info" fontWeight="medium" textGradient>
                Sign up
              </SuiTypography>
            </Link>
          </SuiTypography>
        </SuiBox> */}
        </SuiBox>
      </form>
    </CoverLayout>
  );
}

export default SignIn;
