import Api from '../../axios/api';
import { requestStarted, requestSucceeded, requestFailed } from './feedbackActionCreators';
import {
  SET_PRODUCTS_VARIATIONS,
  REMOVE_PRODUCT_VARIATIONS,
  ADD_VARIATION
} from '../types/productsVariationsTypes';

export const setProdVar = (prodVar) => ({ type: SET_PRODUCTS_VARIATIONS, payload: prodVar });
export const removeVariation = (id) => ({ type: REMOVE_PRODUCT_VARIATIONS, payload: id });
export const addVariation = (variation) => ({ type: ADD_VARIATION, payload: variation})

export const fetchProductsVariations = (id) => (dispatch) => {
  dispatch(requestStarted());
  Api.get(`products/${id}/variations`)
    .then((res) => {
      dispatch(requestSucceeded());
      console.log(res.data);
      dispatch(setProdVar(res.data));
    })
    .catch((err) => {
      console.log({ err });
      dispatch(requestFailed(err.response.data.message));
    });
};

export const requestRemovingVariation = (prodId, varId) => (dispatch) => {
  dispatch(requestStarted());
  Api.delete(`products/${prodId}/variations/${varId}`, {
    force: true,
  })
    .then((res) => {
      dispatch(requestSucceeded('Variation du produit supprimé avec succès!'));
      dispatch(removeVariation(varId));
      dispatch(fetchProductsVariations(prodId));
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestFailed(error.response.data.message));
    });
};


export const requestAddingVariation = (payload) => (dispatch) => {
  dispatch(requestStarted());
  console.log("attributes", payload.attributes);
  Api.post(`products/${payload.id}/variations`, {
    manage_stock: payload.manage_stock,
    stock_quantity: payload.stock_quantity,
    regular_price: payload.regular_price,
    attributes: payload.attributes
  })
    .then((res) => {
      dispatch(requestSucceeded('Attribut ajouté avec succée!'));
      dispatch(addVariation(res.data));
      console.log('houni', res.data);
      dispatch(fetchProductsVariations(payload.id));
    })
    .catch((error) => dispatch(requestFailed(error.response.data.message)));
};