import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SuiTypography from '../../components/SuiTypography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { requestAddingOrder, requestUpdatingOrder } from '../../redux/actions/ordersActionCreators';
import AddRemoveInputField from './AddRemoveInputField';
import Select from '../../components/Select';
import moment from 'moment';
import { MapContainer } from '../../components/MapContainer';
import Geocode from 'react-geocode';

export default function AddModal({
  open,
  handleClose,
  initialValues,
  position,
  orderId,
  search,
  filter,
  page,
  after,
  before,
  disabled,
  isUpdating,
}) {
  const dispatch = useDispatch();
  Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);
  Geocode.setLanguage('fr');
  Geocode.setRegion('es');
  Geocode.setLocationType('ROOFTOP');
  Geocode.enableDebug();
  const customers = useSelector((state) => state.customers.list);

  const [customersData, setCustomersData] = useState([]);
  const [currentPosition, setCurrentPosition] = React.useState(position);
  const [countries, setCountries] = useState([]);
  const [prod, setProd] = useState({});
  const [orderType, setOrderType] = useState('pickup');
  const [time, setTime] = useState(
    moment().hour() >= 8 && moment().hour() <= 12
      ? '08:00 - 12:00'
      : moment().hour() > 12 && moment().hour() <= 16
      ? '12:00 - 16:00'
      : moment().hour() > 16 && moment().hour() <= 20
      ? '16:00 - 20:00'
      : '20:00 - 21:00'
  );

  const handleChange = (data) => {
    setProd(data.map((el) => ({ product_id: el.product_id, quantity: el.quantity })));
  };

  const handleType = (str) => {
    setOrderType(str);
  };

  const handleTime = (el) => {
    setTime(el);
  };
  useEffect(() => {
    Geocode.fromLatLng(currentPosition.lat, currentPosition.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        formik.setFieldValue('address_1', address);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [currentPosition]);

  const initValSchema = {
    first_name: Yup.string().required('Ce champ est obligatoire!'),
    last_name: Yup.string().required('Ce champ est obligatoire!'),
    phone: Yup.string().required('Ce champ est obligatoire!'),
    date: Yup.string().required('Ce champ est obligatoire'),
    email: Yup.string()
      .email('Doit être une adresse mail valide!')
      .required('Ce champ est obligatoire!'),
    // info: Yup.string().required('Ce champ est obligatoire!'),

    line_items: Yup.array().required('Ce champ est obligatoire!'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema:
      // !isUpdating &&
      Yup.object(initValSchema),
    onSubmit: (values, { resetForm }) => {
      const data = {
        billing: {
          first_name: values.first_name,
          last_name: values.last_name,
          address_1: orderType === 'pickup' ? 'Route mahdia km7 Sfax' : values.address_1,
          postcode: values.postcode,
          country: 'TN',
          email: values.email,
          phone: values.phone,
        },
        shipping: {
          first_name: values.first_name,
          last_name: values.last_name,
          address_1: orderType === 'pickup' ? 'Route mahdia km7 Sfax' : values.address_1,
          postcode: values.postcode,
          country: 'TN',
        },
        meta_data:
          orderType === 'pickup'
            ? [
                { key: 'delivery_type', value: orderType },
                { key: 'pickup_date', value: values.date },
                { key: 'pickup_time', value: time },
              ]
            : [
                { key: 'delivery_type', value: orderType },
                { key: 'delivery_date', value: values.date },
                { key: 'delivery_time', value: time },
                { key: 'lpac_latitude', value: currentPosition.lat },
                { key: 'lpac_longitude', value: currentPosition.lng },
                { key: '_billing_informations_manquantes', value: values.info },
              ],
        line_items: prod,
        status: 'processing',
      };
      if (isUpdating) {
      } else {
        dispatch(
          requestAddingOrder({
            data,
            search: search,
            filter: filter,
            page: page,
            after: after,
            before: before,
          })
        );
      }
      resetForm({ values: '' });
    },
  });

  const handleUpdate = () => {
    dispatch(
      requestUpdatingOrder({
        orderId,
        currentPosition,
        search,
        filter,
        page,
        after,
        before,
      })
    );
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{ sx: { width: '80%' } }}>
        <DialogTitle>{isUpdating ? 'Modifier Position' : "Ajout d'un bon de commande"}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              {!isUpdating && (
                <>
                  <Grid item xs={4}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Type de commande
                      </SuiTypography>
                      <Select
                        values={[
                          { label: 'Retrait magasin', value: 'pickup' },
                          { label: 'Livraison à domicile', value: 'delivery' },
                        ]}
                        typeProd={orderType}
                        sx={{ width: '100%' }}
                        handleType={handleType}
                        disabled={disabled}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        {orderType === 'delivery' ? 'Date de livraison' : 'Date du retrait'}
                      </SuiTypography>
                      <TextField
                        type="date"
                        name="date"
                        error={Boolean(formik.touched.date && formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.date}
                        disabled={disabled}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        {orderType === 'delivery' ? 'Temps de la livraison' : 'Temps du retrait'}
                      </SuiTypography>
                      <Select
                        values={[
                          { label: '08:00 - 12:00', value: '08:00 - 12:00' },
                          { label: '12:00 - 16:00', value: '12:00 - 16:00' },
                          { label: '16:00 - 20:00', value: '16:00 - 20:00' },
                          { label: '20:00 - 21:00', value: '20:00 - 21:00' },
                        ]}
                        typeProd={time}
                        sx={{ width: '100%' }}
                        handleType={handleTime}
                        disabled={disabled}
                      />
                    </Stack>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                {(orderType === 'delivery' || isUpdating) && (
                  <MapContainer
                    height="30vh"
                    target={{
                      lat: position.lat,
                      lng: position.lng,
                    }}
                    draggable
                    setCurrentPosition={(pos) => {
                      setCurrentPosition(pos);
                    }}
                    currentPosition={currentPosition}
                  ></MapContainer>
                )}
              </Grid>
            </Grid>

            <hr style={{ marginTop: ' 45px', marginBottom: '30px' }} />
            <Grid container spacing={2}>
              {!isUpdating && (
                <>
                  <Grid item xs={3}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Prénom
                      </SuiTypography>
                      <TextField
                        type="text"
                        placeholder="Prénom"
                        name="first_name"
                        error={Boolean(formik.touched.first_name && formik.errors.first_name)}
                        helperText={formik.touched.first_name && formik.errors.first_name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.first_name}
                        disabled={disabled}
                        sx={{
                          '& .MuiInputBase-input': {
                            width: '100% !important',
                          },
                        }}
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nom
                      </SuiTypography>
                      <TextField
                        type="text"
                        placeholder="Nom"
                        name="last_name"
                        error={Boolean(formik.touched.last_name && formik.errors.last_name)}
                        helperText={formik.touched.last_name && formik.errors.last_name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.last_name}
                        disabled={disabled}
                        sx={{
                          '& .MuiInputBase-input': {
                            width: '100% !important',
                          },
                        }}
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Numéro de téléphone
                      </SuiTypography>
                      <TextField
                        type="text"
                        placeholder="Numéro de téléphone"
                        name="phone"
                        error={Boolean(formik.touched.phone && formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        disabled={disabled}
                        sx={{
                          '& .MuiInputBase-input': {
                            width: '100% !important',
                          },
                        }}
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Adresse mail
                      </SuiTypography>
                      <TextField
                        type="text"
                        placeholder="Adresse mail"
                        name="email"
                        error={Boolean(formik.touched.email && formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        disabled={disabled}
                        sx={{
                          '& .MuiInputBase-input': {
                            width: '100% !important',
                          },
                        }}
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                </>
              )}
              {(orderType === 'delivery' || isUpdating) && (
                <>
                  <Grid item xs={5}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Adresse
                      </SuiTypography>
                      <TextField
                        type="text"
                        placeholder="Adresse"
                        name="address_1"
                        error={Boolean(formik.touched.address_1 && formik.errors.address_1)}
                        helperText={formik.touched.address_1 && formik.errors.address_1}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.address_1}
                        disabled
                        sx={{
                          '& .MuiInputBase-input': {
                            width: '100% !important',
                          },
                        }}
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={5}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Informations supplémentaire
                      </SuiTypography>
                      <TextField
                        type="text"
                        placeholder="Etages, portes, etc..."
                        name="info"
                        error={Boolean(formik.touched.info && formik.errors.info)}
                        helperText={formik.touched.info && formik.errors.info}
                        onBlur={formik.handleBlur}
                        disabled={disabled}
                        onChange={formik.handleChange}
                        value={formik.values.info}
                        sx={{
                          '& .MuiInputBase-input': {
                            width: '100% !important',
                          },
                        }}
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    <Stack spacing={2}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Code Postal
                      </SuiTypography>
                      <TextField
                        type="text"
                        placeholder="Code Postal"
                        name="postcode"
                        error={Boolean(formik.touched.postcode && formik.errors.postcode)}
                        helperText={formik.touched.postcode && formik.errors.postcode}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.postcode}
                        disabled={disabled}
                        sx={{
                          '& .MuiInputBase-input': {
                            width: '100% !important',
                          },
                        }}
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
            <hr style={{ marginTop: ' 45px', marginBottom: '30px' }} />
            {!isUpdating && (
              <>
                <AddRemoveInputField handleChangeInput={handleChange} />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            {isUpdating ? (
              <Button onClick={handleUpdate}> Confirmer</Button>
            ) : (
              <Button type="submit">Ajouter</Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
